import React from "react";
import Layout from "../../layout/Layout";
import { Div } from "../../ui/Div";
import Header from "../../ui/Header";
import { useTranslation } from "react-i18next";
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import styled from "styled-components";
import HeroImage from "../../assets/Home page .svg";
import Type from "../../ui/Type";
const Container = styled.div`
  margin-top: 50px;
  margin-right: 100px;
  margin-left: 100px;
  @media (max-width: 720px) {
    margin: 0;
  }
`;

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding-left: 40px;
  padding-right: 40px;
  @media (max-width: 720px) {
    img {
      display: none;
    }
    grid-template-columns: 2fr;
  }
`;

const FAQ = () => {
  let [t] = useTranslation();
  return (
    <Layout>
      <Div flexcenter sx={{ m: "30px" }}>
        <Header
          title={t("FAQ")}
          list={[
            { title: t("Home"), link: "/" },
            { title: t("FAQ"), link: "/FAQ" },
          ]}
        ></Header>
      </Div>
      <Container>
        <GridGroup>
          <Div sx={{ mt: 30 }}>
            <Div>
              <Type
                var="FAQFont"
                sx={{ c: "#222", fs: 24, fw: "bold", mb: 20 }}
              >
                {t("Q1")}
              </Type>
            </Div>
            <Div>
              <Type var="FAQFont" sx={{ c: "gray2", fs: 16 }}>
                {t("A1")}
              </Type>
            </Div>
            <Div sx={{ mt: 40 }}>
              <Type
                var="FAQFont"
                sx={{ c: "#222", fs: 24, fw: "bold", mb: 20 }}
              >
                {t("Q2")}
              </Type>
            </Div>
            <Div>
              <Type var="FAQFont" sx={{ c: "gray2", fs: 16 }}>
                {t("A2")}
              </Type>
            </Div>
          </Div>
          <Div>
            <img
              src={HeroImage}
              alt="Hero"
              style={{ width: "100%", height: "100%" }}
            />
          </Div>
        </GridGroup>
        <Div sx={{ px: 40 }}>
          <RowS sx={{ mt: [0, 0, 40] }}>
            <Type var="FAQFont" sx={{ c: "#222", fs: 24, fw: "bold", mb: 20 }}>
              {t("Q3")}
            </Type>
          </RowS>

          <RowS>
            <Type var="FAQFont" sx={{ c: "gray2", fs: 16 }}>
              {t("A3")}
            </Type>
          </RowS>

          <RowS sx={{ mt: 40 }}>
            <Type var="FAQFont" sx={{ c: "#222", fs: 24, fw: "bold", mb: 20 }}>
              {t("Q4")}
            </Type>
          </RowS>
          <RowS>
            <Type var="FAQFont" sx={{ c: "gray2", fs: 16 }}>
              {t("A4")}
            </Type>
          </RowS>
          <RowS sx={{ mt: 40 }}>
            <Type var="FAQFont" sx={{ c: "#222", fs: 24, fw: "bold", mb: 20 }}>
              {t("Q5")}
            </Type>
          </RowS>
          <RowS>
            <Type var="FAQFont" sx={{ c: "gray2", fs: 16 }}>
              {t("A5")}
            </Type>
          </RowS>
        </Div>
      </Container>
    </Layout>
  );
};

export default FAQ;
