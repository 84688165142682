import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Div } from "../../ui/Div";
import { ToastContainer } from "react-toastify";
import Header from "../../ui/Header";
import { useTranslation } from "react-i18next";
import { useHistory } from "./provider";
import { useEffect } from "react";
import Type from "../../ui/Type";
import { Button } from "@mui/material";
import StarRatings from "react-star-ratings";
import { useState } from "react";
import AudioPlayerVoice from "./historyStyled/AudioPlayerVoice";
import { success, error } from "../../utilities/ToastContainer";
import { useRef } from "react";

const SingleRead = () => {
  const { orderID } = useParams();
  const [t] = useTranslation();
  let navigate = useNavigate();
  const audioPlayer = useRef();
  const [rate, setRate] = useState();
  const [reload, setReload] = useState();

  const { CancelOrder, setRateOrder } = useHistory();
  const changeRating = async (e) => {
    setRate(e);
    try {
      await setRateOrder(read?.orderID, { rate: e });
      success("Set Rate to this order has been successfully");
      setReload(true);
    } catch (error) {
      error(error.errors.message);
    }
  };

  const CancelOrderClick = async (orderId) => {
    try {
      await CancelOrder(orderId);
      success("This order canceled successfully");
      setReload(true);
    } catch (error) {
      error(error.errors.message);
    }
  };
  const handleSetEmployeeClick = async (orderId, serviceId) => {
    navigate(`/ChooseForteller/${orderId}/${serviceId}`);
  };
  // var read = {
  //   serviceName: "coffee Reading",
  //   statusID: 2,
  //   replyVoice: 45,
  //   serviceID: 1,
  //   orderID: 73,
  //   employeeName: "Alaa",
  //   replyDate: "2023-10-09",
  //   message:
  //     "cccccccccccccccccccccccccccccccmmmmmmmmmmmmmmmmmmmmmmmldddddddddddddd",
  // };
  const {
    state: { singleRead: read },
    fetchRead,
  } = useHistory();

  useEffect(() => {
    fetchRead(orderID);
  }, [fetchRead, orderID, reload]);

  if (!read) return;

  return (
    <Div sx={{ fxd: "column", mx: 150 }}>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        autoClose={2000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Div flexcenter sx={{ m: "30px" }}>
        <Header
          title={t("My Readings")}
          list={[
            { title: t("Home"), link: "/" },
            { title: t("My Readings"), link: "/History" },
          ]}
        ></Header>
      </Div>
      <Div>
        <Type var="Font" sx={{ fw: 600, c: "black22" }}>
          {" "}
          {read.serviceName}
        </Type>
      </Div>
      <Div>
        <Type var="Font" sx={{ c: "black3", mt: 10 }}>
          {t("The Reading")}
        </Type>
        <Type var="Font" sx={{ c: "gray2" }}>
          {read.message}
        </Type>
      </Div>
      {read.statusID === 1 ? (
        <Div sx={{ mt: 20 }}>
          <Type var="Font" sx={{ c: "gray2", d: "inline", fs: 24, mx: 2 }}>
            {t("your reading still Draft")}{" "}
          </Type>
          <Button
            sx={{
              backgroundColor: "#833DCC",
              border: "none",
              color: "white",
              textTransform: "capitalize",
              ":hover": { backgroundColor: "#833DCC" },
            }}
            onClick={() => handleSetEmployeeClick(read.orderID, read.serviceID)}
          >
            {t("Complete task")}
          </Button>
          <Button
            sx={{
              backgroundColor: "red",
              border: "1px solid red",
              color: "white",
              textTransform: "capitalize",
              mx: 3,
              ":hover": { backgroundColor: "red" },
            }}
            onClick={() => CancelOrderClick(read.orderID)}
          >
            {" "}
            {t("cancle")}
          </Button>
        </Div>
      ) : read.replyVoice ? (
        <Div sx={{ mt: 30 }}>
          <Div>
            <AudioPlayerVoice
              src={read?.replyVoice}
              emp={read?.employeeName}
              date={read?.replyDate}
              sourceAudio={read?.replyVoice}
            />
          </Div>
          <Type var="Font" sx={{ mt: 15 }}>
            {" "}
            {t("Rate")} {read.employeeName} {t("read")}
          </Type>
          <StarRatings
            rating={read.rate > 0 ? read?.rate : 0}
            starRatedColor="#e5bd16"
            numberOfStars={5}
            name="rating1"
            changeRating={changeRating}
            className="stars item-align-end"
            starDimension="30px"
            starSpacing="8px"
          />
        </Div>
      ) : (
        <Div>
          <Type var="Font" sx={{ ta: "center", mt: 10 }}>
            {t("no reply yet")}
          </Type>
        </Div>
      )}
    </Div>
  );
};

export default SingleRead;
