// *** React
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import StarRatings from "react-star-ratings";

// *** Styled Components
import Header from "../../ui/Header";
import Card from "../../ui/Card";
import Icon from "../../ui/Icon";
import Type from "../../ui/Type";
import InputText from "../../ui/InputText";
import { Div } from "../../ui/Div";
import { Button } from "../../ui/Button";
import { styled } from "styled-components";
import CardDetailBody from "./Styled Components/CardDetailBody";
import CardCommentBody from "./Styled Components/CardCommentBody";
import { sizes } from "../../ui/core/stylesBuilder";

// *** Providers and Services
import { useFortellers } from "./provider";
import { warning } from "../../utilities/ToastContainer";

function DetailsForteller() {
  const GridGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 130px;
    row-gap: 20px;
    cursor: auto;
    @media (max-width: ${sizes.laptop}) {
      grid-template-columns: 1fr;
    }
  `;

  //   Hooks
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [replying, setReplying] = useState(false);
  const [deleting, setDeleting] = useState(false);
  // const [reviewInput, setReviewInput] = useState(true);
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [comment, setComment] = useState("");
  const [rate, setRate] = useState(0);
  const { forteller } = location.state;
  const {
    fetchReview,
    addReview,
    state: { reviews },
  } = useFortellers();

  useEffect(() => {
    fetchReview({
      employeeID: forteller?.employeeID,
      clientID: forteller?.clientID,
      pageSize: 0,
      pageNumber: 0,
    });
  }, [
    replying,
    deleting,
    fetchReview,
    forteller?.employeeID,
    forteller?.clientID,
  ]);

  //   Handling
  const handleChange = (e) => {
    setComment(e.target.value);
  };
  const handleRate = (e) => {
    setRate(e);
  };

  // Check validity for calling 
  const checkValid = (type) => {
    if (!forteller?.online)
      warning(`${t("Sorry")} ${forteller?.employeeName} ${t("isOffline")}`);
    else if (!JSON.parse(localStorage.getItem("user"))) navigate("/LOGIN");
    else {
      navigate("/Call", {
        state: { forteller: forteller, callTypeID: type === "video" ? 2 : 1 },
      });
    }
  };

  //   Submit
  const sendReview = async () => {
    if (!user) navigate("/LOGIN");
    else {
      await addReview({
        // clientID: user?.profile?.clientID,
        employeeID: forteller?.employeeID,
        comment: comment,
        rate: rate,
      });
      setReplying(!replying);
      setRate(0);
      setComment("");
    }
  };

  return (
    <Div sx={{ gap: 20, d: "flex", fxd: "column" }}>
      {/* *** Header *** */}
      <Div flexcenter sx={{ fxd: "column" }}>
        <Div flexcenter sx={{ m: 30 }}>
          <Header
            title={t("Fortellers")}
            list={[
              { title: t("Home"), link: "/" },
              { title: t("Fortellers"), link: "/FortellerPage" },
            ]}
          ></Header>
        </Div>
      </Div>

      {/* *** Card Forteller with prices *** */}
      <Div>
        {/* *** Grid Top *** */}
        <Div flexcenter>
          <GridGroup>
            {/* *** Card *** */}
            <Div>
              <Card
                shadow="none"
                hovershadow="none"
                sx={{ bgc: "gray5", br: 0 }}
              >
                <CardDetailBody forteller={forteller}></CardDetailBody>
              </Card>
            </Div>
            {/* *** Prices *** */}
            <Div sx={{ gap: 60, d: "flex", fxd: "column", jc: "center" }}>
              <Div sx={{ d: "flex", jc: "space-between" }}>
                <Div
                  flexcenter
                  sx={{ gap: 12, cursor: "pointer" }}
                  onClick={() => checkValid("video")}
                >
                  <Icon name="VideoCarusalColored" w={45} pointer />
                  <Type var="custom" sx={{ fw: 600 }}>
                    {t("VideoCall")}
                  </Type>
                </Div>
                <Div flexcenter sx={{ gap: 10 }}>
                  <Icon name="Gem" w={35} pointer />
                  {forteller?.videoPriceText}
                </Div>
              </Div>
              <Div sx={{ d: "flex", jc: "space-between" }}>
                <Div
                  flexcenter
                  sx={{ gap: 10, cursor: "pointer" }}
                  onClick={() => checkValid("audio")}
                >
                  <Icon name="AudioCarusalColored" w={45} pointer />
                  <Type var="custom" sx={{ fw: 600 }}>
                    {t("AudioCall")}
                  </Type>
                </Div>
                <Div flexcenter sx={{ gap: 10 }}>
                  <Icon name="Gem" w={35} pointer />
                  {forteller?.voicePriceText}
                </Div>
              </Div>
              <Div sx={{ d: "flex", jc: "space-between" }}>
                <Div
                  flexcenter
                  sx={{ gap: 10, cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/empServices/${forteller?.employeeID}`)
                  }
                >
                  <Icon name="ChatCarusalColored" w={45} pointer />
                  <Type var="custom" sx={{ fw: 600 }}>
                    {t("chat")}
                  </Type>
                </Div>
              </Div>
            </Div>
          </GridGroup>
        </Div>
        {/* *** Data Forteller *** */}
        <Div flexcenter sx={{ py: 40 }}>
          <Div sx={{ w: "75%", gap: 15, d: "flex", fxd: "column" }}>
            <Div>
              <Type var="custom" sx={{ fs: 26, fw: "bold" }}>
                {forteller?.employeeName}
              </Type>
            </Div>
            <Div>
              <Type var="custom" sx={{ fs: 24, fw: "light" }}>
                {forteller?.shortDescription}
              </Type>
            </Div>
            <Div>
              <Type var="small" sx={{ ff: "arabicBodyLight" }}>
                {forteller?.description}
              </Type>
            </Div>
          </Div>
        </Div>
      </Div>

      {/* *** Divider *** */}
      <Div flexcenter>
        <Div sx={{ w: "75%", bs: "dashed", bw: 1, bc: "gray6" }}>
          {/* <hr /> */}
        </Div>
      </Div>

      {/* *** Reviews *** */}
      <Div flexcenter sx={{ fxd: "column" }}>
        <Div sx={{ w: "75%", gap: 10, d: "flex", fxd: "column" }}>
          <Type var="custom" sx={{ fw: 600 }}>
            {t("Reviews")} ({reviews?.length}){" "}
          </Type>
        </Div>
        <Div sx={{ w: "75%" }}>
          {reviews?.map((reviewer, index) => {
            if (reviewer?.clientID === user?.profile?.clientID) {
              // setReviewInput(false);
            }
            return (
              <Card
                key={index}
                shadow="none"
                hovershadow="none"
                sx={{ py: 15, px: 10 }}
              >
                <CardCommentBody
                  reviewer={reviewer}
                  replying={replying}
                  setReplying={setReplying}
                  deleting={deleting}
                  setDeleting={setDeleting}
                  setCommentReview={setComment}
                />
              </Card>
            );
          })}
        </Div>
      </Div>

      {(!reviews?.find(
        (reviewer) => reviewer.clientID === user?.profile?.clientID
      ) ||
        comment !== "") && (
        <Div flexcenter sx={{ gap: 20, fxd: "column" }}>
          <Div sx={{ w: "75%", gap: 10, d: "flex", fxd: "column" }}>
            <Type var="custom" sx={{ fw: 600 }}>
              {t("Add Review")}
            </Type>
            <StarRatings
              rating={rate}
              starRatedColor="#e5bd16"
              numberOfStars={5}
              name="rating"
              starDimension={"20px"}
              starSpacing="5px"
              changeRating={handleRate}
            />
          </Div>
          <Div sx={{ w: "75%" }}>
            <InputText
              type="textarea"
              bradiusNone
              placeholder={t("message")}
              onChange={handleChange}
              value={comment}
            />
          </Div>
          <Div sx={{ w: "75%", d: "flex", jc: "flex-end" }}>
            <Div>
              <Button
                sx={{
                  py: 10,
                  px: 20,
                  w: "fit-content",
                  bgc: "primary",
                  c: "white",
                  br: 30,
                }}
                onClick={() => sendReview()}
              >
                <Type var="custom" sx={{ fs: 14 }}>
                  {t("Post Review")}
                </Type>
              </Button>
            </Div>
          </Div>
        </Div>
      )}
    </Div>
  );
}

export default DetailsForteller;
