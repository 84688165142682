import styled, { css } from "styled-components";
import stylesBlocks from "./core/stylesBuilder";
import loginBackground from "../assets/loginBackground.svg";

export const Div = styled.div.attrs((props) => ({
  className: props.className,
  display: props.display,
}))`
  ${(p) =>
    p.loginbg &&
    css`
      background-image: url(${loginBackground});
    `};
  ${(p) =>
    p.transition &&
    css`
      transition: all 0.5s;
    `};
  ${(p) =>
    p.gradient &&
    css`
      @media (max-width: 1024px) {
        width: 100%;
      }
      background: linear-gradient(
        90deg,
        rgba(203, 182, 226, 1) 0%,
        rgba(213, 126, 235, 1) 100%
      );
    `};
  &::-webkit-scrollbar {
    display: none;
  }
  ${stylesBlocks}
`;
