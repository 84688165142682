// *** React
import React from "react";
import { useTranslation } from "react-i18next";

// *** Styled Components
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import { Div } from "../../ui/Div";
import Type from "../../ui/Type";
import Header from "../../ui/Header";
import styled from "styled-components";

// *** Icons & Images
import HeroImage from "../../assets/Home page .svg";

// *** Sizes
import { sizes } from "../../ui/core/stylesBuilder";

const GridGroup = styled(Div)`
  @media (max-width: ${sizes.tablet}) {
    padding: 0px;
  }
`;

export const DetailsAboutUs = () => {
  const [t] = useTranslation();

  return (
    <Div flexcenter sx={{ m: 30, fxd: "column", gap: 60 }}>
      <Header
        title={t("about")}
        list={[
          { title: t("Home"), link: "/" },
          { title: t("about"), link: "/about" },
        ]}
      ></Header>
      <Div sx={{ d: "flex", gap: 10 }}>
        <RowS>
          <ColS xs="12" md="6">
            <GridGroup sx={{ d: "flex", gap: 10, fxd: "column", px: 90 }}>
              <Type var="h2">{t("about")}</Type>
              <Type var="small">{t("aboutbody")}</Type>
            </GridGroup>
          </ColS>

          <ColS xs="12" md="6">
            <img
              src={HeroImage}
              alt="Hero"
              style={{ width: "100%", height: "100%" }}
            />
          </ColS>
        </RowS>
      </Div>
    </Div>
  );
};
