import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import { Home } from "./Home";
import Container from "./Container";
import LiveCall from "../Fortellers/LiveCall";

export default (
  <>
    <Route
      path="/"
      exact
      element={
        <RequireAuth>
          <Home />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <Container />
          </RequireAuth>
        }
      />
    </Route>
    <Route
      path="/LiveCall"
      element={
        <RequireAuth>
          <LiveCall />
        </RequireAuth>
      }
    />
  </>
);
