import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

const ImageComponent = styled.img.attrs(({ src, alt }) => ({
  src: src || "No Src Image",
  alt: alt || "Alternative text",
}))`
  transition: transform 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  transform: ${(p) => (p.transform === "reverse" ? "scale(-1)" : "scale(1)")};
  border: none;
  ${stylesBlocks}
`;

function Image({ children, ...props }) {
  return (
    <>
      <ImageComponent {...props}>{children}</ImageComponent>
    </>
  );
}

export default Image;
