// *** React
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// *** Styled Components
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import { Div } from "../../ui/Div";
import { Button } from "../../ui/Button";
import Card from "../../ui/Card";
import Type from "../../ui/Type";
import Image from "../../ui/Image";
import CardBody from "./Styled Components/CardBody";
import CardService from "./Styled Components/CardService";

// *** Providers
import { useServices } from "../Services/provider";

// *** Utilities
import {
  getRandomNumbersArray,
  getServiceImage,
} from "../../utilities/functions";

function Services() {
  const [t] = useTranslation();
  const {
    state: { listServices },
  } = useServices();

  const [rands, setRands] = useState([]);
  useEffect(() => {
    setRands(getRandomNumbersArray(3, 0, listServices.length - 1));
  }, [listServices]);

  return (
    <Div sx={{ m: 0, px: 50 }}>
      <RowS flexcenter>
        <ColS md="12" lg="12" xl="6">
          <Card shadow="none" hovershadow="none">
            <CardBody
              section1={t("ourServices")}
              section2={t("servicesHead")}
              section3={t("servicesBody")}
              children={
                <Button
                  href="/Services"
                  sx={{
                    py: 10,
                    w: 150,
                    bgc: "primary",
                    c: "white",
                    br: 20,
                  }}
                >
                  <Type var="custom" sx={{ fs: 14 }}>
                    {t("SeeAllServices")}
                  </Type>
                </Button>
              }
            ></CardBody>
          </Card>
        </ColS>
        <ColS md="12" lg="12" xl="6">
          <RowS flexcenter sx={{ h: "100%" }}>
            <ColS md="12" lg="12" xl="6" flexcenter>
              <Card
                shadow={"0px 0px 70px 2px rgba(19, 40, 81, 0.1)"}
                style={{ cursor: "inherit" }}
              >
                <CardService
                  header={
                    <Image
                      src={getServiceImage(listServices[rands[0]]?.serviceID)}
                      sx={{ sqr: 70, mx: 8 }}
                    />
                  }
                  section2={
                    <Type var="h5">{listServices[rands[0]]?.title}</Type>
                  }
                  section3={
                    <Div sx={{ h: 120, ofy: "scroll" }}>
                      <Type var="custom" sx={{ fs: 14, c: "gray2", lh: 0 }}>
                        {listServices[rands[0]]?.description}
                      </Type>
                    </Div>
                  }
                ></CardService>
              </Card>
            </ColS>
            <ColS md="12" lg="12" xl="6">
              <RowS>
                <ColS flexcenter xs={12} sx={{ fxd: "column" }}>
                  <Card
                    shadow={"0px 0px 70px 2px rgba(19, 40, 81, 0.1)"}
                    style={{ cursor: "inherit" }}
                  >
                    <CardService
                      header={
                        <Image
                          src={getServiceImage(
                            listServices[rands[1]]?.serviceID
                          )}
                          sx={{ sqr: 70, mx: 8 }}
                        />
                      }
                      section2={
                        <Type var="h5">{listServices[rands[1]]?.title}</Type>
                      }
                      section3={
                        <Div sx={{ h: 120, ofy: "scroll" }}>
                          <Type var="custom" sx={{ fs: 14, c: "gray2", lh: 0 }}>
                            {listServices[rands[1]]?.description}
                          </Type>
                        </Div>
                      }
                    ></CardService>
                  </Card>
                </ColS>
                <ColS flexcenter xs={12} sx={{ fxd: "column" }}>
                  <Card
                    shadow={"0px 0px 70px 2px rgba(19, 40, 81, 0.1)"}
                    style={{ cursor: "inherit" }}
                  >
                    <CardService
                      header={
                        <Image
                          src={getServiceImage(
                            listServices[rands[2]]?.serviceID
                          )}
                          sx={{ sqr: 70, mx: 8 }}
                        />
                      }
                      section2={
                        <Type var="h5">{listServices[rands[2]]?.title}</Type>
                      }
                      section3={
                        <Div sx={{ h: 120, ofy: "scroll" }}>
                          <Type var="custom" sx={{ fs: 14, c: "gray2", lh: 0 }}>
                            {listServices[rands[2]]?.description}
                          </Type>
                        </Div>
                      }
                    ></CardService>
                  </Card>
                </ColS>
              </RowS>
            </ColS>
          </RowS>
        </ColS>
      </RowS>
    </Div>
  );
}

export default Services;
