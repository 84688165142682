// *** React
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// *** Styles
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";

// *** Routes
import HomeRoutes from "./view/Home/routes";
import LoginRoutes from "./view/Login/routes";
import RegisterRoutes from "./view/Register/routes";
import FortellersRoutes from "./view/Fortellers/routes";
import ServicesRoutes from "./view/Services/routes";
import StoreRoutes from "./view/GemStore/routes";
import HistoryRoutes from "./view/History/routes";

import ProfileRoutes from "./view/Profile/routes";

import AboutUsRoutes from "./view/AboutUs/routes";

// Services and Utilities
import reportWebVitals from "./reportWebVitals";
import Providers from "./services/Providers";
import "./utilities/i18n/index";
import UnderConstruction from "./view/Pages/UnderConstruction";
import Success from "./view/Pages/Success";
import Cancel from "./view/Pages/Cancel";
import ZeroGem from "./view/Pages/ZeroGem";
import Thanks from "./view/Pages/Thanks";
import PagesRoutes from "./view/Pages/routes";

/**import Stripe package to implement payment */
import "@stripe/stripe-js";

import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <FpjsProvider
    loadOptions={{
      apiKey: "2O6s4JREnTLvIHyrN1SI",
    }}
  >
    <Providers>
      <BrowserRouter>
        <Routes>
          {HomeRoutes}
          {LoginRoutes}
          {RegisterRoutes}
          {FortellersRoutes}
          {ServicesRoutes}
          {StoreRoutes}
          {HistoryRoutes}
          {ProfileRoutes}
          {AboutUsRoutes}
          {PagesRoutes}
        </Routes>
      </BrowserRouter>
    </Providers>
  </FpjsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
