// *** React
import React from "react";
import { useTranslation } from "react-i18next";

// *** Styled Components
import { Div } from "../../ui/Div";
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import { useTheme } from "styled-components";
import { Button } from "../../ui/Button";
import Type from "../../ui/Type";
import Image from "../../ui/Image";

// *** Icons
import Flag from "../../assets/loginFlag.svg";

// *** Providers
import { useLocalization } from "../../services/LocalizationProvider";

function AreYouReady() {
  let theme = useTheme();
  const [t] = useTranslation();
  const {
    state: { lang },
  } = useLocalization();

  return (
    <Div sx={{ my: 150 }}>
      <Div gradient="true" sx={{ br: 20, py: 50, px: 40 }}>
        <RowS flexcenter>
          <ColS md={12} lg={8}>
            <RowS flexcenter>
              <ColS md={12} lg={2} flexcenter>
                <Image src={Flag} sx={{ w: 80 }}></Image>
              </ColS>
              <ColS md={12} lg={10}>
                {localStorage.getItem("tobsirLng") === "en" ? (
                  <RowS>
                    <ColS xs={12}>
                      <Div sx={{ gap: 10, d: "flex", ai: "center" }}>
                        <Type var="custom" sx={{ fs: [36, 20] }}>
                          Are you Ready to
                        </Type>{" "}
                        <Type var="custom" sx={{ fs: [36, 20], fw: "bold" }}>
                          Peak,
                        </Type>
                      </Div>
                    </ColS>
                    <ColS xs={12}>
                      <Div sx={{ gap: 10, d: "flex", ai: "center" }}>
                        <Type var="custom" sx={{ fs: [36, 20] }}>
                          into your
                        </Type>{" "}
                        <Type var="custom" sx={{ fs: [36, 20], fw: "bold" }}>
                          Future?
                        </Type>
                      </Div>
                    </ColS>
                  </RowS>
                ) : (
                  <Div>
                    <Div>
                      <Type var="custom" sx={{ fs: [36, 20] }}>
                        هل أنت مستعد للوصول
                      </Type>
                    </Div>{" "}
                    <Div>
                      <Type var="custom" sx={{ fs: [36, 20] }}>
                        إلى مستقبلك؟
                      </Type>
                    </Div>
                  </Div>
                )}
              </ColS>
            </RowS>
          </ColS>
          <ColS md={12} lg={4}>
            <Button
              href="/Register"
              sx={{
                py: "2%",
                px: 10,
                w: "100%",
                bgc: "primary",
                c: "white",
                mt: 20,
                br: 20,
              }}
            >
              <Type var="p">{t("Join for Free")}</Type>
            </Button>
          </ColS>
        </RowS>
      </Div>
    </Div>
  );
}

export default AreYouReady;
