import styled, { css } from "styled-components";
import Input from "./Input";
import Label from "./Label";
import stylesBlocks from "./core/stylesBuilder";

const Wrapper = styled.div`
  margin-bottom: 20px;
  ${(props) =>
    props.noSpace &&
    css`
      margin-bottom: 0;
    `}
  .inputWrap {
    position: relative;
    > span {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      fill: ${(props) => props.theme.color.dark};
      line-height: 0;
    }
  }
  @media (max-width: 720px) {
    // width: 100px;
  }
  p.msg {
    color: ${(props) => props.theme.color.grayText};
    padding-left: 9px;
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 14px;
  }
  &.error p.msg {
    color: ${(props) => props.theme.color.red};
  }
  ${stylesBlocks}
`;

function InputText({
  label,
  value,
  name,
  id,
  onChange,
  placeholder = "",
  type,
  msg = null,
  error = false,
  icon = null,
  size = "large",
  outlineNone,
  paddingcustom,
  bradiusNone,
  widthres,
  inputProps,
  ...props
}) {
  return (
    <Wrapper {...props} className={`${error && "error"}`}>
      {label && <Label htmlFor={id || inputProps?.id}>{label}</Label>}
      <div className="inputWrap">
        {icon && <span>{icon}</span>}
        <Input
          as={type === "textarea" ? "textarea" : "input"}
          id={id || inputProps?.id}
          {...{
            value,
            type,
            name,
            id,
            onChange,
            placeholder,
            outlineNone,
            paddingcustom,
            bradiusNone,
            widthres,
            size,
            ...inputProps,
          }}
        />
      </div>
      {msg && (
        <p className="msg" data-testid={name + "-errormsg"}>
          {msg}
        </p>
      )}
    </Wrapper>
  );
}

export default InputText;
