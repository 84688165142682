import { Form } from "react-bootstrap";
import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

export const CheckS = styled(Form.Check)`
  input:checked,
  input:focus {
    background-color: ${(props) => props.theme.color.gray1};
    border-color: ${(props) => props.theme.color.gray1};
    box-shadow: none;
  }
  ${stylesBlocks}
`;
