import { Route } from "react-router-dom";
import { RequireAuth } from "../../services/auth";
import Store from "./Store";
import GemStore from "./GemStore";
import Subscriptions from "./Subscriptions";
import VideoPage from "./StyledGems/VideoPage";

export default (
  <>
    <Route
      path="/Store"
      exact
      element={
        <RequireAuth>
          <Store />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <GemStore />
          </RequireAuth>
        }
      />
      <Route
        path="Subscriptions"
        element={
          <RequireAuth>
            <Subscriptions />
          </RequireAuth>
        }
      />
      <Route
        path="video"
        element={
          <RequireAuth>
            <VideoPage />
          </RequireAuth>
        }
      />
    </Route>
  </>
);
