// *** React
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// *** Styled Components
import { Div } from "../../ui/Div";
import Header from "../../ui/Header";
import { FormProfile } from "./FormProfile";

// *** Services and Providers
import { useProfile } from "./provider";

// *** Fields
import profileFields from "./profileFields";

// *** YUP
import * as Yup from "yup";

// Schema
const schema = Yup.object().shape({
  firstName: Yup.string().required("Name is required"),
  email: Yup.string().email().required("Email is required"),
  birthPlace: Yup.string().required("Birth Place is required"),
  maritalStatusID: Yup.string().required("Marital Status is required"),
});

export const EditProfile = () => {
  const [t] = useTranslation();
  const {
    state: { selected },
    fetchProfile,
  } = useProfile();

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Div sx={{ m: 30 }}>
      <Header
        title={t("Profile")}
        list={[
          { title: t("Home"), link: "/" },
          { title: t("Profile"), link: "/Profile" },
        ]}
      ></Header>
      <FormProfile fields={profileFields} schema={schema} />
    </Div>
  );
};
