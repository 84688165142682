import { Route } from "react-router-dom";
import { RequireAuth } from "../../services/auth";
import { AboutUs } from "./AboutUs";
import { DetailsAboutUs } from "./DetailsAboutUs";

export default (
  <>
    <Route
      path="/aboutus"
      exact
      element={
        <RequireAuth>
          <AboutUs />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <DetailsAboutUs />
          </RequireAuth>
        }
      />
    </Route>
  </>
);
