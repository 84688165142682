import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import { Register } from "./Register";

export default (
  <Route
    path="/Register"
    exact
    element={
      <RequireAuth>
        <Register />
      </RequireAuth>
    }
  >
    {/* <Route index element={<RequireAuth></RequireAuth>} /> */}
  </Route>
);
