import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Div } from "../../ui/Div";
import Image from "../../ui/Image";
import Type from "../../ui/Type";
import { useTranslation } from "react-i18next";
import CenterImage from "../../assets/register-img.svg";
import { Button } from "../../ui/Button";
import Layout from "../../layout/Layout";
import { useAuth } from "../../services/auth";
const VerifiedEmail = () => {
  let [t] = useTranslation();
  let navigate = useNavigate();

  const { verifyEmail } = useAuth();
  const [error, setError] = useState();
  const param = useParams();
  const [User] = useState(JSON.parse(sessionStorage.getItem("user")));
  useEffect(() => {
    verifyEmail({
      clientID: User?.profile?.clientID,
      encCode: param.key,
      authenticationKey: "123auth123",
    });
  }, [param.key]);
  if (error) return;
  return (
    <Layout>
      <Div loginbg sx={{ mt: 40 }}>
        <Div
          sx={{
            gap: 10,
            d: "flex",
            jc: "center",
            ai: "center",
            fxd: "column",
          }}
        >
          <Image src={CenterImage} sx={{ w: "30%", h: "30%" }} />
          <Type var="Font" sx={{ c: "black2", fs: 30 }}>
            {t("confirmation")}
          </Type>
          <Button
            sx={{
              h: 45,
              w: 150,
              br: 25,
              bgc: "primary",
              c: "white",
              d: "flex",
              jc: "center",
              ai: "center",
            }}
            onClick={() => navigate(-1)}
          >
            {t("Home")}
          </Button>
        </Div>
      </Div>
    </Layout>
  );
};

export default VerifiedEmail;
