import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Div } from "../../ui/Div";
import Image from "../../ui/Image";
import Type from "../../ui/Type";
import centerImage from "../../assets/Component 79 – 1@3x.png";
import { Button } from "@mui/material";
import theme from "../../theme";

const ZeroGem = () => {
  let [t] = useTranslation();
  let navigate = useNavigate();

  return (
    <Div loginbg sx={{ bgc: "gray7", h: "100%" }} flexcenter>
      <Div
        sx={{
          gap: 10,
          d: "flex",
          jc: "center",
          ai: "center",
          fxd: "column",
        }}
      >
        <Image src={centerImage} sx={{ w: "30%", h: "30%" }} />
        <Div sx={{ ta: "center" }}>
          <Type var="Font" sx={{ c: "black2", fs: 30 }}>
            {t("You Have Zero Gems!")}
          </Type>
          <Type var="Font" sx={{ c: "black2", fs: 24 }}>
            {t("Buy from the Gems Store?")}
          </Type>
        </Div>
        <Div>
          <Button
            sx={{
              backgroundColor: theme.color.white,
              color: theme.color.primary,
              border: `1px solid ${theme.color.primary}`,
              borderRadius: 25,
              textTransform: "capitalize",
              width: 150,
              height: 45,
              textAlign: "center",
              mr: 5,
            }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>

          <Button
            sx={{
              backgroundColor: theme.color.primary,
              color: theme.color.white,
              borderRadius: 25,
              textTransform: "capitalize",
              width: 150,
              height: 45,
              textAlign: "center",
              "&:hover": { backgroundColor: theme.color.primary },
            }}
            onClick={() => navigate("/Store")}
          >
            {t("Buy Gems")}
          </Button>
        </Div>
      </Div>
    </Div>
  );
};

export default ZeroGem;
