import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import NextArrow from "../../../assets/NextArrow.svg";
import { Div } from "../../../ui/Div";
import { useHistory } from "../provider";
import { useLocalization } from "../../../services/LocalizationProvider";
import { useTranslation } from "react-i18next";
import { sizes } from "../../../ui/core/stylesBuilder";
const SliderC = styled(Slider).attrs((p) => ({ direct: p.direct }))`
  transition: width 0.5s ease-in-out;
  width: 1000px;

  .slick-next:before {
    background-image: url("${NextArrow}");
    background-size: 40px 40px;
    display: inline-block;
    width: 40px;
    height: 40px;
    content: "";
    transform: ${(p) => p.direct};
  }

  @media (max-width: ${sizes.laptop}) {
    width: 800px;
  }
  @media (max-width: ${sizes.tablet}) {
    width: 400px;
  }
  @media (max-width: ${sizes.mobile}) {
    width: 200px;
  }
`;
const Item = styled.li.attrs((p) => ({
  textDecoration: p.textDecoration,
  offset: p.offset,
}))`
  font-size: 18px;
  padding: 10px;
  text-align: center;
  border: none;
  background-color: transparent;
  margin-top: 11px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: ${(p) => p.textDecoration};
  text-underline-offset: ${(p) => p.offset}px;
  &:focus {
    text-decoration: underline #833dcc;
    text-underline-offset: 10px;
  }
`;
const ListSlider = ({ List }) => {
  let [t] = useTranslation();
  const [serviceIDClicked, setServiceIDClicked] = useState(0);
  const {
    state: { lang },
  } = useLocalization();
  const { dispatch } = useHistory();
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick}></div>;
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          display: "none",
          marginTop: "-55px -22px 0px 0px",
        }}
        onClick={onClick}
      />
    );
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const handleServiceClick = (Id) => {
    dispatch({ type: "set_serviceID", payload: Id });
  };
  if (!List) return;
  return (
    <Div sx={lang === "ar" ? { ml: 50 } : { mr: 70 }}>
      <SliderC {...settings} direct={lang === "ar" ? "rotate(180deg)" : null}>
        {List.length !== 0 ? (
          <Item
            onClick={() => {
              dispatch({ type: "set_serviceID", payload: 0 });
              setServiceIDClicked(0);
            }}
            offset={serviceIDClicked === 0 ? 10 : 0}
            textDecoration={serviceIDClicked === 0 ? "underline #833dcc" : ""}
          >
            {t("All")}
          </Item>
        ) : (
          ""
        )}

        {List?.map((NavItem, index) => (
          <Item
            onClick={() => {
              handleServiceClick(NavItem?.serviceID);
              setServiceIDClicked(NavItem?.serviceID);
            }}
            sx={{ mt: 20, fs: 18, fw: "bold", c: "black3" }}
            key={index}
            offset={serviceIDClicked === NavItem?.serviceID ? 10 : 0}
            textDecoration={
              serviceIDClicked === NavItem?.serviceID ? "underline #833dcc" : ""
            }
          >
            {NavItem.title}
          </Item>
        ))}
      </SliderC>
    </Div>
  );
};

export default ListSlider;
