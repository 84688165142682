// *** React
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

// *** Components
import AddService from "../AddService";

// *** Fields
import soulmateFields from "../Fields/soulmate";

// *** Providers & Utilities
import { useServices } from "../provider";
import { success } from "../../../utilities/ToastContainer";
import { objByStr } from "../../../utilities/functions";

// YUP
import * as Yup from "yup";

// Schema
const schema = Yup.object().shape({
  firstName: Yup.string().required("Name is required"),
  placeOfBirth: Yup.string().required("Place Of Birth is required"),
  motherName: Yup.string().required("Mother Name is required"),
  matesName: Yup.string().required("Mate's Name is required"),
  matesMotherName: Yup.string().required("Mate's Mother Name is required"),
  maritalStatusID: Yup.string().required("Marital Status is required"),
  message: Yup.string().required("Message is required"),
});

function SoulmateService({}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { fortellerID } = useParams();
  const {
    state: { audio, images },
    sendRequest,
  } = useServices();
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [day, setDay] = useState(
    new Date(objByStr(user, "profile.birthDate")).getDate()
  );
  const [month, setMonth] = useState(
    new Date(objByStr(user, "profile.birthDate")).getMonth()
  );
  const [year, setYear] = useState(
    new Date(objByStr(user, "profile.birthDate")).getFullYear()
  );
  const [dayMate, setDayMate] = useState("1");
  const [monthMate, setMonthMate] = useState("1");
  const [yearMate, setYearMate] = useState("1970");
  // Errors State
  const [dateError, setDateError] = useState("");
  const [dateErrorMate, setDateErrorMate] = useState("");
  const [audioError, setAudioError] = useState("");

  const headerList = [
    { title: t("Home"), link: "/" },
    { title: t("Services"), link: "/Services" },
    { title: t("soulmate"), link: "/SOULMATE" },
  ];
  const VerifyValues = () => {
    // DATE
    if (!year && !month && !day) {
      setDateError("Date is required");
      window.scroll(0, 300);
      return false;
    } else setDateError("");
    // Mother
    if (!yearMate && !monthMate && !dayMate) {
      setDateErrorMate("Mother Date is required");
      window.scroll(0, 400);
      return false;
    } else setDateErrorMate("");
    // AUDIO
    if (!audio) {
      setAudioError("Audio is required");
      return false;
    } else setAudioError("");

    return true;
  };
  const onSubmit = async (values) => {
    var bd = new Date(Number(month) + 1 + "/" + day + "/" + year),
      birthDateFormat =
        bd.getFullYear() +
        "-" +
        (bd.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        bd.getDate().toString().padStart(2, "0");
    var bdMate = new Date(
        Number(monthMate) + 1 + "/" + dayMate + "/" + yearMate
      ),
      birthDateFormatMate =
        bdMate.getFullYear() +
        "-" +
        (bdMate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        bdMate.getDate().toString().padStart(2, "0");
    if (!VerifyValues()) {
      return false;
    } else {
      await sendRequest({
        clientID: user?.profile?.clientID,
        serviceID: 6,
        employeeID: fortellerID ? Number(fortellerID) : 0,
        voice: audio?.fileName,
        birthDate: birthDateFormat,
        genderID: Number(values.genderID),
        maritalStatusID: Number(values.maritalStatusID),
        firstName: values.firstName,
        message: values.message,
        birthPlace: values.placeOfBirth,
        motherName: values.motherName,
        mateName: values.matesName,
        mateMotherName: values.matesMotherName,
        mateBirthDate: birthDateFormatMate,
      }).then((res) => {
        if (res.response === 1)
          localStorage.setItem("balance", res.data.financial.balance);
        if (fortellerID) {
          success(t("Thank you, your request sent"));
          setTimeout(() => {
            navigate("/FortellerPage");
          }, 3000);
        } else navigate(`/ChooseForteller/${res?.data?.orderID}/${6}`);
      });
    }
  };

  return (
    <AddService
      heading={"soulmateAdvices"}
      headerList={headerList}
      fields={soulmateFields}
      schema={schema}
      withRecord={true}
      onSubmit={onSubmit}
      day={day}
      setDay={setDay}
      month={month}
      setMonth={setMonth}
      year={year}
      setYear={setYear}
      dayMate={dayMate}
      setDayMate={setDayMate}
      monthMate={monthMate}
      setMonthMate={setMonthMate}
      yearMate={yearMate}
      setYearMate={setYearMate}
      dateErrorMate={dateErrorMate}
      dateError={dateError}
      audioError={audioError}
    />
  );
}

export default SoulmateService;
