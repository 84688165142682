// *** React
import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// *** Styled Components
import styled from "styled-components";
import Image from "../../../ui/Image";
import Type from "../../../ui/Type";
import Icon from "../../../ui/Icon";
import { Div } from "../../../ui/Div";
import { sizes } from "../../../ui/core/stylesBuilder";

// *** Icons and Images
import RequestImage from "../../../assets/request icon.svg";

// *** providers and utilities
import { useFortellers } from "../provider";
import { success } from "../../../utilities/ToastContainer";

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 8fr 2fr;
  gap: 40px;
  row-gap: 0;
  cursor: auto;
  @media (max-width: ${sizes.laptop}) {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

function CardChoose({ forteller, orderID }) {
  const [t] = useTranslation();
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const { chooseForteller } = useFortellers();
  let navigate = useNavigate();
  
  const handleSetForteller = async () => {
    try {
      await chooseForteller({
        clientID: user?.profile?.clientID,
        orderID: orderID,
        employeeID: forteller?.employeeID,
      });
      success(t("Thank you, your request sent"));
      setTimeout(() => {
        navigate("/Services");
      }, 3000);
    } catch (error) {
      navigate("/zeroGem");
    }
  };

  return (
    <GridGroup>
      <Image src={forteller?.avatar} sx={{ sqr: 150, br: 70 }} />
      <Div sx={{ d: "flex", fxd: "column", gap: 10 }}>
        <Type var="h3">{forteller?.employeeName}</Type>
        <Div>
          {forteller?.online ? (
            <Type var="custom" sx={{ c: "green" }}>
              Online
            </Type>
          ) : (
            <Type var="custom" sx={{ c: "red" }}>
              Offline
            </Type>
          )}
          <Div sx={{ d: "inline-flex", mx: 10, gap: 8, ai: "center" }}>
            <Icon name="Gem" w={25} pointer />
            {forteller?.priceText}
          </Div>
        </Div>
        <Div>
          {t("Responds")}
          <Type var="custom" sx={{ c: "primary", px: 20 }}>
            {forteller.responseDuration}
          </Type>
        </Div>
        <Div>
          <StarRatings
            rating={forteller?.rate}
            starRatedColor="#e5bd16"
            numberOfStars={5}
            name="rating"
            starDimension={"25px"}
            starSpacing="5px"
          />
        </Div>
      </Div>
      <Div
        flexcenter
        onClick={handleSetForteller}
        sx={{ cursor: "pointer", fxd: "column" }}
      >
        <Image src={RequestImage} sx={{ w: 70 }}></Image>
      </Div>
    </GridGroup>
  );
}

export default CardChoose;
