import Type from "./Type";
import BreadCrumb from "./BreadCrumb";
import { Div } from "./Div";
import { useLocalization } from "../services/LocalizationProvider";

function Header({ title, list }) {
  const {
    state: { lang },
  } = useLocalization();
  return (
    <Div flexcenter sx={{ fxd: "column" }}>
      <Type
        var={lang === "en" ? "custom" : "lightArabic"}
        sx={{ fs: [40, 40, 25], mb: lang === "en" ? 0 : 20 }}
      >
        {title}
      </Type>
      <BreadCrumb list={list}></BreadCrumb>
    </Div>
  );
}

export default Header;
