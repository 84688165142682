import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { handleBody } from "../../utilities/functions";
import { useLocalization } from "../../services/LocalizationProvider";

const Context = createContext();

export function useWebClient() {
  return useContext(Context);
}

const initState = {
  listServices: [],
  listLives: [],
  selected: null,
  statisticSelected: null,
  status: "idle",
  error: null,
  activeUsers: 0,
  tobsirClients: 0,
  totalReading: 0,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_listServices":
      return { ...state, listServices: [...action.payload] };
    case "set_listLives":
      return { ...state, listLives: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_Statistics":
      return {
        ...state,
        activeUsers: action.payload.activeUsers,
        tobsirClients: action.payload.tobsirClients,
        totalReading: action.payload.totalReading,
      };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function WebClientProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const {
    state: { lang },
  } = useLocalization();

  const fetchLiveFortellers = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
        pageSize: data?.pageSize || 10,
        pageNumber: data?.pageNumber || 1,
      };
      const resData = await req(
        `Broadcast/List`,
        null,
        { method: "GET" },
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_listLives",
        payload: resData.data.broadcasts || [],
      });
    },
    [req]
  );
  const fetchStatistics = useCallback(async () => {
    dispatch({ type: "status", payload: "fetching" });
    let headersData = { lang: lang };
    const resData = await req(`Web/Statistic`, {}, {}, false, headersData);
    dispatch({ type: "status", payload: `idle` });
    dispatch({
      type: "set_Statistics",
      payload: resData?.data || [],
    });
  }, [req]);

  return (
    <Context.Provider
      value={{
        state,
        fetchLiveFortellers,
        fetchStatistics,
      }}
    >
      {children}
    </Context.Provider>
  );
}
