import React from "react";
import Header from "./Header/Header";
import { styled } from "styled-components";
import FooterLayout from "./Footer/FooterLayout";
import stylesBlocks from "../ui/core/stylesBuilder";
import { useLocalization } from "../services/LocalizationProvider";

const Layout = ({ children }) => {
  const Container = styled.div`
    ${stylesBlocks}
  `;
  const {
    state: { lang },
  } = useLocalization();
  return (
    <Container
      sx={{
        ff:
          localStorage.getItem("tobsirLng") === "ar" ||
          !localStorage.getItem("tobsirLng")
            ? "arabicBodyLight"
            : "Poppins",
      }}
    >
      <Header />
      {children}
      <FooterLayout />
    </Container>
  );
};

export default Layout;
