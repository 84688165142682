var apiUrl;

if (process.env.REACT_APP_ENV === "production")
  apiUrl = "https://clientapi.tobsir.com/api";
if (process.env.REACT_APP_ENV === "sandbox")
  apiUrl = "https://clientapi.tobsir.com/api";
else {
  apiUrl = "https://clientapi.tobsir.com/api";
}

export { apiUrl };
