// *** React
import { useRef, useEffect, useState } from "react";

// *** Styled Components
import { styled } from "styled-components";
import { Div } from "../../../ui/Div";
import Icon from "../../../ui/Icon";
import Range from "../../../ui/Range";
import AudioPlayer from "../../../ui/AudioPlayer";
import IconButton from "../../../ui/IconButton";
import Type from "../../../ui/Type";

// *** Providers and Utilities
import { useServices } from "../provider";

// *** Icons and Images

// *** Sound
import MicRecorder from "mic-recorder-to-mp3";
import { sizes } from "../../../ui/core/stylesBuilder";

const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  align-items: center;
  gap: 10px;
  row-gap: 0;
  padding: 20px;
  background-color: #e3e3e3;
  width: 500px;
  border-radius: 10px;
  @media (max-width: ${sizes.tablet}) {
    width: 300px;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  row-gap: 0;
  margin-bottom: 30px;
  @media (max-width: ${sizes.tablet}) {
    flex-direction: column;
  }
`;

function AudioPlayerBox({}) {
  // References
  const audioPlayer = useRef(); // reference our audio component
  const progressBar = useRef(); // reference our progress bar
  const animationRef = useRef(); // reference the animation
  // Provider
  const { uploadAudio } = useServices();
  // States
  var [duration, setDuration] = useState(0); // the total duration of the audio
  const [currentTime, setCurrentTime] = useState(0); // the current time while playing audio
  const [play, setPlay] = useState(false); // is audio playig now Or not?
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blobURL, setBlobURL] = useState(null); // the audio file
  const [intervalID, setIntervalID] = useState(""); // interval for duration counter
  const [Mp3Recorder, setMp3Recorder] = useState(
    new MicRecorder({ bitRate: 64 })
  );
  const [user] = useState(JSON.parse(localStorage.getItem("user")));

  // Use Effects
  useEffect(() => {
    navigator.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        setIsBlocked(false);
      },
      () => {
        console.log("Permission Denied");
        setIsBlocked(true);
      }
    );
  }, []);

  useEffect(() => {
    if (play) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }, [play]);

  useEffect(() => {
    // when the record is finished playing
    if (Number(currentTime) === Number(duration) && blobURL) {
      // Set timeout => for not cutting the last second
      setTimeout(() => {
        setPlay(!play);
      }, 1000);
    }
  }, [currentTime, setPlay]);

  // Upload Audio
  const upload = () => {
    const AudioData = new File([blobURL], "uploaded.mp3", {
      type: "audio/mpeg",
    });
    uploadAudio({
      clientID: user?.profile?.clientID,
      RefID: user?.profile?.clientID,
      data: AudioData,
    });
  };

  // Handlers
  const onDurationChangeHandler = (e) => {
    const seconds = Math.floor(e.target.duration);
    setDuration(seconds);
    progressBar.current.max = seconds;
  };

  const handleSound = () => {
    setPlay(!play);
  };

  const changePlayerCurrentTime = () => {
    // change range progress width
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(progressBar.current.value / duration) * 100}%`
    );

    setCurrentTime(progressBar.current.value);
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  };

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const start = (e) => {
    e.preventDefault();
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
          setIntervalID(
            setInterval(() => {
              setDuration(duration + 1);
              duration++;
            }, 1000)
          );
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = (e) => {
    e.preventDefault();
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobURL(blobURL);
        setIsRecording(false);
        clearInterval(intervalID);
        setIntervalID("");
        upload();
      })
      .catch((e) => console.log(e));
  };

  const reset = () => {
    setDuration(0);
    setBlobURL(null);
    clearInterval(intervalID);
    setIntervalID("");
    setPlay(false);
    setIsRecording(false);
    setIsBlocked(false);
    audioPlayer.current.currentTime = 0;
    progressBar.current.style.setProperty("--seek-before-width", 0);
    document.getElementById("myAudio").src = null;
    setCurrentTime(0);
  };

  return (
    <Container>
      <GridGroup>
        <AudioPlayer
          ref={audioPlayer}
          id="myAudio"
          src={blobURL}
          load="metadata"
          onDurationChange={() => onDurationChangeHandler}
        />
        <Icon
          name={play ? "Pause" : "Play"}
          w={40}
          pointer
          onClick={handleSound}
        />
        <Div>
          <Range ref={progressBar} onChange={changeRange} />
          <Type var="custom" sx={{ fs: 12, c: "dark" }}>
            {!play
              ? duration
                ? calculateTime(duration)
                : calculateTime(currentTime)
              : play
              ? calculateTime(currentTime)
              : calculateTime(duration)}
          </Type>
        </Div>
      </GridGroup>
      <Div>
        {isRecording ? (
          <IconButton>
            <Icon
              name={"Stop"}
              w={25}
              pointer
              onClick={stop}
              disabled={!isRecording}
              c="white"
            />
          </IconButton>
        ) : (
          <IconButton>
            <Icon
              name={"Mic"}
              w={25}
              pointer
              onClick={start}
              disabled={isRecording}
              c="white"
            />
          </IconButton>
        )}
        {!isRecording && blobURL && (
          <IconButton sx={{ p: 15, bgc: "red" }}>
            <Icon
              name={"Trash"}
              w={20}
              pointer
              onClick={reset}
              disabled={isRecording}
              c="white"
            />
          </IconButton>
        )}
      </Div>
    </Container>
  );
}

export default AudioPlayerBox;
