import { css, styled } from "styled-components";

export const ReplyInput = styled.input.attrs(({ defaultValue }) => ({
  defaultValue: defaultValue || "",
}))`
  background-color: ${(props) => props.theme.color.gray0};
  height: 55px;

  ${(props) => {
    if (props.size === "medium")
      return css`
        height: 38px;
        padding: 0 5px 0 20px;
      `;
    else if (props.size === "small")
      return css`
        height: 34px;
        padding: 0 5px 0 14px;
      `;
  }}
  ::placeholder {
    color: ${(p) => p.theme.color.gray1};
  }
  width: 100%;
  border: none;
  border-bottom: solid 1px #ddd;
  font-size: 16px;
  color: ${(p) => p.theme.color.gray1};
  transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);

  &:read-only,
  &:disabled {
    color: ${(p) => p.theme.color.gray1};
    background-color: ${(p) => p.theme.color.gray0};
  }

  ${(props) =>
    props.outlineNone
      ? css`
          outline: none;
        `
      : css`
          outline: solid 1px #e0e0e0;
        `}
  ${(props) =>
    props.paddingcustom
      ? css`
          padding: ${(props) => props.paddingcustom};
        `
      : css`
          padding: 6px 12px 6px 30px;
        `}
  ${(props) =>
    props.type === "textarea" &&
    css`
      height: 120px;
      padding: 26px;
    `} /* &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  } */
`;
