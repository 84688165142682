import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

const AudioPlayer = styled.audio`
  &::-webkit-media-controls-panel {
    border-radius: 0px;
  }
  ${stylesBlocks};
`;

export default AudioPlayer;
