// *** React
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// *** Styled Components
import { Div } from "../../../ui/Div";
import Icon from "../../../ui/Icon";
import Image from "../../../ui/Image";
import IconButton from "../../../ui/IconButton";

// *** Providers and Utilities
import { useProfile } from "../provider";
import { useLocalization } from "../../../services/LocalizationProvider";

function UploadPic({ id }) {
  const [t] = useTranslation();
  const {
    state: { lang },
  } = useLocalization();
  const {
    state: { pic },
    uploadImage,
  } = useProfile();
  const [user] = useState(JSON.parse(localStorage.getItem("user")));

  const fileSelectedHandler = (e, id) => {
    var imagedata = document.querySelector(`#file${id}`).files[0];
    uploadImage(
      {
        // clientID: user?.profile?.clientID,
        RefID: user?.profile?.clientID,
        data: imagedata,
      },
      id
    );
  };

  return (
    <Div sx={{ pos: "relative" }}>
      <Div
        sx={{
          w: "fit-content",
          mb: 40,
        }}
      >
        <Image
          src={pic ? pic?.url : user?.profile?.avatar}
          widthpercentage={35}
          sx={{ sqr: 200, br: 100 }}
        />
      </Div>
      <IconButton
        abs={{
          t: 130,
          r: [lang === "en" ? 30 : 140, , lang === "en" ? 0 : 0],
        }}
        onClick={() => document.getElementById(`file${id}`).click()}
        type="button"
      >
        <Icon name={"PenStroke"} w={25} pointer c="white" />
      </IconButton>
      <input
        className="d-none"
        type="file"
        size="60"
        id={`file${id}`}
        onChange={(e) => fileSelectedHandler(e, id)}
      />
    </Div>
  );
}

export default UploadPic;
