import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { handleBody } from "../../utilities/functions";
import { useLocalization } from "../../services/LocalizationProvider";

const Context = createContext();

export function useServices() {
  return useContext(Context);
}

const initState = {
  listServices: [],
  pics: [],
  images: {
    image1: "",
    image2: "",
    image3: "",
  },
  newService: null,
  audio: null,
  selected: null,
  status: "idle",
  error: null,
  page: 1,
  perPage: 10,
  totalRecords: 0,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_listServices":
      return { ...state, listServices: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_newService":
      return { ...state, newService: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_total_records":
      return { ...state, totalRecords: action.payload };
    case "set_audio":
      return { ...state, audio: action.payload };
    case "set_pic":
      var tmp = state.pics;
      tmp[action.payload.id] = {
        name: action.payload.data.fileName,
        url: action.payload.data.url,
      };
      var tmpImages = state.images;
      tmpImages[`image${action.payload.id + 1}`] = action.payload.data.fileName;
      return { ...state, pics: tmp, images: tmpImages };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function ServicesProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const {
    state: { lang },
  } = useLocalization();

  const fetchServicesList = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = { lang: lang };
      const resData = await req(
        `Service/List`,
        null,
        { method: "GET" },
        false,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_total_records",
        payload: resData.data.length,
      });
      dispatch({
        type: "set_listServices",
        payload: resData.data || [],
      });
    },
    [req, lang]
  );

  const sendRequest = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = { lang: lang };
      const resData = await req(
        `Order/Create`,
        { ...data },
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      // dispatch({ type: "set_newService", payload: resData });
      return resData;
    },
    [req, lang]
  );

  const uploadImage = useCallback(
    async (data, id) => {
      dispatch({ type: "status", payload: "fetching" });
      var dataReq = new FormData();
      dataReq.append("ClientID", data.clientID);
      dataReq.append("RefID", data.RefID);
      dataReq.append("data", data.data);
      dataReq.append("Token", JSON.parse(localStorage.getItem("user"))?.token);
      dataReq.append("Lang", lang);

      const resData = await req(
        `Upload/Image`,
        dataReq,
        { headers: { authenticationKey: "sak_tSk95or04htx8i76inlm==" } },
        true
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_pic",
        payload: { data: resData.data, id: id },
      });
    },
    [req, lang]
  );
  const uploadAudio = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      var dataReq = new FormData();
      dataReq.append("ClientID", data.clientID);
      dataReq.append("RefID", data.RefID);
      dataReq.append("data", data.data);
      dataReq.append("Token", JSON.parse(localStorage.getItem("user"))?.token);
      dataReq.append("Lang", lang);

      const resData = await req(
        `Upload/Voice`,
        dataReq,
        { headers: { authenticationKey: "sak_tSk95or04htx8i76inlm==" } },
        true
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_audio",
        payload: resData.data,
      });
    },
    [req, lang]
  );

  return (
    <Context.Provider
      value={{
        state,
        fetchServicesList,
        sendRequest,
        uploadImage,
        uploadAudio,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
}
