const fields = [
  {
    name: "firstName",
    type: "text",
    placeholder: "Enter your Name",
  },
  {
    name: "email",
    type: "email",
    placeholder: "Enter your Email",
  },
  {
    name: "password",
    type: "password",
    placeholder: "Enter your Password",
  },
  {
    name: "confirmPassword",
    type: "password",
    placeholder: "Confirm your Password",
  },
  {
    name: "genderID",
    type: "select",
    placeholder: "Gender",
    list: [
      { id: 1, name: "male", value: "Male" },
      { id: 2, name: "female", value: "Female" },
    ],
  },
  {
    name: "maritalStatusID",
    type: "radio",
    list: [
      { id: 1, name: "status", value: "single" },
      { id: 2, name: "status", value: "engaged" },
      { id: 3, name: "status", value: "married" },
    ],
  },
];
export default fields;
