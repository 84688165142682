import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import { Login } from "./Login";

export default (
  <Route
    path="/LOGIN"
    exact
    element={
      <RequireAuth>
        <Login />
      </RequireAuth>
    }
  ></Route>
);
