// *** React
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

// *** Styled Components
import Image from "../../ui/Image";
import Icon from "../../ui/Icon";
import Type from "../../ui/Type";
import { Div } from "../../ui/Div";
import { Button } from "../../ui/Button";
import {
  ButtonS,
  ContainerS,
  IconFlex,
  LiS,
  NavDropdownS,
  NavS,
  NavbarS,
} from "./styled";

// *** Icons and Images
import SmallImage from "../../assets/Component 67 – 2.png";
import AppleEnLogo from "../../assets/safarienlogo.jpg";
import AppleArLogo from "../../assets/safariarlogo.png";
import EnLogo from "../../assets/newLogo.svg";
import ArLogo from "../../assets/newLogo1.svg";

// *** Providers
import { useLocalization } from "../../services/LocalizationProvider";
import { useAuth } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { StyledLink } from "../../ui/StyledLink";

function Header() {
  // Hooks
  const { signout } = useAuth();
  const [t, i18n] = useTranslation();
  const { dispatch } = useLocalization();
  const navigate = useNavigate();

  // States
  // const [newNotification, setNewNotification] = useState(true);
  // const [notifications, setNotifications] = useState([]);
  const [EnglishLogo, setEnglishLogo] = useState("");
  const [ArabicLogo, setArabicLogo] = useState("");
  const [user] = useState(JSON.parse(localStorage.getItem("user")));

  // Navbar Tabs
  const tabs = [
    { title: t("about"), href: "/aboutus" },
    { title: t("Services"), href: "/Services" },
    { title: t("forteller"), href: "/FortellerPage" },
    { title: t("gemstore"), href: "/Store" },
    { title: t("history"), href: "/History" },
  ];

  // UseEffects
  useEffect(() => {
    navigator.vendor === "Apple Computer, Inc."
      ? setEnglishLogo(AppleEnLogo)
      : setEnglishLogo(EnLogo);

    navigator.vendor === "Apple Computer, Inc."
      ? setArabicLogo(AppleArLogo)
      : setArabicLogo(ArLogo);
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("tobsirLng"))
  //     dispatch({
  //       type: "set_lang",
  //       payload: localStorage.getItem("tobsirLng"),
  //     });
  // }, [localStorage.getItem("tobsirLng")]);

  useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .setAttribute("lang", localStorage.getItem("tobsirLng"));
    document
      .getElementsByTagName("body")[0]
      .setAttribute(
        "dir",
        localStorage.getItem("tobsirLng") === "en" ? "ltr" : "rtl"
      );
    window.scrollTo(0, 0);
  }, []);

  // Functions
  const changeLanguage = (lang) => {
    sessionStorage.setItem("lang", lang);
    localStorage.setItem("tobsirLng", lang);
    dispatch({ type: "set_lang", payload: lang });
    i18n.changeLanguage(lang);
    document
      .getElementsByTagName("body")[0]
      .setAttribute("dir", lang === "en" ? "ltr" : "rtl");
    document.getElementsByTagName("body")[0].setAttribute("lang", lang);
  };

  const handleLogout = async () => {
    try {
      await signout({
        clientID: user?.profile?.clientID,
      });
      window.location.pathname.includes("/SingleRead")
        ? navigate("/")
        : window.location.pathname === "/History"
        ? navigate("/")
        : window.location.pathname === "/Profile"
        ? navigate("/")
        : window.location.pathname.includes("/Call/")
        ? navigate("/")
        : window.location.pathname.includes("/ChooseForTeller/")
        ? navigate("/")
        : window.location.pathname.includes("/ChangePassword")
        ? navigate("/")
        : window.location.reload();
    } catch (e) {
      window.scrollTo(0, 0);
    }
  };

  document.addEventListener("scroll", function (e) {
    if (document.getElementById("socialClass2"))
      if (window.scrollY < 10) {
        document.getElementById("socialClass2").style.marginTop = "0px";
      } else if (window.scrollY < 150) {
        document.getElementById("socialClass2").style.backgroundColor =
          "transparent";
        document.getElementById("socialClass2").style.marginTop = "-50px";
      } else {
        document.getElementById("socialClass2").style.marginTop = "-100px";
        document.getElementById("socialClass2").style.backgroundColor = "#ddd";
      }
  });

  return (
    <header>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        autoClose={2000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Half moon image */}
      <Image
        src={SmallImage}
        transform={localStorage.getItem("tobsirLng") === "en" ? "" : "reverse"}
        widthpercentage={50}
        heightpercentage={50}
        abs={{
          t: 140,
          r: [
            localStorage.getItem("tobsirLng") === "en" ? 0 : "93.70vw",
            ,
            localStorage.getItem("tobsirLng") === "en" ? 0 : "92vw",
          ],
        }}
        sx={{
          h: [200, 200, 150],
          w: [80, 80, 60],
          d: [, , , "none"],
          tr: "height 0.5s ease-in-out",
          tr: "width 0.5s ease-in-out",
        }}
      />
      <NavbarS expand="xl" sx={{ py: 10, px: 0 }}>
        <ContainerS>
          {/* logo */}
          <NavbarS.Brand>
            <StyledLink to="/">
              <Image
                src={
                  localStorage.getItem("tobsirLng") === "en"
                    ? EnglishLogo
                    : ArabicLogo
                }
                widthpercentage={10}
                sx={{ w: 100, h: 60 }}
              />
            </StyledLink>
          </NavbarS.Brand>
          <NavbarS.Toggle aria-controls="basic-navbar-nav" />
          <NavbarS.Collapse id="basic-navbar-nav">
            {/* tabs */}
            <NavS sx={{ m: "auto" }}>
              {tabs.map((tab, index) => {
                if (tab.title === t("history") && !localStorage.getItem("user"))
                  return null;
                return (
                  <Button
                    key={index}
                    className={"text-uppercase"}
                    sx={{
                      mx: 10,
                      c: "dark1",
                      ":hover": {
                        c: "primary",
                      },
                    }}
                  >
                    <StyledLink to={tab.href}>
                      <Type
                        var={
                          localStorage.getItem("tobsirLng") === "en"
                            ? "custom"
                            : "lightArabic"
                        }
                        sx={{
                          fs:
                            localStorage.getItem("tobsirLng") === "en"
                              ? 16
                              : 20,
                        }}
                      >
                        {tab.title}
                      </Type>
                    </StyledLink>
                  </Button>
                );
              })}
            </NavS>
            <NavS sx={{ pt: 8, m: "inherit" }}>
              <Div
                sx={{
                  d: "flex",
                  fxd: ["row", "row", "column"],
                  ai: [, , "center"],
                }}
              >
                {localStorage.getItem("user") && (
                  <Div
                    flexcenter
                    sx={{ gap: 22, fxd: ["row", "row", "column"] }}
                  >
                    <Div sx={{ cursor: "pointer" }}>
                      <Icon name="Bell" w={18} c="gray4" />
                    </Div>
                    <Div sx={{ cursor: "pointer", gap: 7 }} flexcenter>
                      <Icon name="WalletGem" w={20} c="gray4" />
                      <Div flexcenter sx={{ gap: 3 }}>
                        <Type
                          var="custom"
                          sx={{ lh: 24.57, fs: 14, c: "dark1" }}
                        >
                          {/* {user?.profile?.financial?.balance} */}
                          {localStorage.getItem("balance")}
                        </Type>
                        <Type
                          var="custom"
                          sx={{ lh: 24.57, fs: 14, c: "dark1" }}
                        >
                          {t("Gems")}
                        </Type>
                      </Div>
                    </Div>
                    <Div>
                      <Div>
                        <Div>
                          <Div flexcenter sx={{ cursor: "pointer", gap: 5 }}>
                            <Div sx={{ sqr: 40 }}>
                              <Image
                                src={user?.profile?.avatar}
                                sx={{ br: 20, sqr: "100%" }}
                              />
                            </Div>
                            <Div flexcenter sx={{ gap: 5 }}>
                              <NavDropdownS
                                title={`${t("Hi")} ${user?.displayName}`}
                              >
                                <NavDropdownS.Item
                                  style={{ textAlign: "center" }}
                                >
                                  <StyledLink to="/Profile">
                                    {t("Profile")}
                                  </StyledLink>
                                </NavDropdownS.Item>
                                <NavDropdownS.Item
                                  onClick={() => handleLogout()}
                                  style={{ textAlign: "center" }}
                                >
                                  {t("logout")}
                                </NavDropdownS.Item>
                              </NavDropdownS>
                            </Div>
                          </Div>
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                )}
                <LiS className="d-flex" sx={{ pt: 6 }}>
                  <Div>
                    <ButtonS
                      disabled={
                        localStorage.getItem("tobsirLng") === "en"
                          ? true
                          : false
                      }
                      onClick={() => changeLanguage("en")}
                      sx={{
                        px: 5,
                        w: 40,
                        fs: 16,
                        c:
                          localStorage.getItem("tobsirLng") === "en"
                            ? "dark2"
                            : "dark1",
                        bgc: "none",
                        bs: "none",
                      }}
                    >
                      ENG
                    </ButtonS>
                    <Type var="custom">|</Type>
                    <ButtonS
                      disabled={
                        localStorage.getItem("tobsirLng") === "en"
                          ? false
                          : true
                      }
                      onClick={() => changeLanguage("ar")}
                      sx={{
                        px: 5,
                        fs: 16,
                        c:
                          localStorage.getItem("tobsirLng") === "en"
                            ? "dark1"
                            : "dark2",
                        bgc: "none",
                        bs: "none",
                      }}
                    >
                      AR
                    </ButtonS>
                  </Div>
                </LiS>
              </Div>
              {!localStorage.getItem("user") && (
                <Button
                  href="/LOGIN"
                  c="white !important"
                  bgc="#833dcc !important"
                  sx={{
                    w: 150,
                    bgc: "primary",
                    c: "white",
                    border: [, , , 20],
                  }}
                >
                  <Type
                    var={
                      localStorage.getItem("tobsirLng") === "en"
                        ? "custom"
                        : "lightArabic"
                    }
                    sx={{ fs: 14, c: "white" }}
                  >
                    {t("login")}
                  </Type>
                </Button>
              )}
            </NavS>
          </NavbarS.Collapse>
        </ContainerS>
      </NavbarS>
      {/* Social Icons Large screen */}
      <Div
        sx={{ mb: 50 }}
        className="social-container-lg"
        id="social-container-lg"
      >
        <IconFlex
          sx={{ my: 150 }}
          className="socialClass2-lg"
          id="socialClass2-lg"
        >
          <a href="https://www.facebook.com/tobsirlive">
            <Icon name="Facebook" w={30} />
          </a>
          <a href="https://www.instagram.com/tobsirlive/">
            <Icon name="Twitter" w={30} />
          </a>
        </IconFlex>
      </Div>
      {/* Social Icons Small screen */}
      <Div sx={{ mb: 50 }} className="social-container" id="social-container">
        <IconFlex sx={{ my: 150 }} className="socialClass2" id="socialClass2">
          <a href="https://www.facebook.com/tobsirlive">
            <Icon name="Facebook" w={30} />
          </a>
          <a href="https://www.instagram.com/tobsirlive/">
            <Icon name="Twitter" w={30} />
          </a>
        </IconFlex>
      </Div>
    </header>
  );
}

export default Header;
