const spirtualFields = [
  {
    name: "firstName",
    label: "firstName",
    placeholder: "firstName",
    defaultValue: "profile.firstName",
    gridColumn: ["span 1", "span 1", "span 2", "span 2"],
  },
  {
    name: "motherName",
    label: "motherName",
    placeholder: "motherName",
    gridColumn: ["span 1", "span 1", "span 2", "span 2"],
  },
  {
    name: "birthDate",
    type: "date",
    label: "dateOfBirth",
    placeholder: "Enter your Date",
    defaultValue: "profile.birthDate",
    gridColumn: ["span 1", "span 1", "span 2", "span 2"],
  },
  {
    name: "birthHour",
    type: "time",
    label: "Birth houre",
    placeholder: "Enter your Birth hour",
    gridColumn: ["span 1", "span 1", "span 2", "span 2"],
  },
  {
    name: "genderID",
    type: "select",
    label: "Gender",
    placeholder: "Gender",
    list: [
      { id: 1, name: "female", value: "Female" },
      { id: 2, name: "male", value: "Male" },
    ],
    defaultValue: "profile.genderID",
    gridColumn: ["span 1", "span 1", "span 2", "span 2"],
  },
  {
    name: "maritalStatusID",
    type: "radio",
    list: [
      { id: 1, name: "status", value: "single" },
      { id: 2, name: "status", value: "engaged" },
      { id: 3, name: "status", value: "married" },
    ],
    defaultValue: "profile.maritalStatusID",
    gridColumn: ["span 1", "span 1", "span 2", "span 2"],
  },
  {
    name: "message",
    type: "textarea",
    label: "Write your Message",
    placeholder: "Typing...",
    gridColumn: ["span 2", "span 2", "span 2", "span 2"],
  },
];
export default spirtualFields;
