// *** React
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// React agora live
import "react-agora-live/src/index.css";

// *** Styled Components
import { Div } from "../../ui/Div";
import { useTheme } from "styled-components";
import { Button } from "../../ui/Button";
import Icon from "../../ui/Icon";
import Type from "../../ui/Type";
import Image from "../../ui/Image";
import Card from "../../ui/Card";
import CardBody from "./Styled Components/CardBody";
import CustomSlider from "./Styled Components/CustomSlider";

// *** Providers
import { useLocalization } from "../../services/LocalizationProvider";
import { useWebClient } from "./provider";

function LiveFortellers() {
  let theme = useTheme();
  const [t] = useTranslation();
  const navigate = useNavigate();

  // Live states
  // const [Error, setError] = useState("");
  // const [Users, setUsers] = useState("");
  // const [Reply, setReply] = useState("");
  // const [Status, setStatus] = useState("");

  // let tmp = [
  //   {
  //     broadcastID: 98,
  //     employeeID: 13,
  //     employeename: "test",
  //     title: "ب",
  //     description: "ي",
  //     startDate: "2023-08-22 08:39",
  //     avatar:
  //       "https://aws-s3-tabsir-files.s3.eu-west-1.amazonaws.com/images/avatar.png",
  //     viewers: 0,
  //   },
  // ];

  const {
    state: { lang },
  } = useLocalization();

  const {
    state: { listLives },
    fetchLiveFortellers,
  } = useWebClient();

  useEffect(() => {
    fetchLiveFortellers();
  }, [fetchLiveFortellers, localStorage.getItem("tobsirLng")]);

  const onMouseEnterLive = (id) => {
    document.getElementById(id).style.opacity = "1";
  };
  const onMouseLeaveLive = (id) => {
    document.getElementById(id).style.opacity = "0";
  };

  return (
    <>
      {listLives.length ? (
        <Div sx={{ my: 150 }}>
          <Card shadow="none" hovershadow="none">
            <CardBody
              ai="center"
              section1={t("Our Team In")}
              section2={t("Live Rooms")}
            ></CardBody>
          </Card>
          <CustomSlider>
            {listLives?.map((forteller, idx) => {
              // console.log(forteller, "kk");
              return (
                <Div key={idx}>
                  <Div relative flexcenter sx={{ gap: 40, fxd: "column" }}>
                    <Div
                      relative
                      onMouseEnter={() =>
                        onMouseEnterLive(`live${forteller.broadcastID}`)
                      }
                      onMouseLeave={() =>
                        onMouseLeaveLive(`live${forteller.broadcastID}`)
                      }
                    >
                      <Image src={forteller.avatar} sx={{ sqr: 220 }} />
                      <Div
                        sx={{ op: 0 }}
                        id={`live${forteller.broadcastID}`}
                        transition
                      >
                        <Div
                          abs={{
                            t: 140,
                            r: 70,
                          }}
                          sx={{ br: "50%", cursor: "pointer" }}
                          flexcenter
                        >
                          <Icon name="Play" w={80} />
                        </Div>
                      </Div>
                    </Div>

                    <Div
                      sx={{ ta: "center", gap: 10, d: "flex", fxd: "column" }}
                    >
                      <Type var="h4">{forteller?.employeename}</Type>
                      <Type var="custom" sx={{ fs: 20, c: "gray2" }}>
                        {forteller?.description}
                      </Type>
                    </Div>
                    <Div sx={{ w: "50%" }}>
                      <Button
                        onClick={() =>
                          navigate("/LiveCall", {
                            state: { forteller: forteller },
                          })
                        }
                        sx={{
                          py: "2%",
                          px: "1%",
                          mb: 40,
                          w: "100%",
                          bgc: "primary",
                          c: "white",
                          br: 20,
                        }}
                      >
                        <Type var="p">{t("Join for Free")}</Type>
                      </Button>
                    </Div>
                  </Div>
                </Div>
              );
            })}
          </CustomSlider>
        </Div>
      ) : (
        <></>
      )}
    </>
  );
}

export default LiveFortellers;
