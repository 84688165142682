// *** React
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

// *** Styled Components
import { styled } from "styled-components";
import { Div } from "../../ui/Div";
import { sizes } from "../../ui/core/stylesBuilder";
import { CheckS } from "../../ui/CheckS";
import { SelectS } from "../../ui/SelectS";
import { Button } from "../../ui/Button";
import Type from "../../ui/Type";
import Label from "../../ui/Label";
import InputText from "../../ui/InputText";
import DatePicker from "../../ui/DatePicker";
import AudioPlayerBox from "./Styled Components/AudioPlayerBox";
import UploadPic from "./Styled Components/UploadPic";

// *** YUP
import { yupResolver } from "@hookform/resolvers/yup";

// *** Utilities
import { warning } from "../../utilities/ToastContainer";
import { objByStr } from "../../utilities/functions";

const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;

  @media (max-width: ${sizes.tablet}) {
    grid-template-columns: 1fr;
    gap: 0px;
    row-gap: 10px;
  }
`;
const GridCheckList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 80px;
  padding: 50px 10px 0px 10px;
  @media (max-width: ${sizes.tablet}) {
    grid-template-columns: 1fr;
    gap: 0px;
    row-gap: 10px;
    padding: 20px 10px 0px 10px;
  }
`;

const FormService = ({
  fields,
  schema,
  withPics,
  withRecord,
  onSubmit,
  uploadLabel,
  dateErrorMate,
  dateError,
  imagesError,
  audioError,
  day,
  setDay,
  month,
  setMonth,
  year,
  setYear,
  dayMate,
  setDayMate,
  monthMate,
  setMonthMate,
  yearMate,
  setYearMate,
}) => {
  // translation
  const [t] = useTranslation();

  // navigate
  const navigate = useNavigate();

  // [params]
  const { fortellerID } = useParams();

  // Use State
  const [error, setError] = useState(null);
  const [user] = useState(JSON.parse(localStorage.getItem("user")));

  const [defaultDate, setDefaultDate] = useState(
    new Date(objByStr(user, "profile.birthDate"))
  );
  const [defaultDateMate, setDefaultDateMate] = useState(
    new Date(`${yearMate}-${monthMate}-${dayMate}`)
  );

  // Use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    var d = new Date(year, month, day);
    setDefaultDate(d);
  }, [day, month, year]);

  useEffect(() => {
    var d = new Date(yearMate, monthMate, dayMate);
    setDefaultDateMate(d);
  }, [dayMate, monthMate, yearMate]);

  function isValidDate(year, month, day) {
    var d = new Date(year, month, day);
    return (
      Number(d?.getFullYear()) === Number(year) &&
      Number(d?.getMonth()) === Number(month) &&
      Number(d?.getDate()) === Number(day)
    );
  }
  const checkday = (day) => {
    if (isValidDate(year, month, day)) setDay(day);
    else warning(t("invalid date"));
  };

  const checkmonth = (month) => {
    if (isValidDate(year, month, day)) setMonth(month);
    else warning(t("invalid date"));
  };

  const checkyear = (year) => {
    if (isValidDate(year, month, day)) setYear(year);
    else warning(t("invalid date"));
  };

  const checkdayMate = (dayMate) => {
    if (isValidDate(yearMate, monthMate, dayMate)) setDayMate(dayMate);
    else warning(t("invalid mate date"));
  };

  const checkmonthMate = (monthMate) => {
    console.log(monthMate, "monthmate");
    if (isValidDate(yearMate, monthMate, dayMate)) setMonthMate(monthMate);
    else warning(t("invalid mate date"));
  };

  const checkyearMate = (yearMate) => {
    if (isValidDate(yearMate, monthMate, dayMate)) setYearMate(yearMate);
    else warning(t("invalid mate date"));
  };

  return (
    <Div
      sx={{ px: [120, 120, 10] }}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {withPics && (
        <Div>
          <Div>
            <Type var="h4" sx={{ fs: [, , 20] }}>
              {t(uploadLabel)}
            </Type>
          </Div>
          <Div
            sx={{
              d: "flex",
              gap: [60, 60, 0],
              w: ["100%", "80%"],
              fxd: ["row", "row", "column"],
              ml: [0, 0, 50],
            }}
          >
            <UploadPic id={0} />
            <UploadPic id={1} />
            <UploadPic id={2} />
          </Div>
          {imagesError && (
            <Type var="custom" sx={{ c: "red", fs: 14 }}>
              {imagesError}
            </Type>
          )}
        </Div>
      )}
      <GridGroup>
        {error && error}
        {fields?.map((field, index) => {
          return field?.type === "select" ? (
            <Div
              sx={
                field?.gridColumn && {
                  gc: field?.gridColumn,
                }
              }
            >
              <Label>{t(field.label)}</Label>
              <SelectS
                key={index}
                dir={localStorage.getItem("tobsirLng") === "en" ? "ltr" : "ltr"}
                {...register(field.name)}
                defaultValue={objByStr(user, field?.defaultValue)}
              >
                <option disabled>Open this select menu</option>
                {field?.list?.map((li, ind) => (
                  <option value={li?.id} key={ind}>
                    {t(li?.value)}
                  </option>
                ))}
              </SelectS>
            </Div>
          ) : field?.type === "radio" ? (
            <Div
              sx={
                field?.gridColumn && {
                  gc: field?.gridColumn,
                }
              }
            >
              <GridCheckList>
                {field?.list?.map((li, ind) => (
                  <CheckS
                    type={field?.type}
                    name={li?.name}
                    id={li?.id}
                    key={ind}
                    value={li?.id}
                    label={t(li?.value)}
                    defaultChecked={
                      objByStr(user, field?.defaultValue) === li?.id
                    }
                    {...register(field.name)}
                  />
                ))}
              </GridCheckList>
              <Type var="custom" sx={{ c: "red", fs: 14 }}>
                {errors[field?.name]?.message}
              </Type>
            </Div>
          ) : field?.type === "date" ? (
            <Div
              sx={
                field?.gridColumn && {
                  gc: field?.gridColumn,
                }
              }
            >
              <Label>{t(field.label)}</Label>
              <DatePicker
                handleDay={
                  field?.label === "mdateOfBirth" ? checkdayMate : checkday
                }
                handleMonth={
                  field?.label === "mdateOfBirth" ? checkmonthMate : checkmonth
                }
                handleYear={
                  field?.label === "mdateOfBirth" ? checkyearMate : checkyear
                }
                date={
                  field?.label === "mdateOfBirth"
                    ? defaultDateMate
                    : defaultDate
                }
              />
              {field?.label === "mdateOfBirth"
                ? dateErrorMate && (
                    <Type var="custom" sx={{ c: "red", fs: 14 }}>
                      {dateErrorMate}
                    </Type>
                  )
                : dateError && (
                    <Type var="custom" sx={{ c: "red", fs: 14 }}>
                      {dateError}
                    </Type>
                  )}
            </Div>
          ) : (
            <InputText
              sx={
                field?.gridColumn && {
                  gc: field?.gridColumn,
                  mt: field?.type === "textarea" && 50,
                }
              }
              key={index}
              type={field.type}
              label={t(field.label)}
              placeholder={t(field.placeholder)}
              error={!!errors?.[field.name]}
              msg={errors[field.name]?.message}
              widthres="100%"
              size="small"
              inputProps={{
                ...register(field.name),
                defaultValue: objByStr(user, field?.defaultValue),
                size: "sm",
              }}
            />
          );
        })}
      </GridGroup>

      {withRecord && (
        <Div flexcenter sx={{ fxd: "column" }}>
          <AudioPlayerBox />
          {audioError && (
            <Type var="custom" sx={{ c: "red", fs: 14 }}>
              {audioError}
            </Type>
          )}
        </Div>
      )}

      <Div sx={{ w: "95%", px: 10 }}>
        <Button
          as="button"
          type="submit"
          onClick={() => !localStorage?.getItem("user") && navigate("/LOGIN")}
          sx={{
            p: 10,
            my: 5,
            w: "100%",
            bgc: "primary",
            c: "white",
            br: 30,
            bs: "none",
          }}
        >
          <Type var="p" c="white">
            {fortellerID ? t("sendRequest") : t("choose")}
          </Type>
        </Button>
      </Div>
    </Div>
  );
};

export default FormService;
