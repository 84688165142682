// *** React
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// *** Styled Components
import { Div } from "../../ui/Div";
import { SelectS } from "../../ui/SelectS";
import InputText from "../../ui/InputText";
import "react-toastify/dist/ReactToastify.css";

// *** Icons and Images
import { styled } from "styled-components";
import { CheckS } from "../../ui/CheckS";
import { sizes } from "../../ui/core/stylesBuilder";
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import { Button } from "../../ui/Button";
import Type from "../../ui/Type";
import Icon from "../../ui/Icon";

// *** Providers
import { useLocalization } from "../../services/LocalizationProvider";
import { useAuth } from "../../services/auth";

// *** Yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// *** Fields
import fields from "./fields";
import { useFirebaseSection } from "../Firebase/provider";

const schema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(4, "Too Short, a password with at least 4 letters is required!"),
  confirmPassword: Yup.string().when("password", (password, field) =>
    password
      ? field
          .required("R")
          .oneOf(
            [Yup.ref("password")],
            "confirm password must match the password field"
          )
      : field
  ),
  genderID: Yup.number().required("Required"),
  maritalStatusID: Yup.number().required("Required"),
});

const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
  @media (max-width: ${sizes.laptop}) {
    grid-template-columns: 1fr;
  }
`;
const GridCheckList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 80px;
  padding: 12px 10px 0px 10px;
  @media (max-width: ${sizes.laptop}) {
    grid-template-columns: 1fr;
    gap: 0px;
    row-gap: 10px;
    padding: 20px 10px 0px 10px;
  }
`;

export const Register = () => {
  const { registeration } = useAuth();
  const { signInWithGoogle, signInWithFacebook, signInWithApple } =
    useFirebaseSection();
  const {
    state: { lang },
  } = useLocalization();

  const [t] = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [error, setError] = useState(null);
  //Sign up with google
  const handleSignUpGoogle = async () => {
    try {
      await signInWithGoogle();
    } catch (err) {
      toast.error(err.message);
      console.log(err, "error");
    }
  };
  //Sign up with facebook
  const handleSignUpFacebook = async () => {
    try {
      await signInWithFacebook();
    } catch (err) {
      toast.error(err.message);
      console.log(err, "error");
    }
  };
  //Sign up with apple
  const handleSignUpApple = async () => {
    try {
      await signInWithApple();
    } catch (err) {
      toast.error(err.message);
      console.log(err, "error");
    }
  };
  const onSubmit = async (values) => {
    setError(null);
    try {
      await registeration(values);
      reset();
      // navigate("/");
      window.location.href = "/CheckEmail";
    } catch (e) {
      window.scrollTo(0, 0);
      console.log("e", e);
    }
  };

  return (
    <Div loginbg sx={{ bgc: "gray7" }}>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        autoClose={2000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Div
        onClick={() => navigate(-1)}
        sx={{ cursor: "pointer", pr: 20, d: "flex", jc: "flex-end" }}
      >
        <Icon name="Close" w={50} />
      </Div>
      <Div
        sx={{
          h: "fit-content",
          gap: 25,
          d: "flex",
          jc: "top",
          ai: "center",
          fxd: "column",
        }}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Div sx={{ mb: 20 }}>
          <Type var="custom" sx={{ fs: 32, fw: 400 }}>
            {t("Create an account")}
          </Type>
        </Div>
        <Div>
          <Type var="custom" sx={{ fs: 20, fw: 400, ta: "center" }}>
            {t("Choose one of te following registration methods")}
          </Type>
        </Div>
        <Div sx={{ w: ["45%", , "80%"] }}>
          <RowS>
            <ColS>
              <Button
                sx={{
                  p: 10,
                  my: 5,
                  w: "100%",
                  bgc: "blue",
                  c: "white",
                  br: 30,
                }}
                onClick={handleSignUpFacebook}
              >
                <Icon name="WithFacebook" w={150} />
              </Button>
            </ColS>
            <ColS>
              <Button
                sx={{
                  p: 10,
                  my: 5,
                  w: "100%",
                  bgc: "red",
                  c: "white",
                  br: 30,
                }}
                onClick={handleSignUpGoogle}
              >
                <Icon name="WithGoogle" w={150} />
              </Button>
            </ColS>
            <ColS>
              <Button
                sx={{
                  p: 10,
                  my: 5,
                  w: "100%",
                  bgc: "black1",
                  c: "white",
                  br: 30,
                }}
                onClick={handleSignUpApple}
              >
                <Icon name="WithApple" w={120} />
              </Button>
            </ColS>
          </RowS>
        </Div>
        <Div>
          <Type var="custom" sx={{ fs: 20, fw: 400, ta: "center" }}>
            {t("Or register using your email address")}
          </Type>
        </Div>
        <Div
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ w: ["60%", , "80%"] }}
        >
          <GridGroup>
            {error && error}
            {fields?.map((field, index) => {
              return field?.type === "select" ? (
                <SelectS
                  aria-label="Default select example"
                  key={index}
                  {...register(field.name)}
                >
                  <option disabled>Open this select menu</option>
                  {field?.list?.map((li, ind) => (
                    <option value={li?.id} key={ind}>
                      {t(li?.value)}
                    </option>
                  ))}
                </SelectS>
              ) : field?.type === "radio" ? (
                <GridCheckList>
                  {field?.list?.map((li, ind) => (
                    <CheckS
                      type={field?.type}
                      name={li?.name}
                      id={li?.id}
                      key={ind}
                      value={li?.id}
                      label={t(li?.value)}
                      {...register(field.name)}
                    />
                  ))}
                </GridCheckList>
              ) : (
                <InputText
                  key={index}
                  type={field.type}
                  label={t(field.label)}
                  placeholder={t(field.placeholder)}
                  error={!!errors?.[field.name]}
                  msg={errors[field.name]?.message}
                  widthres="auto"
                  inputProps={{
                    ...register(field.name),
                    autoFocus: true,
                  }}
                />
              );
            })}
          </GridGroup>
        </Div>

        <Div sx={{ w: ["45%", , "80%"] }}>
          <Button
            as="button"
            type="submit"
            sx={{
              p: 10,
              my: 5,
              w: "100%",
              bgc: "primary",
              c: "white",
              br: 30,
              bs: "none",
            }}
          >
            <Type var={lang === "en" ? "p" : "lightArabic"} c="white">
              {t("Register")}
            </Type>
          </Button>
        </Div>

        <Div flexcenter sx={{ w: "40%", my: 10, gap: 7, fxd: "column" }}>
          <Type var="custom" sx={{ fs: 16, fw: 400, ta: "center" }}>
            {t("By registering you confirm that you accept Tobsir")}{" "}
          </Type>
          <Div onClick={() => navigate("/Terms")} sx={{ cursor: "pointer" }}>
            <Type
              var="custom"
              sx={{ fs: 16, fw: 500, ta: "center", c: "primary" }}
            >
              {t("Terms and Policy")}{" "}
            </Type>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};
