import React from "react";
import { Div } from "../../ui/Div";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../GemStore/provider";
import Header from "../../ui/Header";
import { Col, Container, Row } from "react-bootstrap";
import Icon from "../../ui/Icon";

const Terms = () => {
  let [t] = useTranslation();
  let navigate = useNavigate();
  const { id } = useParams();
  const {
    state: { balance },
    SetStatus,
  } = useStore();

  return (
    <Div loginbg sx={{ bgc: "gray7", h: "fit-content" }}>
      <Div
        onClick={() => navigate(-1)}
        sx={{ cursor: "pointer", pr: 20, d: "flex", jc: "flex-end" }}
      >
        <Icon name="Close" w={50} />
      </Div>
      <Div flexcenter sx={{ pb:20, fxd: "column", gap: 60 }}>
        <Header
          title={t("Terms and Policy")}
          list={[
            { title: t("Home"), link: "/" },
            { title: t("Terms and Policy"), link: "/Terms" },
          ]}
        ></Header>
      </Div>
      <Container>
        <Row>
          <Col xs="12" md="12" className="hero-left-text">
            <h2 style={{ fontSize: "22px", color: "#222", fontWeight: "600" }}>
              {t("T1M")}
            </h2>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T1B")} </p>
          </Col>

          <Col xs="12">
            <h2 style={{ fontSize: "22px", color: "#222", fontWeight: "600" }}>
              {t("T2M")}
            </h2>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T2B")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T2B1")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T2B2")} </p>

            <p style={{ cursor: "auto", color: "#838383" }}>{t("T2B3")} </p>

            <p style={{ cursor: "auto", color: "#838383" }}>{t("T2B4")} </p>

            <p style={{ cursor: "auto", color: "#838383" }}>{t("T2B5")} </p>

            <p style={{ cursor: "auto", color: "#838383" }}>{t("T2B6")} </p>

            <p style={{ cursor: "auto", color: "#838383" }}>{t("T2B7")} </p>

            <p style={{ cursor: "auto", color: "#838383" }}>{t("T2B8")} </p>

            <p style={{ cursor: "auto", color: "#838383" }}>{t("T2B9")} </p>

            <h2 style={{ fontSize: "22px", color: "#222", fontWeight: "600" }}>
              {t("T3M")}
            </h2>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B2")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B3")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B4")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B5")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B6")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B7")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B8")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B9")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B10")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B11")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B12")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B13")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B14")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B15")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B16")} </p>
            <p style={{ cursor: "auto", color: "#838383" }}>{t("T3B17")} </p>
          </Col>
        </Row>
      </Container>
    </Div>
  );
};

export default Terms;
