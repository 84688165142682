import React from "react";
import styled from "styled-components";
const Loader = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  &:after {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    top: -30px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    border: 6px solid #833dcc;
    border-top: 6px dotted #833dcc;
    border-bottom: 6px dotted #833dcc;
    border-radius: 50%;
    animation: loading 1.7s infinite;
  }
  &:after {
    font-family: "Lobster", cursive;
    font-size: 20px;
    letter-spacing: 1px;
    color: #833dcc;
    position: absolute;
    top: 57%;
    text-align: center;
    right: 0;
    left: 0;
    margin: auto;
  }
  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(360deg);
    }
  }
`;
const Loading = () => {
  return <Loader />;
};

export default Loading;
