import Type from "./Type";
import { Div } from "./Div";
import Icon from "./Icon";
import { useNavigate } from "react-router-dom";
import { useLocalization } from "../services/LocalizationProvider";

function BreadCrumb({ list }) {
  const {
    state: { lang },
  } = useLocalization();
  const navigate = useNavigate();
  return (
    <Div sx={{ gap: 10, d: "flex", jc: "space-around", ai: "space-around" }}>
      {list?.map((li, idx) => {
        return (
          <Div
            key={idx}
            sx={{ gap: 10, d: "flex", jc: "space-around", ai: "center" }}
          >
            <Type
              var={lang === "en" ? "custom" : "lightArabic"}
              sx={{
                fs: [18, 18, 14],
                fw: "bold",
                c: "primary",
                cursor: "pointer",
              }}
              onClick={() => navigate(li?.link)}
            >
              {li?.title}
            </Type>
            {idx !== list?.length - 1 && <Icon name="Dot" w={8} />}
          </Div>
        );
      })}
    </Div>
  );
}

export default BreadCrumb;
