import Slider from "react-slick";
import { styled } from "styled-components";

const SliderC = styled(Slider)`
  & .slick-dots li button:before {
    opacity: 1 !important;
    font-size: 7px;
  }
  & .slick-active button:before {
    color: #833dcc !important;
    border-radius: 100%;
    border: 2px solid #833dcc;
  }
`;

function CustomSlider({ children }) {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };
  return (
    <>
      <SliderC {...settings}>{children}</SliderC>
    </>
  );
}

export default CustomSlider;
