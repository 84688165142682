import styled from "styled-components";

const Label = styled.label`
  font-size: 16px;
  color: ${(props) => props.theme.color.dark};
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
`;

export default Label;
