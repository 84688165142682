// *** React
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// *** Styled Components
import { Div } from "../../ui/Div";
import InputText from "../../ui/InputText";
import "react-toastify/dist/ReactToastify.css";

// *** Icons and Images
import Logo from "../../assets/MainLogo.svg";
import Image from "../../ui/Image";
import Type from "../../ui/Type";
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import { Button } from "../../ui/Button";
import Icon from "../../ui/Icon";

// *** Providers
import { useLocalization } from "../../services/LocalizationProvider";
import { useAuth } from "../../services/auth";

// *** Yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// *** Fields
import fields from "./fields";

// *** Toast
import { ToastContainer, toast } from "react-toastify";
import { useFirebaseSection } from "../Firebase/provider";
import ModalBox from "../../ui/ModalBox";
import NoRegisterModal from "./StyledComponents/NoRegisterModal";

const schema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(4, "Too Short, a password with at least 4 letters is required!"),
});

export const Login = () => {
  const { signin, forgetPassword } = useAuth();
  const { SignInGoogle, SignInFacebook, SignInApple } = useFirebaseSection();
  const {
    state: { lang },
  } = useLocalization();

  const [t] = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [error, setError] = useState(null);
  //Sign in Facebook
  const handleSignInFacebook = async () => {
    try {
      await SignInFacebook();
    } catch (err) {
      toast.error(err.message);
      console.log(err, "error");
    }
  };
  //Sign in Google
  const handleSignInGoogle = async () => {
    try {
      await SignInGoogle();
    } catch (err) {
      toast.error(err.message);
      console.log(err, "error");
    }
  };
  //sign in Apple
  const handleSignInApple = async () => {
    try {
      await SignInApple();
    } catch (err) {
      toast.error(err.message);
      console.log(err, "error");
    }
  };

  //onSubmit to login with user name and password
  const onSubmit = async (values) => {
    setError(null);
    try {
      await signin({
        email: values.email,
        password: values.password,
        socialMediaID: 0,
        socialMediaProfileID: "string",
      });
      reset();
      // navigate("/");
      // window.location.href = "/";
    } catch (e) {
      window.scrollTo(0, 0);
      console.log("e", e);
      if (e.errors.response === 33) {
        setOpen(true);
      } else {
        toast.error(e.errors.message);
      }
    }
  };
  const handleForgetPassword = async () => {
    if (!getValues("email")) {
      toast.error("Please, enter your email in the email field");
      return;
    }
    try {
      await forgetPassword({ email: getValues("email") });
      navigate("/CheckEmail");
    } catch (error) {
      console.log(error);
      toast.error(error?.errors?.message);
    }
  };
  return (
    <>
      <Div loginbg sx={{ bgc: "gray7" }}>
        <ToastContainer
          position="top-center"
          hideProgressBar={false}
          autoClose={2000}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Div
          onClick={() => navigate(-1)}
          sx={{ cursor: "pointer", pr: 20, d: "flex", jc: "flex-end" }}
        >
          <Icon name="Close" w={50} />
        </Div>
        <Div
          sx={{
            h: "fit-content",
            gap: 10,
            d: "flex",
            jc: "top",
            ai: "center",
            fxd: "column",
          }}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Div>
            <Image src={Logo} widthpercentage={10} sx={{ w: 250, h: 140 }} />
          </Div>
          <Div>
            <Type var="custom" sx={{ fs: 28, fw: 400 }}>
              {t("Welcome back to Tobsir!")}
            </Type>
          </Div>
          <Div sx={{ w: ["45%", , "80%"] }}>
            {error && error}
            {fields?.map((field, index) => {
              return (
                <InputText
                  key={index}
                  type={field.type}
                  label={t(field.label)}
                  placeholder={t(field.placeholder)}
                  error={!!errors?.[field.name]}
                  msg={errors[field.name]?.message}
                  inputProps={{
                    ...register(field.name),
                    autoFocus: true,
                  }}
                />
              );
            })}
            <Div
              onClick={handleForgetPassword}
              sx={{ cursor: "pointer", my: 10, d: "flex", jc: "flex-end" }}
            >
              <Type var="custom" sx={{ fs: 14, fw: 500, c: "background1" }}>
                {t("Forgot Password")}
              </Type>
            </Div>
          </Div>

          <Div sx={{ w: ["45%", , "80%"] }}>
            <Button
              as="button"
              type="submit"
              sx={{
                p: 10,
                w: "100%",
                bgc: "primary",
                c: "white",
                br: 30,
                bs: "none",
              }}
            >
              <Type var={lang === "en" ? "p" : "lightArabic"}>
                {t("Login")}
              </Type>
            </Button>
          </Div>
          <Div sx={{ w: ["45%", , "80%"] }}>
            <RowS>
              <ColS>
                <Button
                  sx={{
                    p: 10,
                    my: 5,
                    w: "100%",
                    bgc: "blue",
                    c: "white",
                    br: 30,
                  }}
                  onClick={handleSignInFacebook}
                >
                  <Icon name="WithFacebook" w={150} />
                </Button>
              </ColS>
              <ColS>
                <Button
                  sx={{
                    p: 10,
                    my: 5,
                    w: "100%",
                    bgc: "red",
                    c: "white",
                    br: 30,
                  }}
                  onClick={handleSignInGoogle}
                >
                  <Icon name="WithGoogle" w={150} />
                </Button>
              </ColS>
              <ColS>
                <Button
                  sx={{
                    p: 10,
                    my: 5,
                    w: "100%",
                    bgc: "black1",
                    c: "white",
                    br: 30,
                  }}
                  onClick={handleSignInApple}
                >
                  <Icon name="WithApple" w={120} />
                </Button>
              </ColS>
            </RowS>
          </Div>
          <Div flexcenter sx={{ my: 10, w: "40%", gap: 7, fxd: "column" }}>
            <Type
              var="custom"
              sx={{ c: "gray8", fw: 400, fs: 18, ta: "center" }}
            >
              {t("Don't have an account ?")}
            </Type>
            <Div
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/Register")}
            >
              <Type
                var="custom"
                sx={{ c: "primary", fw: 500, fs: 18, ta: "center" }}
              >
                {t("Register here.")}
              </Type>
            </Div>
          </Div>
        </Div>
      </Div>
      <NoRegisterModal open={open} handleClose={() => setOpen(false)} />
    </>
  );
};
