// *** React
import React from "react";

// *** Styled Component
import styled from "styled-components";

const Card = styled.div.attrs((p) => {
  p.mb = p.mb ? p.mb : 0;
  p.mt = p.mt ? p.mt : 0;
})`
  width: 250px;
  height: 300px;
  background-color: white;
  padding: 25px 10px;
  margin: 20px 5px;
  box-shadow: 0px 40px 70px #4747471a;
  margin-bottom: ${(p) => p.mb}px;
  margin-top: ${(p) => p.mt}px;
  position: relative;
  @media (max-width: 1024px) {
    margin: 0;
    width: 150px;
  }
`;
const CollectionCard = ({ children, mb, mt, onClick }) => {
  return (
    <Card mb={mb} mt={mt} onClick={onClick}>
      {children}
    </Card>
  );
};

export default CollectionCard;
