import { Route } from "react-router-dom";
import UnderConstruction from "./UnderConstruction";
import Success from "./Success";
import Cancel from "./Cancel";
import ZeroGem from "./ZeroGem";
import Thanks from "./Thanks";
import FAQ from "./FAQ";
import VerifiedEmail from "./VerifiedEmail";
import CheckEmail from "./CheckEmail";
import Terms from "./Terms";

export default (
  <Route>
    <Route path="UnderConstruction" element={<UnderConstruction />} />
    <Route path="success/:id" element={<Success />} />
    <Route path="cancel/:id" element={<Cancel />} />
    <Route path="zeroGem" element={<ZeroGem />} />
    <Route path="thanks" element={<Thanks />} />
    <Route path="FAQ" element={<FAQ />} />
    <Route path="confirmemail/:key" element={<VerifiedEmail />} />
    <Route path="/CheckEmail" element={<CheckEmail />} />
    <Route path="/Terms" element={<Terms />} />
  </Route>
);
