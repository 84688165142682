// *** React
import React from "react";

// *** Styled Components
import styled from "styled-components";

const Card = styled.div.attrs((p) => {
  p.boxShadow = p.boxShadow ? p.boxShadow : "";
})`
  width: 300px;
  height: 291px;
  background-color: white;
  padding: 25px 10px;
  margin: 20px 5px;
  cursor: pointer;
  box-shadow: ${(p) => p.boxShadow};
  @media (max-width: 1024px) {
    margin: 10px 0;
    width: 200px;
  }
`;
const GemsCard = ({ children, boxShadow, onCllick }) => {
  return (
    <Card boxShadow={boxShadow} onClick={onCllick}>
      {children}
    </Card>
  );
};

export default GemsCard;
