// *** React
import { useTranslation } from "react-i18next";

// *** Styled Components
import { Div } from "../../ui/Div";
import Header from "../../ui/Header";
import FormService from "./FormService";

function AddService({
  heading,
  headerList,
  fields,
  schema,
  withPics,
  withRecord,
  onSubmit,
  uploadLabel,
  day,
  setDay,
  month,
  setMonth,
  year,
  setYear,
  dayMate,
  setDayMate,
  monthMate,
  setMonthMate,
  yearMate,
  setYearMate,
  dateErrorMate,
  dateError,
  imagesError,
  audioError,
}) {
  const [t] = useTranslation();

  return (
    <>
      <Div flexcenter sx={{ m: 30 }}>
        <Header title={t(heading)} list={headerList}></Header>
      </Div>
      <FormService
        schema={schema}
        fields={fields}
        withPics={withPics}
        withRecord={withRecord}
        onSubmit={onSubmit}
        uploadLabel={uploadLabel}
        day={day}
        setDay={setDay}
        month={month}
        setMonth={setMonth}
        year={year}
        setYear={setYear}
        dayMate={dayMate}
        setDayMate={setDayMate}
        monthMate={monthMate}
        setMonthMate={setMonthMate}
        yearMate={yearMate}
        setYearMate={setYearMate}
        dateErrorMate={dateErrorMate}
        dateError={dateError}
        imagesError={imagesError}
        audioError={audioError}
      />
    </>
  );
}

export default AddService;
