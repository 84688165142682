import styled from "styled-components";
import { Nav } from "react-bootstrap";
import stylesBlocks from "./core/stylesBuilder";

export const Button = styled(Nav.Link)`
  text-align: center;
  &:hover {
    color: white;
  }

  ${stylesBlocks}
`;
