import React from "react";
import ModalBox from "../../../ui/ModalBox";
import { Div } from "../../../ui/Div";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RegisterImg from "../../../assets/register-img.svg";
import theme from "../../../theme";
import Type from "../../../ui/Type";
import Image from "../../../ui/Image";
import { Button } from "@mui/material";

const NoRegisterModal = ({ open, handleClose }) => {
  let navigate = useNavigate();
  let [t] = useTranslation();
  return (
    <ModalBox open={open} handleClose={handleClose}>
      <Div flexcenter>
        <Image src={RegisterImg} sx={{ w: "30%", h: "30%" }} />
      </Div>
      <Div flexcenter sx={{ mt: 20 }}>
        <Type var="Font" sx={{ c: "black2", fs: 36 }}>
          {t("You are not Registered!")}
        </Type>
      </Div>
      <Div flexcenter sx={{ mt: 20 }}>
        <Type var="Font" sx={{ c: "gray10", fs: 20 }}>
          {t("Please create an account to receive your reading.")}
        </Type>
      </Div>
      <Div flexcenter sx={{ mt: 20 }}>
        <Button
          sx={{
            backgroundColor: theme.color.white,
            color: theme.color.primary,
            border: `1px solid ${theme.color.primary}`,
            borderRadius: 25,
            textTransform: "capitalize",
            width: 150,
            height: 45,
            textAlign: "center",
            mr: 5,
          }}
          onClick={handleClose}
        >
          {t("Cancel")}
        </Button>

        <Button
          sx={{
            backgroundColor: theme.color.primary,
            color: theme.color.white,
            borderRadius: 25,
            textTransform: "capitalize",
            width: 150,
            height: 45,
            textAlign: "center",
            "&:hover": { backgroundColor: theme.color.primary },
          }}
          onClick={() => navigate("/Register")}
        >
          {t("Register")}
        </Button>
      </Div>
    </ModalBox>
  );
};

export default NoRegisterModal;
