import styled from "styled-components";
import { Nav } from "react-bootstrap";
import stylesBlocks from "./core/stylesBuilder";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #4f4f6f;
  &:hover {
    color: #833dcc;
  }

  ${stylesBlocks}
`;
