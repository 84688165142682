import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

const Body = styled.div`
  border: dashed 2px white;
  border-radius: 5px;
  width: 180px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
  padding: 15px 20px;
  color: white;
  &:hover {
    box-shadow: 0px 0px 30px 4px rgb(166 164 182 / 0.5);
    // cursor: pointer;
  }
  ${stylesBlocks}
`;

function CounterCard({ children, ...props }) {
  return (
    <>
      <Body {...props}>{children}</Body>
    </>
  );
}

export default CounterCard;
