import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRequest } from "./request";
import { handleLoginError } from "../utilities/handlers/handleLoginError";
import { error, warning } from "../utilities/ToastContainer";
import { useLocalization } from "./LocalizationProvider";
import { handleBody } from "../utilities/functions";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [newLive, setNewLive] = useState(true);
  const [newOnline, setNewOnline] = useState(true);
  const {
    state: { lang },
  } = useLocalization();
  const req = useRequest();

  sessionStorage.setItem("lang", "ar");

  let isLoggedIn = useCallback(() => {
    return JSON.parse(localStorage.getItem("user"))?.token;
  }, [JSON.parse(localStorage.getItem("user"))]);

  let registeration = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        let headersData = {
          method: "POST",
          lang: lang,
        };
        try {
          const resData = await req(
            `Web/SignUp`,
            data,
            { method: "POST" },
            false,
            headersData
          );
          if (data.socialMediaID) window.location.href = "/LOGIN";
          else window.location.href = "/CheckEmail";

          // console.log(resData, "data");
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  let verifyEmail = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          let headersData = {
            method: "POST",
            lang: lang,
          };
          const res = await req(
            `Web/VerifyEmail`,
            data,
            { method: "POST" },
            false,
            headersData
          );
          window.location.href = "/LOGIN";
          resolve(res.data);
        } catch (error) {
          console.log(error, "catch");
        }
      });
    },
    [req]
  );
  let signin = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          let headersData = { lang: lang };
          const res = await req(
            `Web/Login`,
            data,
            { method: "POST" },
            false,
            headersData
          );
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem(
            "balance",
            JSON.stringify(res.data.user.profile.financial.balance)
          );
          localStorage.setItem(
            "profile",
            JSON.stringify(res.data.user.profile)
          );
          localStorage.setItem(
            "tokens",
            JSON.stringify({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
              IssuedAtTime: res.data.issuedAt,
              exp: res.data.expiration,
            })
          );
          resolve(res);
          window.location.href = "/";
        } catch (err) {
          error(err.errors.message);
          // reject(err);
        }
      });
    },
    [req]
  );

  let signout = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          let headersData = { lang: lang };
          const resData = await req(
            `Web/Logout`,
            null,
            { method: "POST" },
            true,
            headersData
          );

          resolve(resData);
          localStorage.removeItem("user");
          localStorage.removeItem("balance");
          localStorage.removeItem("profile");
          localStorage.removeItem("tokens");
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  let resetPass = useCallback(
    (email) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(`Web/ResetPassword/`, { email }, {});
          localStorage.setItem("resetPassEmail", email);
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let changePass = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const email = localStorage.getItem("resetPassEmail");
          const res = await req(
            `auth/password/reset/confirm/`,
            { ...data, email },
            {}
          );
          localStorage.removeItem("resetPassEmail");
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let changePass2 = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          let headersData = { lang: lang };

          const res = await req(
            `Web/ChangePassword`,
            { ...data },
            {},
            true,
            headersData
          );
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let forgetPassword = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          let headersData = { lang: lang };

          const res = await req(
            `Web/ForgotPassword`,
            { ...data },
            {},
            false,
            headersData
          );
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );
  let resetPassword = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          let headersData = { lang: lang };
          const res = await req(
            `Web/ResetPassword`,
            { ...data },
            {},
            false,
            headersData
          );
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );
  const saveTokens = useCallback(
    (newTokens) => {
      const userTokens = {
        ...JSON.parse(localStorage.getItem("user")),
        tokens: {
          ...JSON.parse(localStorage.getItem("user")).token,
          ...newTokens,
        },
      };
      localStorage.setItem("user", JSON.stringify(userTokens));
    },
    [JSON.parse(localStorage.getItem("user"))]
  );

  let value = {
    newLive,
    setNewLive,
    newOnline,
    setNewOnline,
    signin,
    signout,
    registeration,
    verifyEmail,
    saveTokens,
    resetPass,
    changePass,
    changePass2,
    forgetPassword,
    resetPassword,
    isLoggedIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }) {
  // const location = useLocation();
  // let { user } = useAuth();

  // if (!user?.tokens)
  //   return <Navigate to="/login" state={{ from: location }} replace />;

  return children;
}
