// *** React
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState } from "react";

// *** Styled Components
import styled from "styled-components";
import { sizes } from "../../ui/core/stylesBuilder";
import { Div } from "../../ui/Div";
import { Button } from "../../ui/Button";
import InputText from "../../ui/InputText";
import Type from "../../ui/Type";

// *** YUP
import { yupResolver } from "@hookform/resolvers/yup";

// *** Toast
import { success, warning } from "../../utilities/ToastContainer";

// *** Services and Providers
import { useLocalization } from "../../services/LocalizationProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../services/auth";

const GridGroup = styled.div`
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;

  @media (max-width: ${sizes.tablet}) {
    grid-template-columns: 1fr;
    gap: 0px;
    row-gap: 10px;
    width: 100%;
  }
`;

// Replace user with selected

export const FormChangePass = ({ fields, schema }) => {
  const [t] = useTranslation();
  const { changePass2, resetPassword } = useAuth();
  let { key } = useParams();
  // Use State
  const [error, setError] = useState(null);

  // Use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      if (values?.oldPassword) await changePass2(values);
      else await resetPassword({ ...values, encCode: key });
      window.location.href = "/LOGIN";
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <Div
      sx={{ px: [80, 0, 0], fxd: "column" }}
      flexcenter
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <GridGroup>
        {error && error}
        {fields?.map((field, index) => {
          return (
            <InputText
              style={{
                gridColumn: field?.gridColumn,
                marginTop: field?.gridColumn && 50,
              }}
              key={index}
              type={field.type}
              label={t(field.label)}
              placeholder={t(field.placeholder)}
              error={!!errors?.[field.name]}
              msg={errors[field.name]?.message}
              widthres="auto"
              inputProps={{
                ...register(field.name),
              }}
            />
          );
        })}
      </GridGroup>
      <Div sx={{ w: "50%", px: 10 }}>
        <Button
          as="button"
          type="submit"
          sx={{
            p: 10,
            my: 5,
            w: "100%",
            bgc: "primary",
            c: "white",
            br: 30,
            bs: "none",
          }}
        >
          <Type var="p" c="white">
            {t("save")}
          </Type>
        </Button>
      </Div>
    </Div>
  );
};
