// *** React
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// *** Styled Components
import Card from "../../../ui/Card";
import Icon from "../../../ui/Icon";
import Type from "../../../ui/Type";

// *** Providers and Utilities
import { useServices } from "../provider";

function UploadPic({ id }) {
  const [t] = useTranslation();
  const {
    state: { images, pics },
    uploadImage,
  } = useServices();
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const fileSelectedHandler = (e, id) => {
    var imagedata = document.querySelector(`#file${id}`).files[0];
    uploadImage(
      {
        clientID: user?.profile?.clientID,
        RefID: user?.profile?.clientID,
        data: imagedata,
      },
      id
    );
  };

  return (
    <Card
      shadow="none"
      hovershadow="none"
      sx={{
        border: ["grayOpacity1", "0.01em", "solid", 10, "tlrb"],
        px: 20,
        py: 30,
        fxd: "column",
        gap: 20,
        bgc: "gray7",
        cursor: "pointer",
      }}
      flexcenter
      onClick={() => document.getElementById(`file${id}`).click()}
    >
      {images[`image${id + 1}`] ? (
        <img src={pics[id]?.url} style={{ width: 100, height: 100 }} />
      ) : (
        <>
          <Icon name="Camera" w={45} />
          <Type var="small">{t("upload picture")}</Type>
        </>
      )}

      <input
        className="d-none"
        type="file"
        size="60"
        id={`file${id}`}
        onChange={(e) => fileSelectedHandler(e, id)}
      />
    </Card>
  );
}

export default UploadPic;
