// *** React
import React, { useState } from "react";

// *** Styled Components
import { styled } from "styled-components";
import { sizes } from "../../ui/core/stylesBuilder";

// *** Components
import Hero from "./Hero";
import About from "./About";
import Services from "./Services";
import Counter from "./Counter";
import AreYouReady from "./AreYouReady";
import Team from "./Team";
import LiveFortellers from "./LiveFortellers";

export const ContainerBox = styled.div`
  padding: 110px 100px;
  @media (max-width: ${sizes.mobile}) {
    padding: 20px 0px;
  }
  @media (max-width: ${sizes.tablet}) {
    padding: 20px 20px;
  }
`;
function Container() {
  const [user] = useState(JSON.parse(localStorage.getItem("user")));

  return (
    <ContainerBox>
      <Hero />
      <About />
      <Services />
      <Counter />
      <LiveFortellers />
      {!user && <AreYouReady />}
      <Team />
    </ContainerBox>
  );
}

export default Container;
