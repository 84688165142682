// *** React
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

// *** React agora live
import { BtnJoin } from "react-agora-live";
import "react-agora-live/src/index.css";

// *** Styled Components
import { Div } from "../../ui/Div";
import Type from "../../ui/Type";
import { Button } from "../../ui/Button";

// *** Provider
import { useFortellers } from "./provider";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

function LiveCall() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { reportBroadcast } = useFortellers();

  const { forteller } = location.state;
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  // Live states
  const [Error, setError] = useState("");
  const [Users, setUsers] = useState("");
  const [Reply, setReply] = useState("");
  const [Status, setStatus] = useState("");
  const { isLoading, error, data, getData } = useVisitorData(
    { extendedResult: true },
    { immediate: true }
  );
  useEffect(() => {
    getData({ ignoreCache: true });
  }, []);

  const accessToken = JSON.parse(localStorage.getItem("tokens"))?.accessToken;
  const headers = {
    authenticationKey: "sak_tSk95or04htx8i76inlm==",
  };
  if (accessToken) headers.Authorization = `Bearer ${accessToken}`;
  const referenceID = accessToken ? String(user?.userID) : data?.visitorId;

  useEffect(() => {
    if (Reply === "user-published") {
      document.getElementById("liveButtonYes").style.visibility = "hidden";
      document.getElementById("liveButtonNo").style.visibility = "hidden";
    } else if (Reply === "user-unpublished") {
      reportBroadcast({
        broadcastID: forteller?.broadcastID,
        referenceID: referenceID,
      });
    }
    // console.log(Reply, "Reply");
  }, [Reply]);

  return (
    <Div flexcenter sx={{ sqr: "100%", fxd: "column" }}>
      <Div flexcenter sx={{ w: "50%", gap: 40, fxd: "column" }}>
        <Div flexcenter sx={{ fxd: "column" }}>
          <Div>Are you sure you want to join broadcast?</Div>
        </Div>
        <Div sx={{ w: "100%", d: "flex", jc: "space-between" }}>
          <BtnJoin
            fetchHeaders={headers}
            fetchURL={"https://clientapi.tobsir.com/api/Broadcast/Channel"}
            endURL={"https://clientapi.tobsir.com/api/Broadcast/Leave"}
            clientID={user?.profile?.clientID}
            broadcastID={forteller?.broadcastID}
            referenceID={referenceID}
            token={user?.token}
            setError={setError}
            users={Users}
            setUsers={setUsers}
            setReply={setReply}
            setStatus={setStatus}
            btnText={
              <Button
                sx={{
                  py: 5,
                  px: 40,
                  w: 100,
                  bgc: "primary",
                  c: "white",
                  br: 20,
                }}
                id="liveButtonYes"
              >
                <Type var="p" sx={{ fs: 14 }}>
                  {t("Yes")}
                </Type>
              </Button>
            }
          />

          <Button
            onClick={() => navigate(-1)}
            sx={{
              py: 5,
              px: 40,
              w: 100,
              bgc: "primary",
              c: "white",
              br: 20,
            }}
            id="liveButtonNo"
          >
            <Type var="custom" sx={{ fs: 14 }}>
              {t("No")}
            </Type>
          </Button>
        </Div>
      </Div>
    </Div>
  );
}

export default LiveCall;
