// *** React
import React from "react";
import { useTranslation } from "react-i18next";

// *** Styled Components
import { ContainerS, FooterItem, ListGroupSItem } from "./styled";
import { Div } from "../../ui/Div";
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import { ListGroupS } from "../../ui/ListGroupS";
import Image from "../../ui/Image";
import Icon from "../../ui/Icon";
import Type from "../../ui/Type";

// *** Icons and Images
import Plant from "../../assets/Plant.png";

const FooterLayout = () => {
  const [t] = useTranslation();

  return (
    <Div sx={{ mt: 600 }}>
      <hr style={{ border: "0.01em #37353224 solid", marginBottom: -240 }} />
      <ContainerS>
        <RowS>
          <ColS xs="12" md="5">
            <Image
              src={Plant}
              widthpercentage={35}
              sx={{ ml: [-80,,0], w: [500, 400, 290] }}
            />
          </ColS>
          <ColS xs="12" md="7">
            <Div sx={{ pt: [280, 100], d: "flex", fxd: "column" }}>
              <RowS sx={{ ai: "center" }}>
                <ColS xs="12" md="4">
                  <ListGroupS variant="flush">
                    <ListGroupSItem>
                      <FooterItem
                        to="/aboutus"
                        sx={{ ":hover": { c: "primary" } }}
                      >
                        {t("about")}
                      </FooterItem>
                    </ListGroupSItem>
                    <ListGroupSItem>
                      <FooterItem
                        sx={{ ":hover": { c: "primary" } }}
                        to="/Services"
                      >
                        {t("Services")}
                      </FooterItem>
                    </ListGroupSItem>
                    <ListGroupSItem className="reacharge-plan">
                      <FooterItem
                        sx={{ ":hover": { c: "primary" } }}
                        to="/Store"
                      >
                        {t("Recharge Plan")}
                      </FooterItem>
                    </ListGroupSItem>
                  </ListGroupS>
                </ColS>
                <ColS xs="12" md="4">
                  <ListGroupS variant="flush">
                    <ListGroupSItem>
                      <FooterItem
                        sx={{ ":hover": { c: "primary" } }}
                        to="/Store/Subscriptions"
                      >
                        {t("VIP Client")}
                      </FooterItem>
                    </ListGroupSItem>
                    <ListGroupSItem>
                      <FooterItem sx={{ ":hover": { c: "primary" } }} to="/FAQ">
                        {t("FAQ")}
                      </FooterItem>
                    </ListGroupSItem>
                    <ListGroupSItem>
                      <FooterItem
                        sx={{ ":hover": { c: "primary" } }}
                        to="/UnderConstruction"
                      >
                        {t("contactUs")}
                      </FooterItem>
                    </ListGroupSItem>
                  </ListGroupS>
                </ColS>
                <ColS xs="12" md="4">
                  <ListGroupS variant="flush">
                    <ListGroupSItem>
                      <FooterItem to="#">
                        <Icon name="Apple" w={175} h={45} />
                      </FooterItem>
                    </ListGroupSItem>
                    <ListGroupSItem>
                      <FooterItem to="#">
                        <Icon name="GooglePlay" w={175} h={45} />
                      </FooterItem>
                    </ListGroupSItem>
                  </ListGroupS>
                </ColS>
              </RowS>
            </Div>
          </ColS>
        </RowS>
        <RowS
          sx={{
            border: ["grayOpacity1", "0.01em", "solid", 0, "t"],
            py: 20,
            mt: 50,
          }}
        >
          <ColS xs="12" md="9">
            <Type var="small">{t("copyLeft")}</Type>
          </ColS>
          <ColS xs="12" md="3">
            <Div sx={{ gap: 15, d: "flex" }}>
              <Type
                var="small"
                // onClick={() => navigate("/Terms")}
              >
                {t("Terms and Policy")}
              </Type>
              <Type var="small"> | </Type>
              <Type var="small"> {t("24/7 Support")}</Type>
            </Div>
          </ColS>
        </RowS>
      </ContainerS>
    </Div>
  );
};

export default FooterLayout;
