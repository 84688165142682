import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

const Range = styled.input.attrs(() => ({
  type: "range",
  defaultValue: 0,
}))`
  --bar-bg: #fff;
  --seek-before-width: 0;
  --seek-before-color: #833dcc;
  --seek-after-color: #833dcc;
  --knobby: #3452a5;
  --selectedKnobby: #26c9c3;

  appearance: none;
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 6px;
  outline: none;

  /* progress bar - safari */
  &::-webkit-slider-runnable-track {
    background: var(--bar-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 6px;
    outline: none;
  }
  /* progress bar - firefox */
  &::-moz-range-track {
    background: var(--bar-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 6px;
    outline: none;
  }
  &::-moz-focus-outer {
    border: 0;
  }
  /* progress bar - chrome and safari */
  &::before {
    content: "";
    height: 6px;
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
    border-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }
  &::after {
    content: "";
    height: 6px;
    width: var(--seek-before-width);
    background-color: var(--seek-after-color);
    border-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }
  /* progress bar - firefox */
  &::-moz-range-progress {
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 6px;
  }
  /* knobby - chrome and safari */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: none;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    margin: -2px 0 0 0;
    z-index: 3;
    box-sizing: border-box;
    display: none;
  }
  /* knobby while dragging - chrome and safari */
  &:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }
  /* knobby - firefox */
  &::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: transparent;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
  }
  /* knobby while dragging - firefox */
  &:active::-moz-range-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }
  ${stylesBlocks};
`;

export default Range;
