// *** React
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// *** Styled Components
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import { Button } from "../../ui/Button";
import { Div } from "../../ui/Div";
import Type from "../../ui/Type";
import Image from "../../ui/Image";

// *** Icons & Images
import HeroImage from "../../assets/Home page .svg";
import VideoImage from "../../assets/video call.svg";
import AudioImage from "../../assets/audio call.svg";
import RequestImage from "../../assets/request icon.svg";

// *** Providers
import { useLocalization } from "../../services/LocalizationProvider";

function Hero() {
  const [t] = useTranslation();
  const {
    state: { lang },
  } = useLocalization();
  const navigate = useNavigate();

  const [user] = useState(JSON.parse(localStorage.getItem("user")));

  return (
    <Div>
      <RowS sx={{ pb: 50 }}>
        <ColS xs="12" md="5">
          <RowS>
            <ColS md="12">
              {user ? (
                localStorage.getItem("tobsirLng") === "en" ? (
                  <Div sx={{ d: "flex", fxd: "column" }}>
                    <Div sx={{ fs: ["2.5em", "1.8em"], c: "dark3" }}>
                      Have{" "}
                      <Type var="custom" sx={{ fw: "bold" }}>
                        No
                      </Type>
                    </Div>
                    <Div sx={{ fs: ["2.5em", "1.8em"], c: "dark3" }}>
                      <Type
                        var="custom"
                        sx={{ fstyle: "italic", c: "primary" }}
                      >
                        Time
                      </Type>{" "}
                      to
                      <Type
                        var="custom"
                        sx={{ fstyle: "italic", c: "primary" }}
                      >
                        wait?
                      </Type>
                    </Div>
                    <Div>
                      <Type var="p">Get the blue gem!</Type>
                    </Div>
                  </Div>
                ) : (
                  <Div sx={{ d: "flex", fxd: "column" }}>
                    <Div
                      sx={{
                        fs: ["2.5em", "1.8em"],
                        c: "dark3",
                        gap: 10,
                        d: "flex",
                        ai: "center",
                      }}
                    >
                      <Type var="custom" sx={{ fw: 400 }}>
                        لا
                      </Type>
                      <Type var="lightArabic">يوجد</Type>
                      <Type var="custom" sx={{ c: "primary", fw: 400 }}>
                        وقت
                      </Type>
                      <Type var="lightArabic" c="#833DCC">
                        للانتظار
                      </Type>
                    </Div>
                    <Div sx={{ fs: "1em" }}>
                      <Type var="custom">احصل على الجواهر الآن!</Type>
                    </Div>
                  </Div>
                )
              ) : localStorage.getItem("tobsirLng") === "en" ? (
                <Div sx={{ d: "flex", fxd: "column" }}>
                  <Div sx={{ fs: ["2.5em", "1.8em"], c: "dark3" }}>
                    <Type var="custom" sx={{ fw: "bold" }}>
                      Live chat
                    </Type>{" "}
                    with{" "}
                    <Type var="custom" sx={{ fw: "bold" }}>
                      real
                    </Type>
                  </Div>
                  <Div sx={{ fs: ["2.5em", "1.8em"], c: "dark3" }}>
                    <Type
                      var="custom"
                      sx={{ c: "primary", fstyle: "italic", fw: 400 }}
                    >
                      fortune-tellers
                    </Type>{" "}
                    and
                  </Div>
                  <Div sx={{ fs: ["2.5em", "1.8em"], c: "dark3" }}>
                    <Type
                      var="custom"
                      sx={{ c: "primary", fstyle: "italic", fw: 400 }}
                    >
                      spiritual advisors.
                    </Type>
                  </Div>
                  <Div>
                    <Type var="p">Your First Reading is Free!</Type>
                  </Div>
                </Div>
              ) : (
                <Div sx={{ d: "flex", fxd: "column" }}>
                  <Div sx={{ fs: ["2.5em", "1.8em"], c: "dark3" }}>
                    <Type var="lightArabic">تواصل مباشر مع مجموعة</Type>
                  </Div>
                  <Div sx={{ fs: ["2.5em", "1.8em"], c: "dark3" }}>
                    <Type var="custom" sx={{ c: "primary" }}>
                      من اهم علماء الفلك على
                    </Type>
                  </Div>
                  <Div sx={{ fs: ["2.5em", "1.8em"], c: "dark3" }}>
                    <Type var="custom" sx={{ c: "primary" }}>
                      تبصير لايف{" "}
                    </Type>
                  </Div>
                  <Div sx={{ fs: "1em" }}>
                    <Type var="custom">احصل على القراءة الاولى مجاناً!!</Type>
                  </Div>
                </Div>
              )}
            </ColS>
            <ColS md="12">
              <Button
                href={user ? "/Store/Subscriptions" : "/Register"}
                sx={{
                  py: 8,
                  my: 30,
                  w: "70%",
                  bgc: "primary",
                  c: "white",
                  br: 20,
                }}
              >
                <Type var="p">
                  {user ? t("Subscribe") : t("Join Now hero")}
                </Type>
              </Button>
            </ColS>
          </RowS>
        </ColS>

        <ColS xs="12" md="7">
          <img
            src={HeroImage}
            alt="Hero"
            style={{ width: "100%", height: "100%" }}
          />
        </ColS>
      </RowS>
      <RowS>
        <ColS xs="12" md="4">
          <Div
            flexcenter
            sx={{ cursor: "pointer", gap: [10, 30] }}
            onClick={() => navigate("/FortellerPage")}
          >
            <Image src={VideoImage} sx={{ w: 80, mr: [0, 30] }}></Image>
            <Type var="custom" fw="bold" sx={{ fw: "bold", c: "dark4" }}>
              {t("VideoCall")}
            </Type>
          </Div>
        </ColS>
        <ColS xs="12" md="4">
          <Div
            flexcenter
            sx={{ cursor: "pointer", gap: [10, 30], mt: [0, 10] }}
            onClick={() => navigate("/FortellerPage")}
          >
            <Image src={AudioImage} sx={{ w: 70, mr: [0, 30] }}></Image>
            <Type var="custom" fw="bold" sx={{ fw: "bold", c: "dark4" }}>
              {t("AudioCall")}
            </Type>
          </Div>
        </ColS>
        <ColS xs="12" md="4">
          <Div
            flexcenter
            sx={{ cursor: "pointer", gap: [10, 0], mt: [0, 10] }}
            onClick={() => navigate("/Services")}
          >
            <Image
              src={RequestImage}
              sx={{
                w: 70,
                mr: [0, 20],
                ml: localStorage.getItem("tobsirLng") === "en" ? [0] : [30],
              }}
            ></Image>
            <Type var="custom" fw="bold" sx={{ fw: "bold", c: "dark4" }}>
              {t("chat")}
            </Type>
          </Div>
        </ColS>
      </RowS>
    </Div>
  );
}

export default Hero;
