import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import { Services } from "./Services";
import ListServices from "./ListServices";
import CoffeeService from "./AllServices/CoffeeService";
import DreamService from "./AllServices/DreamService";
import FaceService from "./AllServices/FaceService";
import HoroscopeService from "./AllServices/HoroscopeService";
import PalmService from "./AllServices/PalmService";
import SoulmateService from "./AllServices/SoulmateService";
import TarotService from "./AllServices/TarotService";
import SpirtualService from "./AllServices/SpirtualService";

export default (
  <>
    <Route
      path="/Services"
      exact
      element={
        <RequireAuth>
          <Services />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <ListServices />
          </RequireAuth>
        }
      />
    </Route>
    <Route
      path="/COFFEE"
      exact
      element={
        <RequireAuth>
          <Services />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <CoffeeService />
          </RequireAuth>
        }
      />
      <Route path=":fortellerID" element={<CoffeeService />} />
    </Route>
    <Route
      path="/DREAM"
      exact
      element={
        <RequireAuth>
          <Services />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <DreamService />
          </RequireAuth>
        }
      />
      <Route path=":fortellerID" element={<DreamService />} />
    </Route>
    <Route
      path="/FACE"
      exact
      element={
        <RequireAuth>
          <Services />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <FaceService />
          </RequireAuth>
        }
      />
      <Route path=":fortellerID" element={<FaceService />} />
    </Route>
    <Route
      path="/HOROSCOPE"
      exact
      element={
        <RequireAuth>
          <Services />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <HoroscopeService />
          </RequireAuth>
        }
      />
      <Route path=":fortellerID" element={<HoroscopeService />} />
    </Route>
    <Route
      path="/PALM"
      exact
      element={
        <RequireAuth>
          <Services />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <PalmService />
          </RequireAuth>
        }
      />
      <Route path=":fortellerID" element={<PalmService />} />
    </Route>
    <Route
      path="/SOULMATE"
      exact
      element={
        <RequireAuth>
          <Services />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <SoulmateService />
          </RequireAuth>
        }
      />
      <Route path=":fortellerID" element={<SoulmateService />} />
    </Route>
    <Route
      path="/SPIRTUAL"
      exact
      element={
        <RequireAuth>
          <Services />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <SpirtualService />
          </RequireAuth>
        }
      />
      <Route path=":fortellerID" element={<SpirtualService />} />
    </Route>
    <Route
      path="/TAROT"
      exact
      element={
        <RequireAuth>
          <Services />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <TarotService />
          </RequireAuth>
        }
      />
      <Route path=":fortellerID" element={<TarotService />} />
    </Route>
  </>
);
