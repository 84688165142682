import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";
import { MonthPicker } from "react-dropdown-date";

export const MonthPickerS = styled(MonthPicker).attrs((p) => ({
  value: p.value !== undefined ? p.value : "MM",
  required: true,
  classes: "picker",
}))`
  ${stylesBlocks}
`;
