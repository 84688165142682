import React from "react";
import { Div } from "../../ui/Div";
import { ToastContainer } from "react-toastify";
import Header from "../../ui/Header";
import { useTranslation } from "react-i18next";
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import Icon from "../../ui/Icon";
import Type from "../../ui/Type";
import LocalizationProvider from "../../services/LocalizationProvider";
import SubscriptionCard from "./StyledGems/SubscriptionCard";
import { useStore } from "./provider";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Subscriptions = () => {
  const [t] = useTranslation();
  const {
    state: { listSubscriptionOffers },
    fetchSubscriptionOffers,
  } = useStore();

  useEffect(() => {
    fetchSubscriptionOffers({
      pageSize: 100,
      pageNumber: 1,
    });
  }, [fetchSubscriptionOffers]);

  return (
    <Container>
      <Div flexcenter sx={{ fxd: "column" }}>
        <ToastContainer
          position="top-center"
          hideProgressBar={false}
          autoClose={2000}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Div flexcenter sx={{ m: "30px" }}>
          <Header
            title={t("Gems Store")}
            list={[
              { title: t("Home"), link: "/" },
              { title: t("Subscription"), link: "/Store/Subscriptions" },
            ]}
          ></Header>
        </Div>
        <Div gradient="true" sx={{ br: 20, py: 50, px: 30, w: 1300 }}>
          <RowS>
            <ColS md={12} lg={1}>
              <Div sx={{ bgc: "white", br: 10, p: 4, w: 80, h: 80 }} flexcenter>
                <Icon name="GemBlue" w={65} c="white" />
              </Div>
            </ColS>
            <ColS md={9} lg={10}>
              <Div>
                <Type var="Font" sx={{ fs: 35 }}>
                  {t("Your Blue Gem")} {t("is just a Touch Away")}
                </Type>
                <RowS>
                  <ColS md={9} lg={4}>
                    <Type var="Font" sx={{ fs: 18, mb: 6 }}>
                      {" "}
                      {t("Ad-Free Experience")}{" "}
                    </Type>
                  </ColS>
                  <ColS md={9} lg={4}>
                    <Type var="Font" sx={{ fs: 18, mb: 6 }}>
                      {" "}
                      {t("1 Free Credit Every Day")}{" "}
                    </Type>
                  </ColS>
                </RowS>
                <RowS>
                  <ColS md={9} lg={4}>
                    <Type var="Font" sx={{ fs: 18, mb: 6 }}>
                      {" "}
                      {t("Fast & Long Readings")}
                    </Type>
                  </ColS>
                  <ColS md={9} lg={4}>
                    <Type var="Font" sx={{ fs: 18, mb: 6 }}>
                      {t("1 Free Reading Every Week")}
                    </Type>
                  </ColS>
                </RowS>
              </Div>
            </ColS>
          </RowS>
        </Div>
      </Div>

      <RowS>
        {listSubscriptionOffers?.map((offer, index) => (
          <ColS md={12} lg={4}>
            <SubscriptionCard key={index} offer={offer} />
          </ColS>
        ))}
      </RowS>
      <Div flexcenter sx={{ mt: 50 }}>
        <Type var="Font" sx={{ fs: 18, c: "gray10", mb: 20 }}>
          {t("Cancel Anytime. No Commitments.")}
        </Type>
      </Div>
      <Div flexcenter>
        <Link
          to="/UnderConstruction"
          style={{ color: "#4D2D6E", fontSize: "20px", marginBottom: 20 }}
        >
          {t("Restore Subscription")}
        </Link>
      </Div>
      <Div flexcenter>
        <Type var="Font" sx={{ fs: 18, c: "gray10", mb: 20 }}>
          {t("Terms and Conditions Applied")}
        </Type>
      </Div>
    </Container>
  );
};

export default Subscriptions;
