// *** React
import { useContext } from "react";
import { createContext } from "react";

// *** Firebase
import { initializeApp } from "firebase/app";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  // signInWithEmailAndPassword,
  // createUserWithEmailAndPassword,
  // sendPasswordResetEmail,
  // signOut,
  OAuthProvider,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

// *** Services
import { useAuth } from "../../services/auth";

const Context = createContext(null);
const firebaseConfig = {
  apiKey: "AIzaSyDCLukuomtO_ztNBaZjvGeS4FsoKMDdUL0",
  authDomain: "tobsir-register-login-7b378.firebaseapp.com",
  projectId: "tobsir-register-login-7b378",
  storageBucket: "tobsir-register-login-7b378.appspot.com",
  messagingSenderId: "617967117910",
  appId: "1:617967117910:web:943e4d698bd906117cbdf7",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
var myArray = null;
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
var userFirebaseName = null;
var userFirebaseEmail = null;
var userFirebaseUid = null;
var userFirebaseSocialId = null;
export function useFirebaseSection() {
  return useContext(Context);
}

export default function FirebaseSectionProvider({ children }) {
  const { registeration, signin } = useAuth();

  //** handle login after sign in with social media
  const handleLogin = (myArray) => {
    try {
      signin(myArray);
    } catch (error) {
      console.log(error, "error");
    }
  };

  //** handle Sign Up api after register with social media
  const handleSignUp = (myArray) => {
    try {
      registeration(myArray);
    } catch (error) {
      console.log(error, "error");
    }
  };

  //** Sign Up with Google
  const signInWithGoogle = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
          await addDoc(collection(db, "users"), {
            uid: user.uid,
            name: user.displayName,
            authProvider: "google",
            email: user.email,
          });
          myArray = {
            lang: null,
            firstName: user.displayName,
            genderID: null,
            maritalStatusID: null,
            email: user.email,
            password: null,
            socialMediaID: 1,
            socialMediaProfileID: user.uid,
          };
          handleSignUp(myArray);
          resolve();
        } else {
          console.log("This account is already registered");
          reject({ message: "This account is already registered" });
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  //** Sign Up with Facebook
  const signInWithFacebook = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await signInWithPopup(auth, facebookProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
          await addDoc(collection(db, "users"), {
            uid: user.uid,
            name: user.displayName,
            authProvider: "facebook",
            email: user.email,
          });
          myArray = {
            lang: null,
            firstName: user.displayName,
            genderID: null,
            maritalStatusID: null,
            email: user.email,
            password: null,
            socialMediaID: 2,
            socialMediaProfileID: user.uid,
          };
          handleSignUp(myArray);
          resolve();
        } else {
          console.log("This account is already registered");
          reject({ message: "This account is already registered" });
        }
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  };

  //** Sign Up with apple
  const signInWithApple = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await signInWithPopup(auth, appleProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
          await addDoc(collection(db, "users"), {
            uid: user.uid,
            name: user.displayName,
            authProvider: "apple",
            email: user.email,
          });
          myArray = {
            lang: null,
            firstName: user.displayName,
            genderID: null,
            maritalStatusID: null,
            email: user.email,
            password: null,
            socialMediaID: 3,
            socialMediaProfileID: user.uid,
          };
          handleSignUp(myArray);
          resolve();
        } else {
          reject({ message: "This account is already registered" });
        }
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  };

  //** Sign In with google
  const SignInGoogle = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(
          collection(db, "users"),
          where("uid", "==", user.uid),
          where("authProvider", "==", "google")
        );
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
          var evt111 = new CustomEvent("notRegistered");
          window.dispatchEvent(evt111);
          resolve();
        } else {
          myArray = {
            lang: null,
            email: user.email,
            password: null,
            socialMediaID: 1,
            socialMediaProfileID: user.uid,
          };
          handleLogin(myArray);
        }
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  };

  //** Sign In with facebook
  const SignInFacebook = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await signInWithPopup(auth, facebookProvider);
        const user = res.user;
        const q = query(
          collection(db, "users"),
          where("uid", "==", user.uid),
          where("authProvider", "==", "facebook")
        );
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
          var evt111 = new CustomEvent("notRegistered");
          window.dispatchEvent(evt111);
          resolve();
        } else {
          myArray = {
            lang: null,
            email: user.email,
            password: null,
            socialMediaID: 2,
            socialMediaProfileID: user.uid,
          };
          handleLogin(myArray);
        }
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  };

  //** Sign in with Apple
  const SignInApple = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await signInWithPopup(auth, appleProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
          var evt111 = new CustomEvent("notRegistered");
          window.dispatchEvent(evt111);
          resolve();
        } else {
          myArray = {
            lang: null,
            email: user.email,
            password: null,
            socialMediaID: 3,
            socialMediaProfileID: user.uid,
          };
          handleLogin(myArray);
        }
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  };
  let value = {
    auth,
    db,
    signInWithGoogle,
    signInWithFacebook,
    signInWithApple,
    SignInGoogle,
    SignInFacebook,
    SignInApple,
    userFirebaseName,
    userFirebaseEmail,
    userFirebaseUid,
    userFirebaseSocialId,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
}
