import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { useLocalization } from "../../services/LocalizationProvider";
import { error, success } from "../../utilities/ToastContainer";

const Context = createContext();

export function useFortellers() {
  return useContext(Context);
}

const initState = {
  listFortellers: [],
  listFortellerServices: [],
  reviews: [],
  FortellerNameServices: null,
  selected: null,
  endCallInfo: null,
  status: "idle",
  error: null,
  page: 1,
  perPage: 10,
  totalRecords: 0,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_listFortellers":
      return { ...state, listFortellers: [...action.payload] };
    case "set_listFortellerServices":
      return { ...state, listFortellerServices: [...action.payload] };
    case "set_FortellerNameServices":
      return { ...state, FortellerNameServices: action.payload };
    case "set_reviews":
      return { ...state, reviews: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_endCallInfo":
      return { ...state, endCallInfo: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_total_records":
      return { ...state, totalRecords: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function FortellerProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const {
    state: { lang },
  } = useLocalization();

  const fetchFortellersList = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
        pageSize: data?.pageSize || 10,
        pageNumber: data?.pageNumber || 1,
      };
      const resData = await req(
        `Employee/List`,
        null,
        { method: "GET" },
        false,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_total_records",
        payload: resData.data.totalRecords,
      });
      dispatch({
        type: "set_listFortellers",
        payload: resData.data.employees || [],
      });
    },
    [req, lang]
  );
  const fetchFortellerServicesList = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
        pageSize: data?.pageSize || 10,
        pageNumber: data?.pageNumber || 1,
      };
      const resData = await req(
        `Employee/Services/${data?.id}`,
        null,
        { method: "GET" },
        false,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_total_records",
        payload: resData.data?.services?.length,
      });
      dispatch({
        type: "set_FortellerNameServices",
        payload: resData.data?.employeeName || [],
      });
      dispatch({
        type: "set_listFortellerServices",
        payload: resData.data?.services || [],
      });
    },
    [req, lang]
  );

  const fetchFortellersByService = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
        pageSize: data?.perPage || 10,
        pageNumber: data?.page || 1,
      };
      const resData = await req(
        `Service/Employees/${data.serviceID}`,
        null,
        { method: "GET" },
        false,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_total_records",
        payload: resData.data.totalRecords,
      });
      dispatch({
        type: "set_listFortellers",
        payload: resData.data.employees || [],
      });
    },
    [req, lang]
  );
  const chooseForteller = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
      };
      const resData = await req(
        `Order/SetEmployee/${data?.orderID}`,
        { ...data },
        { method: "PUT" },
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
    },
    [req, lang]
  );

  const fetchReview = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
        pageSize: data?.perPage || 10,
        pageNumber: data?.page || 1,
      };
      const resData = await req(
        `Employee/Reviews/${data?.employeeID}`,
        null,
        { method: "GET" },
        false,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_reviews",
        payload: resData.data.reviews || [],
      });
    },
    [req, lang]
  );

  const addReply = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
      };
      try {
        await req(
          `Employee/SetReply/${data?.reviewID}`,
          { replyID: data?.replyID, comment: data?.comment },
          { method: "PUT" },
          true,
          headersData
        );
        dispatch({ type: "status", payload: `idle` });
        success("Reply Added Successfully");
      } catch (err) {
        if (err?.errors?.errors?.Rate) error(err?.errors?.errors?.Rate[0]);
        if (err?.errors?.errors?.Comment)
          error(err?.errors?.errors?.Comment[0]);
      }
    },
    [req, lang]
  );
  const deleteReply = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
      };
      try {
        await req(
          `Employee/DeleteReply/${data?.replyID}`,
          { ...data },
          { method: "DELETE" },
          true,
          headersData
        );
        dispatch({ type: "status", payload: `idle` });
        success("Reply Deleted Successfully");
      } catch (err) {
        error(err?.errors);
      }
    },
    [req, lang]
  );

  const addReview = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
      };
      try {
        await req(
          `Employee/SetReview/${data?.employeeID}`,
          { ...data },
          { method: "PUT" },
          true,
          headersData
        );
        dispatch({ type: "status", payload: `idle` });
        success("Review Added Successfully");
      } catch (err) {
        if (err?.errors?.errors?.Rate) error(err?.errors?.errors?.Rate[0]);
        if (err?.errors?.errors?.Comment)
          error(err?.errors?.errors?.Comment[0]);
      }
    },
    [req, lang]
  );
  const deleteReview = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
      };
      try {
        await req(
          `Employee/DeleteReview/${data?.reviewID}`,
          { ...data },
          { method: "DELETE" },
          true.valueOf,
          headersData
        );
        dispatch({ type: "status", payload: `idle` });
        success("Review Deleted Successfully");
      } catch (err) {
        error(err?.errors);
      }
    },
    [req, lang]
  );

  const reportBroadcast = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
        authenticationKey: "sak_tSk95or04htx8i76inlm==",
      };
      try {
        await req(
          `Broadcast/Report/${data?.broadcastID}`,
          {...data},
          { method: "PUT" },
          true,
          headersData
        );
        dispatch({ type: "status", payload: `idle` });
      } catch (err) {
        console.log(err);
      }
    },
    [req, lang]
  );

  return (
    <Context.Provider
      value={{
        state,
        fetchFortellersList,
        fetchFortellerServicesList,
        fetchFortellersByService,
        chooseForteller,
        fetchReview,
        addReply,
        deleteReply,
        addReview,
        deleteReview,
        reportBroadcast,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
}
