import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import styled from "styled-components";
import stylesBlocks, { sizes } from "../../ui/core/stylesBuilder";

export const IconFlex = styled.div`
  display: inline-flex;
  position: fixed;
  flex-direction: column;
  gap: 25px;
  padding: 20px;
  z-index: 1;
  @media (max-width: ${sizes.tablet}) {
    flex-direction: row;
    padding: 10px;
    margin: 0px;
    width: 100%;
    padding-top: 15px;
    border-bottom: solid 1px #ddd;
    background-color: transparent;
  }
  ${stylesBlocks}
`;

export const NavbarS = styled(Navbar)`
  margin: 0px !important;
  background-color: #ffffff;
  border-bottom: solid #e5e5e5 1px;
  // position: fixed;
  ${stylesBlocks}
`;

export const ContainerS = styled(Container)`
  ${stylesBlocks}
`;

export const NavS = styled(Nav)`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  ${stylesBlocks}
`;

export const LiS = styled.li`
  margin-left: 20px;
  margin-right: 20px;
  ${stylesBlocks}
`;

export const NavDropdownS = styled(NavDropdown)`
  ${stylesBlocks}
`;

export const ButtonS = styled.button`
  ${stylesBlocks}
`;
