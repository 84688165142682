import styled from "styled-components";
import { Div } from "../../../ui/Div";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
`;

function CardService({ section1, section2, section3, children, header }) {
  return (
    <Body>
      {header}
      {section1 && <Div>{section1}</Div>}
      {section2 && <Div>{section2}</Div>}
      {section3 && <Div>{section3}</Div>}
      {children}
    </Body>
  );
}

export default CardService;
