// *** React
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";

// *** Styled Components
import { Div } from "../../ui/Div";
import { Paginate } from "../../ui/Paginate";
import Header from "../../ui/Header";
import Card from "../../ui/Card";
import CardChoose from "./Styled Components/CardChoose";

// *** Providers
import { useFortellers } from "./provider";

function ChooseForteller() {
  const [t] = useTranslation();
  let params = useParams();
  const {
    state: { listFortellers, page, perPage, totalRecords },
    fetchFortellersByService,
    dispatch,
  } = useFortellers();

  useEffect(() => {
    fetchFortellersByService({
      pageSize: perPage,
      pageNumber: page,
      serviceID: params.serviceID,
    });
  }, [fetchFortellersByService, page, perPage]);

  const handlePaging = (event, value) => {
    window.scrollTo(0, 0);
    dispatch({ type: "set_page", payload: value });
  };

  return (
    <Div flexcenter sx={{ fxd: "column" }}>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        autoClose={2000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Div flexcenter sx={{ m: "30px" }}>
        <Header
          title={t("Select your Foreteller")}
          list={[
            { title: t("Home"), link: "/" },
            { title: t("Request Foreteller"), link: "/ChooseForteller" },
          ]}
        ></Header>
      </Div>
      <Div flexcenter sx={{ w: "75%", fxd: "column" }}>
        {listFortellers?.map((forteller, index) => {
          return (
            <Card
              key={index}
              shadow="0px 3px 6px #00000029"
              hovershadow="0px 3px 6px #00000029"
              sx={{ bgc: "gray0", w: ["100%", "auto"] }}
            >
              <CardChoose
                forteller={forteller}
                orderID={params?.orderID}
              ></CardChoose>
            </Card>
          );
        })}
      </Div>
      <Paginate
        count={Math.ceil(totalRecords / perPage)}
        shape="rounded"
        size="large"
        page={page}
        onChange={handlePaging}
        color="secondary"
      />
    </Div>
  );
}

export default ChooseForteller;
