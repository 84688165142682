import { useContext, useReducer, createContext } from "react";
// import { useRequest } from "./request";

const Context = createContext();

export function useLocalization() {
  return useContext(Context);
}

const initState = {
  lang: localStorage.getItem("tobsirLng")
    ? localStorage.getItem("tobsirLng")
    : "ar",
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_lang":
      return { ...state, lang: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function LocalizationProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  // const req = useRequest();
  return (
    <Context.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
}
