import { Route } from "react-router-dom";
import { RequireAuth } from "../../services/auth";
import History from "./History";
import HistoryList from "./HistoryList";
import SingleRead from "./SingleRead";

export default (
  <>
    <Route
      path="/History"
      exact
      element={
        <RequireAuth>
          <History />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <HistoryList />
          </RequireAuth>
        }
      />
      <Route exact path="SingleRead/:orderID" element={<SingleRead />} />
    </Route>
  </>
);
