const changeFields = [
  {
    name: "oldPassword",
    label: "Old Password",
    type: "password",
    placeholder: "Old Password",
  },
  {
    name: "newPassword",
    label: "New Password",
    type: "password",
    placeholder: "New Password",
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
    placeholder: "Confirm Password",
  },
];
export default changeFields;
