import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";
import { DayPicker } from "react-dropdown-date";

export const DayPickerS = styled(DayPicker).attrs((p) => ({
  value: p.value||"DD",
  required: true,
  classes: "picker",
  id: "day",
  name: "day",
}))`
  ${stylesBlocks}
`;
