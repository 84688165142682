import { Col } from "react-bootstrap";
import styled, { css } from "styled-components";
import stylesBlocks, { sizes } from "./core/stylesBuilder";

export const ColS = styled(Col).attrs((props) => ({
  cursor: props.cursor,
  direction: props.direction,
  $paddingLeft: props.$paddingLeft,
  $paddingRight: props.$paddingRigth,
}))`
  border: none;
  flex-direction: ${(props) => props.fxdirection};
  ${(p) =>
    p.flexcenter &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};
  @media (max-width: ${sizes.tablet}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${stylesBlocks}
`;
