// *** React
import { Route } from "react-router-dom";

// *** Services
import { RequireAuth } from "../../services/auth";

// *** Pages
import { Fortellers } from "./Fortellers";
import ListFortellers from "./ListFortellers";
import Call from "./Call";
import DetailsForteller from "./DetailsForteller";
import ChooseForteller from "./ChooseForteller";
import ListServices from "../Services/ListServices";

export default (
  <>
    <Route
      path="/FortellerPage"
      exact
      element={
        <RequireAuth>
          <Fortellers />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <ListFortellers />
          </RequireAuth>
        }
      />
      <Route path=":id" element={<DetailsForteller />} />
    </Route>
    <Route
      path="/ChooseForteller"
      exact
      element={
        <RequireAuth>
          <Fortellers />
        </RequireAuth>
      }
    >
      <Route path=":orderID/:serviceID" element={<ChooseForteller />} />
    </Route>
    <Route
      path="/empServices"
      exact
      element={
        <RequireAuth>
          <Fortellers />
        </RequireAuth>
      }
    >
      <Route path=":fortellerID" element={<ListServices />} />
    </Route>
    <Route
      path="/Call"
      element={
        <RequireAuth>
          <Call />
        </RequireAuth>
      }
    />
  </>
);
