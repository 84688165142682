// *** React
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Carousel from "react-elastic-carousel";

// *** Styled Components
import { Div } from "../../ui/Div";
import { Button } from "../../ui/Button";
import Card from "../../ui/Card";
import Type from "../../ui/Type";
import Image from "../../ui/Image";
import Icon from "../../ui/Icon";
import CardBody from "./Styled Components/CardBody";
import CustomSlider from "./Styled Components/CustomSlider";

// *** Providers & Utilities
import { useFortellers } from "../Fortellers/provider";
import { warning } from "../../utilities/ToastContainer";

// *** Icons & Images
import fortellerBg from "../../assets/fortellerBackground.svg";

function Team() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const {
    state: { listFortellers },
    fetchFortellersList,
  } = useFortellers();

  useEffect(() => {
    fetchFortellersList({
      pageSize: 0,
      pageNumber: 0,
    });
  }, [localStorage.getItem("tobsirLng"), fetchFortellersList]);

  const onMouseEnterLive = (id) => {
    document.getElementById(id).style.opacity = "1";
  };
  const onMouseLeaveLive = (id) => {
    document.getElementById(id).style.opacity = "0";
  };

  const checkValid = (forteller, type) => {
    if (!forteller?.online)
      warning(`${t("Sorry")} ${forteller?.employeeName} ${t("isOffline")}`);
    else if (!JSON.parse(localStorage.getItem("user"))) navigate("/LOGIN");
    else {
      navigate("/Call", {
        state: { forteller: forteller, callTypeID: type === "video" ? 2 : 1 },
      });
    }
  };

  return (
    <Div>
      <Div>
        <Card shadow="none" hovershadow="none">
          <CardBody
            ai="center"
            section1={t("ourTeam")}
            section2={t("teamHead")}
            children={
              <Button
                href="/FortellerPage"
                sx={{
                  p: 10,
                  w: "fit-content",
                  bgc: "primary",
                  c: "white",
                  br: 20,
                }}
              >
                <Type var="custom" sx={{ fs: 14 }}>
                  {t("View all Foretellers")}
                </Type>
              </Button>
            }
          ></CardBody>
        </Card>
        <Div>
          {/* <Carousel
            breakPoints={[
              { width: 1, itemsToShow: 1 },
              { width: 550, itemsToShow: 2 },
              { width: 768, itemsToShow: 3 },
              { width: 1200, itemsToShow: 3 },
            ]}
          >
            {items.map((item) => (
              <div key={item.id}>{item.title}</div>
            ))}
          </Carousel> */}

          <CustomSlider>
            {listFortellers?.map((forteller, idx) => {
              return (
                <Div key={idx} flexcenter sx={{ fxd: "column" }}>
                  <Div
                    relative
                    flexcenter
                    sx={{ gap: 40, fxd: "column" }}
                    onMouseEnter={() =>
                      onMouseEnterLive(`forteller${forteller.employeeID}`)
                    }
                    onMouseLeave={() =>
                      onMouseLeaveLive(`forteller${forteller.employeeID}`)
                    }
                  >
                    <Image src={fortellerBg} sx={{ sqr: 280 }} />
                    <Image
                      src={forteller.avatar}
                      abs={{ t: "auto", r: "auto" }}
                      sx={{ sqr: 220, mb: 160, br: "50%" }}
                    />
                    <Div
                      abs={{ t: "auto", r: "auto" }}
                      sx={{ mb: 180, op: 0, w: "100%" }}
                      id={`forteller${forteller.employeeID}`}
                      transition
                      flexcenter
                    >
                      <Icon
                        name="VideoCarusal"
                        w={50}
                        pointer
                        onClick={() => checkValid(forteller, "video")}
                      />
                      <Icon
                        name="AudioCarusal"
                        w={50}
                        pointer
                        onClick={() => checkValid(forteller, "audio")}
                      />
                      <Icon
                        name="ChatCarusal"
                        w={50}
                        pointer
                        onClick={() =>
                          navigate(`/empServices/${forteller?.employeeID}`)
                        }
                      />
                    </Div>
                    <Div
                      sx={{
                        mb: 50,
                        ta: "center",
                        gap: 10,
                        d: "flex",
                        fxd: "column",
                      }}
                    >
                      <Type var="h4">{forteller?.employeeName}</Type>
                      <Type var="custom" sx={{ fs: 20, c: "gray2" }}>
                        {forteller?.shortDescription}
                      </Type>
                    </Div>
                  </Div>
                </Div>
              );
            })}
          </CustomSlider>
        </Div>
      </Div>
    </Div>
  );
}

export default Team;
