import { Route } from "react-router-dom";
import { RequireAuth } from "../../services/auth";
import { Profile } from "./Profile";
import { EditProfile } from "./EditProfile";
import { ChangePassword } from "./ChangePassword";
import { ResetPassword } from "./ResetPassword";

export default (
  <>
    <Route
      path="/Profile"
      exact
      element={
        <RequireAuth>
          <Profile />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <EditProfile />
          </RequireAuth>
        }
      />
    </Route>
    <Route
      path="/ChangePassword"
      exact
      element={
        <RequireAuth>
          <Profile />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <ChangePassword />
          </RequireAuth>
        }
      />
    </Route>
    <Route
      path="/resetpassword/:key"
      exact
      element={
        <RequireAuth>
          <Profile />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <ResetPassword />
          </RequireAuth>
        }
      />
    </Route>
  </>
);
