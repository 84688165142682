// *** React
import React from "react";
import { useTranslation } from "react-i18next";
import StarRatings from "react-star-ratings";

// *** Styled Components
import styled from "styled-components";
import Image from "../../../ui/Image";
import { Div } from "../../../ui/Div";
import Type from "../../../ui/Type";

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  cursor: auto;
`;

function CardDetailBody({ forteller }) {
  const [t] = useTranslation();

  return (
    <GridGroup>
      <Image src={forteller?.avatar} sx={{ h: [250, 200], w: [280, 230] }} />
      <Div sx={{ d: "flex", jc: "space-between", ai: "center" }}>
        <Div>
          <Type var="custom" sx={{ fs: 14, fw: 500, c: "primary" }}>
            {forteller?.reviewCount} {t("Reviews")}
          </Type>
        </Div>
        <Div>
          <StarRatings
            rating={forteller?.rate}
            starRatedColor="#e5bd16"
            numberOfStars={5}
            name="rating"
            starDimension={"20px"}
            starSpacing="5px"
          />
        </Div>
      </Div>
    </GridGroup>
  );
}

export default CardDetailBody;
