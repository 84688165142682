import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

const Link = styled.p`
  color: #4d2d6e;
  text-decoration: underline;
  cursor: pointer;
  ${stylesBlocks};
`;

export default Link;
