import React from "react";
import ListSlider from "./historyStyled/ListSlider";
import { Div } from "../../ui/Div";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import Header from "../../ui/Header";
import { useTranslation } from "react-i18next";
import HistoryCard from "./historyStyled/HistoryCard";
import { useServices } from "../Services/provider";
import { useEffect } from "react";
import { useHistory } from "./provider";
import { Paginate } from "../../ui/Paginate";

const HistoryList = () => {
  let [t] = useTranslation();
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  const {
    state: {
      listServices,
      page: pService,
      perPage: perPService,
      totalRecords: PTotalService,
    },
    fetchServicesList,
    dispatch,
  } = useServices();
  const {
    state: { list, serviceID, page, perPage, totalRecords },
    fetchList,
    dispatch: dispatchOrder,
  } = useHistory();
  const handlePaging = (event, value) => {
    window.scrollTo(0, 0);
    dispatchOrder({ type: "set_page", payload: value });
  };
  useEffect(() => {
    fetchList({
      serviceID: serviceID,
      pageSize: perPage,
      pageNumber: page,
    });
  }, [fetchList, serviceID, page, perPage]);
  useEffect(() => {
    fetchServicesList();
  }, [fetchServicesList]);

  return (
    <Div flexcenter sx={{ fxd: "column" }}>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        autoClose={2000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Div flexcenter sx={{ m: "30px" }}>
        <Header
          title={t("History")}
          list={[
            { title: t("Home"), link: "/" },
            { title: t("History"), link: "/History" },
          ]}
        ></Header>
      </Div>
      <Div flexcenter>
        <ListSlider List={listServices} />{" "}
      </Div>

      <Div sx={{ mt: 42 }} flexcenter>
        <HistoryCard orders={list} />
      </Div>

      <Paginate
        count={Math.ceil(totalRecords / perPage)}
        shape="rounded"
        size="large"
        page={page}
        onChange={handlePaging}
        color="secondary"
      />
    </Div>
  );
};

export default HistoryList;
