// *** React
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// *** Icons & Images
import Counter1 from "../../assets/Counter1.svg";
import Counter2 from "../../assets/Counter2.svg";
import Counter3 from "../../assets/Counter3.svg";

// *** Styled Components
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import { Div } from "../../ui/Div";
import CounterCard from "../../ui/CounterCard";
import Type from "../../ui/Type";
import Image from "../../ui/Image";
import { useTheme } from "styled-components";
import { useWebClient } from "./provider";

function Counter() {
  let theme = useTheme();
  const [t] = useTranslation();
  const {
    state: { activeUsers, tobsirClients, totalReading },
    fetchStatistics,
  } = useWebClient();
  let cols = [
    {
      id: 1,
      title: "visitorCounter",
      counter: tobsirClients,
      image: Counter1,
      bgc: "background1",
    },
    {
      id: 2,
      title: "activeCounter",
      counter: activeUsers,
      image: Counter2,
      bgc: "primary",
    },
    {
      id: 3,
      title: "readingCounter",
      counter: totalReading,
      image: Counter3,
      bgc: "background1",
    },
  ];
  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  return (
    <Div sx={{ mt: 150, mb: 100, px: 150 }}>
      <RowS sx={{ pb: 50 }}>
        {cols.map((col) => {
          return (
            <ColS md={12} lg={4} key={col.id} flexcenter>
              <CounterCard sx={{ bgc: col.bgc }}>
                <Image src={col.image} sx={{ mx: 8, sqr: 60 }} />
                <Type var="h4">{col.counter}</Type>
                <Type var="custom" sx={{ fs: 12 }}>
                  {t(col.title)}
                </Type>
              </CounterCard>
            </ColS>
          );
        })}
      </RowS>
    </Div>
  );
}

export default Counter;
