import { Row } from "react-bootstrap";
import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

export const RowS = styled(Row).attrs((props) => ({
  width: props.width,
  height: props.height,
}))`
  display: ${(props) => props.display};
  justify-content: ${(props) => props.jt};
  align-items: ${(props) => props.ai};
  ${stylesBlocks}
`;
