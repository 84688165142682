import styled, { css } from "styled-components";
import { sizes } from "./core/stylesBuilder";

const Input = styled.input`
  background-color: ${(props) => props.theme.color.gray0};
  height: 55px;

  ${(props) => {
    if (props.size === "medium")
      return css`
        height: 38px;
        padding: 0 5px 0 20px;
      `;
    else if (props.size === "small")
      return css`
        height: 34px;
        padding: 0 5px 0 14px;
      `;
  }}
  ::placeholder {
    color: ${(p) => p.theme.color.gray1};
  }
  width: 100%;
  width: ${(p) => p.width};
  border: none;
  font-size: 16px;
  color: ${(p) => p.theme.color.gray1};
  transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  &:focus,
  &:active {
    outline: ${(p) => p.theme.color.secondary} 1px solid;
    background-color: ${(p) => p.theme.color.white};
  }
  &:read-only,
  &:disabled {
    outline: ${(p) => p.theme.color.gray1} 1px solid;
    color: ${(p) => p.theme.color.gray1};
    background-color: ${(p) => p.theme.color.gray1};
  }

  ${(props) =>
    props.bradiusNone
      ? css`
          border-radius: none;
        `
      : css`
          border-radius: 30px;
        `}
  ${(props) =>
    props.outlineNone
      ? css`
          outline: none;
        `
      : css`
          outline: solid 1px #e0e0e0;
        `}
  ${(props) =>
    props.paddingcustom
      ? css`
          padding: ${(props) => props.paddingcustom};
        `
      : css`
          padding: 6px 12px 6px 30px;
        `}
  ${(props) =>
    props.type === "textarea" &&
    css`
      height: 120px;
      padding: 26px;
      width: 100%;
    `};
  @media (max-width: ${sizes.laptop}) {
    width: ${(p) => p.widthres};
  }
`;

export default Input;
