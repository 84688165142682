// *** React
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// *** Styled Component
import styled from "styled-components";
import Type from "../../../ui/Type";
import Loading from "../../../ui/Loading";
import { Div } from "../../../ui/Div";
import { Button } from "../../../ui/Button";

// Stripe
import { loadStripe } from "@stripe/stripe-js";

// *** Providers
import { useStore } from "../provider";

const Card = styled.div`
  width: 400px;
  height: 632px;
  background-color: #ffffff;
  box-shadow: 0px 0px 40px #0001010f;
  padding: 20px 0;
  margin-top: 50px;
`;
const Circle = styled.div`
  width: 10rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 40px #0001010f;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Rectangle = styled.div`
  width: 100%;
  height: 91px;
  margin: 20px;
  background: #833dcc 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubscriptionCard = ({ offer }) => {
  let [t] = useTranslation();
  let navigate = useNavigate();
  const [User] = useState(JSON.parse(localStorage.getItem("user")));
  const [Loader, setLoader] = useState(false);

  const {
    state: { selected: Purchase },
    getPaymentPurchase,
  } = useStore();

  let StripePromise;
  let stripe;
  /**get stripe object */
  const getStripe = () => {
    if (!StripePromise) {
      StripePromise = loadStripe(
        "pk_test_51NrfaCIzyB3Y1CrAySbXO8dkGmNNiU01Drs3ZnXZ3yPF9VYMeMhiP5Hz2TDFPCnn0uQLTFJIznIuupeUmECWVVhm00wygNoJEr"
      );
    }
    return StripePromise;
  };

  const checkOutOptions = {
    lineItems: [
      {
        price: Purchase?.stripeAPIID,
        quantity: 1,
      },
    ],
    mode: "subscription",
    clientReferenceId: Purchase?.clientReferenceID,
    successUrl: `${window.location.origin}/success/${Purchase?.purchaseID}`,
    cancelUrl: `${window.location.origin}/cancel/${Purchase?.purchaseID}`,
  };
  /** successful  function after calling purchase api which redirect to payment page*/
  const handleSuccess = async () => {
    console.log(Purchase, "in success function");
    stripe = await getStripe();
    console.log(stripe, "stripe");
    console.log("object", checkOutOptions);

    const { error } = await stripe.redirectToCheckout(checkOutOptions);
    setLoader(false);
    console.log("Stripe Checkout error", error);
  };
  /** function calling when click on card */
  const redirectCheckOut = async (offer) => {
    try {
      setLoader(true);
      await getPaymentPurchase(offer?.offerID, true);
      console.log("Purchase after fetch purchase api in try section", Purchase);
    } catch (error) {
      console.log(error, "error from purchase api");
    }
  };
  /** put handleSuccess function in useEffect after access purchase to avoid timeout error */
  useEffect(() => {
    if (Purchase) {
      handleSuccess();
    }
  }, [Purchase]);
  if (!offer) return;
  return (
    <Card>
      <Div flexcenter>
        {Loader && <Loading />}
        <Circle>
          <Div>
            {" "}
            <Type var="Font" sx={{ c: "background1", fs: 40 }}>
              {offer.priceText}
            </Type>
            <Type var="Font" sx={{ op: "0.7", fs: 30, ml: 5 }}>
              {offer.currency}
            </Type>
          </Div>
        </Circle>
      </Div>
      <Div flexcenter sx={{ w: 400, ta: "center" }}>
        <Type var="Font" sx={{ op: "0.7", fs: 20, m: "20px" }}>
          {offer.description}
        </Type>
      </Div>
      <Div flexcenter>
        <Rectangle>
          <Type var="Font" sx={{ fs: 30, c: "white" }}>
            {offer.typeName}
          </Type>
        </Rectangle>
      </Div>
      <Div flexcenter>
        <Type var="Font" sx={{ fs: 20, m: "20px 0" }}>
          {offer.hint}
        </Type>
      </Div>
      <Div flexcenter>
        <Button
          sx={{
            bgc: "primary",
            c: "white",
            w: 320,
            h: 40,
            br: 25,
            d: "flex",
            jc: "center",
            ai: "center",
          }}
          onClick={() => (User ? redirectCheckOut(offer) : navigate("/LOGIN"))}
        >
          {t("Subscribe")}
        </Button>
      </Div>
      <Div flexcenter>
        <Type
          var="Font"
          sx={{ fs: 29, text: "uppercase", c: "primary", m: "20px" }}
        >
          {t("Save")} {offer.saved} %
        </Type>
      </Div>
    </Card>
  );
};

export default SubscriptionCard;
