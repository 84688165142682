import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { handleBody } from "../../utilities/functions";
import { useLocalization } from "../../services/LocalizationProvider";

const Context = createContext();

export function useProfile() {
  return useContext(Context);
}

const initState = {
  selected: null,
  status: "idle",
  pic: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "status":
      return { ...state, status: action.payload };
    case "set_pic":
      return {
        ...state,
        pic: {
          name: action.payload.data.fileName,
          url: action.payload.data.url,
        },
      };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function ProfileProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const {
    state: { lang },
  } = useLocalization();

  const fetchProfile = useCallback(async () => {
    dispatch({ type: "status", payload: "fetching" });
    let headersData = { lang: lang };
    const resData = await req(
      `Client/Profile`,
      null,
      { method: "GET" },
      true,
      headersData
    );
    dispatch({ type: "set_selected", payload: resData.data });
    localStorage.setItem("profile", JSON.stringify(resData.data));
    return resData;
  }, [req, lang]);

  const uploadImage = useCallback(
    async (data, id) => {
      dispatch({ type: "status", payload: "fetching" });
      var dataReq = new FormData();
      dataReq.append("RefID", data.RefID);
      dataReq.append("data", data.data);
      dataReq.append("Lang", lang);

      const resData = await req(
        `Upload/Image`,
        dataReq,
        { headers: {} },
        true,
        {}
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_pic",
        payload: { data: resData.data },
      });
    },
    [req, lang]
  );

  const updateProfile = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = { lang: lang };
      const resData = await req(
        `Client/UpdateProfile`,
        handleBody(data, true, lang, JSON.parse(localStorage.getItem("user"))),
        { method: "PUT" },
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      fetchProfile({ clientID: data.clientID });
      return resData;
    },
    [req, lang]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchProfile,
        uploadImage,
        updateProfile,
      }}
    >
      {children}
    </Context.Provider>
  );
}
