// *** React
import React from "react";
import { useTranslation } from "react-i18next";
import StarRatings from "react-star-ratings";
import { useNavigate } from "react-router-dom";

// *** Styled Components
import styled from "styled-components";
import Image from "../../../ui/Image";
import Type from "../../../ui/Type";
import Icon from "../../../ui/Icon";
import { Div } from "../../../ui/Div";
import { Button } from "../../../ui/Button";
import { sizes } from "../../../ui/core/stylesBuilder";

// *** providers and utilities
import { warning } from "../../../utilities/ToastContainer";

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  gap: 30px;
  row-gap: 0;
  cursor: auto;
  @media (max-width: ${sizes.laptop}) {
    grid-template-columns: 1fr;
  }
`;
const GridGroup2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  gap: 30px;
  row-gap: 20px;
  cursor: auto;
  @media (max-width: ${sizes.laptop}) {
    grid-template-columns: 1fr;
  }
`;

function CardBody({ forteller }) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const checkValid = (type) => {
    if (!forteller?.online)
      warning(`${t("Sorry")} ${forteller?.employeeName} ${t("isOffline")}`);
    else if (!JSON.parse(localStorage.getItem("user"))) navigate("/LOGIN");
    else {
      navigate("/Call", {
        state: { forteller: forteller, callTypeID: type === "video" ? 2 : 1 },
      });
    }
  };

  return (
    <GridGroup>
      <Image
        src={forteller?.avatar}
        widthpercentage={25}
        heightpercentage={25}
        sx={{ w: 280, h: 250 }}
      />
      <Div sx={{ gap: 7, d: "flex", fxd: "column", jc: "center" }}>
        <GridGroup2>
          <Type var="h3">{forteller?.employeeName}</Type>
          <StarRatings
            rating={forteller?.rate}
            starRatedColor="#e5bd16"
            numberOfStars={5}
            name="rating"
            starDimension={"25px"}
            starSpacing="5px"
          />
        </GridGroup2>
        {forteller?.online ? (
          <Type var="custom" sx={{ c: "green" }}>
            Online
          </Type>
        ) : (
          <Type var="custom" sx={{ c: "red" }}>
            Offline
          </Type>
        )}
        <Type var="custom" sx={{ fw: "light", fs: 24 }}>
          {forteller?.shortDescription}
        </Type>
        <Type var="custom" sx={{ fw: 500, fs: 16, c: "primary" }}>
          {forteller?.reviewCount} {t("Reviews")}
        </Type>
        <Type var="small">{forteller?.description.substr(0, 140)}...</Type>
        <GridGroup2>
          <Div dispay="flex" ai="center" jt="center">
            <Button
              sx={{
                py: 10,
                px: 20,
                w: ["fit-content", "", "auto"],
                bgc: "primary",
                c: "white",
                br: 20,
              }}
              onClick={() =>
                navigate(`${forteller?.employeeID}`, {
                  state: { forteller: forteller },
                })
              }
            >
              <Type var="custom" sx={{ fs: 14 }}>
                {t("Read more")}
              </Type>
            </Button>
          </Div>
          <Div flexcenter sx={{ gap: 15 }}>
            <Icon
              name="VideoCarusalColored"
              w={50}
              pointer
              onClick={() => checkValid("video")}
            />
            <Icon
              name="AudioCarusalColored"
              w={50}
              pointer
              onClick={() => checkValid("audio")}
            />
            <Icon
              name="ChatCarusalColored"
              w={50}
              pointer
              onClick={() => navigate(`/empServices/${forteller?.employeeID}`)}
            />
          </Div>
        </GridGroup2>
      </Div>
    </GridGroup>
  );
}

export default CardBody;
