// *** React
import React from "react";

// *** Styled Component
import styled from "styled-components";

// *** Assets
import BG from "../../../assets/BG.svg";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BG});
  background-size: cover;
  display: flex;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
  background-size: cover;
`;
const Container = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Container;
