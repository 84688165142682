import React from 'react'
import { Outlet } from 'react-router-dom';
import Layout from '../../layout/Layout';

export const Home = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}
