// *** React
import React from "react";
import { Outlet } from "react-router-dom";

// *** Layout
import Layout from "../../layout/Layout";

export const AboutUs = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
