// *** React
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootContainer } from 'react-agora-call';

// *** Styled Components
import { Div } from '../../ui/Div';
import { Button } from '../../ui/Button';
import Type from '../../ui/Type';

// *** React agora call style
import 'react-agora-call/src/index.css';

// *** Services
import { useLocalization } from '../../services/LocalizationProvider';

// *** Utilities and Provider
import { useFortellers } from './provider';

function Call(props) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: { lang },
  } = useLocalization();
  const { dispatch } = useFortellers();
  const { forteller, callTypeID } = location.state;
  const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const [callObj, setcallObj] = useState();
  const [replyCallStatus, setReplyCallStatus] = useState();
  const [errorCallMsg, setErrorCallMsg] = useState();
  const [returnedCallChannelObj, setReturnedCallChannelObj] = useState();
  const [errorCallChannel, setErrorCallChannel] = useState();
  const [endCallObj, setEndCallObj] = useState();
  // console.log(replyCallStatus, "replyCallStatus");

  useEffect(() => {
    if (replyCallStatus) {
      document.getElementById('callButtonYes').style.visibility = 'hidden';
      document.getElementById('callButtonNo').style.visibility = 'hidden';
    }
  }, [replyCallStatus]);

  useEffect(() => {
    if (endCallObj) {
      dispatch({
        type: 'set_endCallInfo',
        payload: endCallObj,
      });
      console.log(endCallObj, 'endCallObj');
      localStorage.setItem('balance', JSON.stringify(endCallObj.data.balance));
      window.location.pathname = '/';
      window.location.reload();
    }
  }, [endCallObj]);

  return (
    <Div flexcenter sx={{ sqr: '100%', fxd: 'column' }}>
      <Div flexcenter sx={{ w: '50%', gap: 40, fxd: 'column' }}>
        <Div flexcenter sx={{ fxd: 'column' }}>
          {replyCallStatus && replyCallStatus !== 'Call is going on..' ? (
            <Type var="custom" sx={{ fs: 25 }}>
              {replyCallStatus}
            </Type>
          ) : !replyCallStatus ? (
            <>
              <Div>
                {t('This call will cost you')}{' '}
                {callTypeID === 2
                  ? forteller?.videoPrice
                  : forteller?.voicePrice}{' '}
                {t('per minute.')}
              </Div>
              <Div>{t('Are you sure you want to proceed ?')}</Div>
            </>
          ) : (
            <>
              <Div>{t('Wait a second...')}</Div>
            </>
          )}
          {replyCallStatus &&
            replyCallStatus !== 'Calling...' &&
            replyCallStatus !== 'Call is going on..' && (
              <Button
                onClick={() => window.location.reload()}
                sx={{
                  py: 5,
                  px: 40,
                  bgc: 'primary',
                  c: 'white',
                  br: 20,
                }}
              >
                <Type var="custom" sx={{ fs: 14 }}>
                  {t('Refresh')}
                </Type>
              </Button>
            )}
        </Div>
        <Div sx={{ w: '100%', d: 'flex', jc: 'space-between' }}>
          <RootContainer
            token={user?.token}
            fetchHeaders={{
              authenticationKey: 'sak_tSk95or04htx8i76inlm==',
              lang: lang,
              clientID: user?.profile?.clientID,
              token: user?.token,
              employeeID: forteller?.employeeID,
              callTypeID: callTypeID,
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('tokens'))?.accessToken
              }`,
            }}
            fetchRtmURL={'https://clientapi.tobsir.com/api/Agora/RtmToken'}
            callURL={'https://clientapi.tobsir.com/api/Agora/Call'}
            callChannelURL={
              'https://clientapi.tobsir.com/api/Agora/CallChannel'
            }
            endURL={'https://clientapi.tobsir.com/api/Agora/EndCall'}
            setEndCallObj={setEndCallObj}
            navigateTo={'InCall'}
            clientID={user?.profile?.clientID}
            callerFirstName={user?.profile?.firstName}
            employeeID={forteller?.employeeID}
            callTypeID={callTypeID}
            setCallReturnedObj={setcallObj}
            setErrorCallMsg={setErrorCallMsg}
            setReplyCallStatus={setReplyCallStatus}
            setReturnedCallChannelObj={setReturnedCallChannelObj}
            setErrorCallChannel={setErrorCallChannel}
            UserPicture={user?.profile?.avatar}
            btnText={
              <Button
                sx={{
                  py: 5,
                  px: 40,
                  w: 'fit-content',
                  bgc: 'primary',
                  c: 'white',
                  br: 20,
                }}
                id="callButtonYes"
              >
                <Type var="custom" sx={{ fs: 14 }}>
                  {t('Yes')}
                </Type>
              </Button>
            }
          />

          <Button
            onClick={() => navigate(-1)}
            sx={{
              py: 5,
              px: 40,
              w: 100,
              bgc: 'primary',
              c: 'white',
              br: 20,
            }}
            id="callButtonNo"
          >
            <Type var="custom" sx={{ fs: 14 }}>
              {t('No')}
            </Type>
          </Button>
        </Div>
      </Div>
    </Div>
  );
}

export default Call;
