import { lazy, Suspense } from "react";
import styled, { css } from "styled-components";
import { firstUp } from "../utilities/functions";

const icons = {
  NewLogo: lazy(() => import("./icons/NewLogo")),
  Spirtual: lazy(() => import("./icons/Spiritual")),
  Facebook: lazy(() => import("./icons/Facebook")),
  Twitter: lazy(() => import("./icons/Twitter")),
  BillNotiNew: lazy(() => import("./icons/BillNotiNew")),
  Bell: lazy(() => import("./icons/Bell")),
  Gem: lazy(() => import("./icons/Gem")),
  GemBlue: lazy(() => import("./icons/GemBlue")),
  GemBuy: lazy(() => import("./icons/Gem-Buy")),
  Apple: lazy(() => import("./icons/Apple")),
  GooglePlay: lazy(() => import("./icons/GooglePlay")),
  Hero: lazy(() => import("./icons/Hero")),
  VideoCall: lazy(() => import("./icons/VideoCall")),
  Play: lazy(() => import("./icons/Play")),
  VideoCarusal: lazy(() => import("./icons/VideoCarusal")),
  VideoCarusalColored: lazy(() => import("./icons/VideoCarusalColored")),
  AudioCarusal: lazy(() => import("./icons/AudioCarusal")),
  AudioCarusalColored: lazy(() => import("./icons/AudioCarusalColored")),
  ChatCarusal: lazy(() => import("./icons/ChatCarusal")),
  ChatCarusalColored: lazy(() => import("./icons/ChatCarusalColored")),
  WalletGem: lazy(() => import("./icons/WalletGem")),
  Close: lazy(() => import("./icons/Close")),
  WithFacebook: lazy(() => import("./icons/WithFacebook")),
  WithGoogle: lazy(() => import("./icons/WithGoogle")),
  WithApple: lazy(() => import("./icons/WithApple")),
  Dot: lazy(() => import("./icons/Dot")),
  BlackDot: lazy(() => import("./icons/BlackDot")),
  Trash: lazy(() => import("./icons/Trash")),
  Pen: lazy(() => import("./icons/Pen")),
  PenStroke: lazy(() => import("./icons/PenStroke")),
  Pause: lazy(() => import("./icons/Pause")),
  Mic: lazy(() => import("./icons/Mic")),
  Stop: lazy(() => import("./icons/Stop")),
  Camera: lazy(() => import("./icons/Camera")),
};

const Wrap = styled.span`
  line-height: 0;
  transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);

  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
      width: calc(18px + ${(props) => props.w}px);
      height: calc(18px + ${(props) => (props.h !== 0 ? props.h : props.w)}px);
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:hover {
        fill: ${props.theme.color[props.hover]};
        background-color: ${(props) =>
          props.hoverBgc ? props.hoverBgc : props.theme.color.white};
        opacity: ${(props) => props.hoverOpacity && props.hoverOpacity};
      }
      &:active {
        background-color: ${props.theme.color.gray2k};
        transform: scale(0.99);
      }
    `}
  svg {
    fill: ${(props) => props.c && props.theme.color[props.c]};
    width: ${(props) => props.w}px;
    height: ${(props) => (props.h !== 0 ? props.h : props.w)}px;
    ${(p) =>
      p.pointer &&
      css`
        cursor: pointer;
      `};
  }
  ${(props) =>
    props.circle &&
    css`
      width: ${props.w + 22}px;
      height: ${(props.h !== 0 ? props.h : props.w) + 22}px;
      display: inline-block;
      border-radius: 200px;
      background-color: ${(props) => props.c && props.theme.color[props.c]};
      position: relative;
      svg {
        fill: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

function Icon({ name, w = "30", h, c = "dark", hover, ...rest }) {
  if (!icons[firstUp(name || "")]) return;
  const Component = icons[firstUp(name)];
  return (
    <Suspense fallback={"loading"}>
      <Wrap {...{ w, h, c, hover, ...rest }}>
        <Component />
      </Wrap>
    </Suspense>
  );
}

export default Icon;
