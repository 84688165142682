import { Box } from "@mui/material";
import React, { useState } from "react";
import { Div } from "./Div";
import styled from "styled-components";
const Tab = styled.button.attrs((p) => {
  p.brb = p.brb ? p.brb : "";
  p.fw = p.fw ? p.fw : "";
})`
  font-size: 25px;
  padding: 20px;
  border: none;
  background-color: transparent;
  border-bottom: ${(p) => p.brb};
  cursor: pointer;
  font-weight${(p) => p.fw} ;
  &:focus {
    font-weight: bold;
    border-bottom: #833dcc 6px solid;
  }
  @media (max-width: 720px) {
    width: 30px;
    font-size: 14px;
    padding: 2px;

  }

`;

const TabList = ({ tabs, selectedTab, setSelectedTab }) => {
  const [Clicked, setClicked] = useState(selectedTab);

  return (
    <>
      <Div sx={{ gap: 150, m: 30 }} flexcenter>
        {tabs.map((tab, index) => (
          <Tab
            fw={Clicked === tab.title ? "bold" : ""}
            brb={Clicked === tab.title ? "#833dcc 6px solid" : ""}
            onClick={() => {
              setSelectedTab(tab.title);
              setClicked(tab.title);
            }}
            key={index}
          >
            {tab.title}
          </Tab>
        ))}
      </Div>
    </>
  );
};

export default TabList;
