import React from "react";
import { Div } from "../../ui/Div";
import LogoThanks from "../../assets/thans-logo.svg";
import Image from "../../ui/Image";
import Type from "../../ui/Type";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import theme from "../../theme";
import { useStore } from "../GemStore/provider";
import { useEffect } from "react";

const Success = () => {
  let [t] = useTranslation();
  let navigate = useNavigate();
  const { id } = useParams();
  const { SetStatus } = useStore();

  useEffect(() => {
    try {
      SetStatus({ statusID: 1 }, id);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  return (
    <Div loginbg sx={{ bgc: "gray7", h: "100%" }} flexcenter>
      <Div
        sx={{
          gap: 10,
          d: "flex",
          jc: "center",
          ai: "center",
          fxd: "column",
        }}
      >
        <Image src={LogoThanks} sx={{ w: "20%", h: "20%" }} />

        <Div sx={{ ta: "center", mt: 20 }}>
          <Type var="Font" sx={{ c: "black2", fs: 30 }}>
            {t("Thank You!")}
          </Type>
        </Div>
        <Div sx={{ ta: "center", mt: 20 }}>
          <Type var="Font" sx={{ c: "black2", fs: 22 }}>
            {t("Your Payment has been Successfully done")}
          </Type>
        </Div>
        <Div sx={{ ta: "center", mt: 20 }}>
          <Button
            sx={{
              backgroundColor: theme.color.primary,
              color: theme.color.white,
              borderRadius: 25,
              textTransform: "capitalize",
              width: 350,
              height: 45,
              textAlign: "center",
              "&:hover": { backgroundColor: theme.color.primary },
            }}
            onClick={() => navigate("/")}
          >
            {t("Back to Homepage")}
          </Button>
        </Div>
      </Div>
    </Div>
  );
};

export default Success;
