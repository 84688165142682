import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { handleBody } from "../../utilities/functions";
import { useLocalization } from "../../services/LocalizationProvider";

const Context = createContext();

export function useStore() {
  return useContext(Context);
}

const initState = {
  list: [],
  listOffers: [],
  listSubscriptionOffers: [],
  listCollections: [],
  selected: null,
  balance: null,
  status: "idle",
  error: null,
  page: 1,
  perPage: 10,
  totalRecords: 0,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_listOffers":
      return { ...state, listOffers: [...action.payload] };
    case "set_listSubscriptionOffers":
      return { ...state, listSubscriptionOffers: [...action.payload] };
    case "set_listCollections":
      return { ...state, listCollections: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_balance":
      return { ...state, balance: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_total_records":
      return { ...state, totalRecords: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const {
    state: { lang },
  } = useLocalization();
  /**get offers payment+ */
  const fetchOffers = useCallback(
    async (data) => {
      let headersData = {
        lang: lang,
      };
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        `Gem/Offers`,
        null,
        { method: "GET" },
        false,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_total_records",
        payload: resData.data.totalRecords,
      });
      dispatch({
        type: "set_listOffers",
        payload: resData.data.offerList || [],
      });
    },
    [req, lang]
  );
  /**get collections */
  const fetchCollections = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
      };
      const resData = await req(
        `Gem/Collections`,
        null,
        { method: "GET" },
        false,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_total_records",
        payload: resData.data.totalRecords,
      });
      dispatch({
        type: "set_listCollections",
        payload: resData.data.collectionList || [],
      });
    },
    [req, lang]
  );
  /** get subscriptions */
  const fetchSubscriptionOffers = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
      };
      const resData = await req(
        `Subscription/Offers`,
        null,
        { method: "GET" },
        false,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_total_records",
        payload: resData.data.totalRecords,
      });
      dispatch({
        type: "set_listSubscriptionOffers",
        payload: resData.data.offerList || [],
      });
    },
    [req, lang]
  );
  /**get purchase Info */
  const getPaymentPurchase = useCallback(
    async (offerID, isSubscription) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
      };
      const resData = await req(
        `Purchase/Info/${offerID}, ${isSubscription}`,
        null,
        { method: "GET" },
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });

      dispatch({
        type: "set_selected",
        payload: resData.data,
      });
    },
    [req, lang]
  );
  const SetStatus = useCallback(
    async (data, purchaseID) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
      };
      const resData = await req(
        `Purchase/CheckOut/${purchaseID}`,
        { ...data },
        { method: "PUT" },
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      localStorage.setItem("balance", resData.data.balance);
      dispatch({ type: "set_balance", payload: resData.data });
    },
    [req, lang]
  );
  return (
    <Context.Provider
      value={{
        state,
        fetchOffers,
        fetchCollections,
        fetchSubscriptionOffers,
        getPaymentPurchase,
        SetStatus,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
}
