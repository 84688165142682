// *** React
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// *** Styled Components
import styled from "styled-components";
import Image from "../../../ui/Image";
import { Div } from "../../../ui/Div";
import Type from "../../../ui/Type";
import Icon from "../../../ui/Icon";
import { ReplyInput } from "./ReplyInput";

// *** providers and utilities
import { useFortellers } from "../provider";

// *** Sizes
import { sizes } from "../../../ui/core/stylesBuilder";

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr 1fr;
  row-gap: 0;
  cursor: auto;
`;

const GridReplyGroup = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 7fr 1fr;
  gap: 10px;
  row-gap: 0;
  cursor: auto;
  align-items: center;
  @media (max-width: ${sizes.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const GridIconsGroup = styled(Div)`
  display: flex;
  gap: 18px;
  opacity: 0;
`;

function CardCommentBody({
  reviewer,
  replying,
  setReplying,
  deleting,
  setDeleting,
  setCommentReview,
}) {
  const [t] = useTranslation();

  const navigate = useNavigate();
  const { addReply, deleteReply, deleteReview } = useFortellers();
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [replyInputShow, setReplyInputShow] = useState(false);
  const [comment, setComment] = useState(false);
  const [defaultReply, setDefaultReply] = useState("");
  const [myReply, setMyReply] = useState();

  const removeReview = async (reviewID) => {
    if (!user) navigate("/LOGIN");
    else {
      await deleteReview({
        clientID: user?.profile?.clientID,
        reviewID: reviewID,
      });
      // success("Review Deleted Successfully");
      setDeleting(!deleting);
    }
  };
  const removeReply = async (replyID) => {
    if (!user) navigate("/LOGIN");
    else {
      await deleteReply({
        clientID: user?.profile?.clientID,
        replyID: replyID,
      });
      setDeleting(!deleting);
    }
  };
  const sendReply = async () => {
    if (!user) navigate("/LOGIN");
    // myReply ? 'edit':'add'
    else {
      await addReply({
        // clientID: user?.profile?.clientID,
        reviewID: reviewer?.reviewID,
        replyID: myReply ? myReply?.replyID : 0,
        comment: comment,
      });
    }
    setReplyInputShow(!replyInputShow);
    setReplying(!replying);
  };
  const handleChange = (e) => {
    setComment(e.target.value);
  };
  const checkContain = () => {
    var flag = true;

    reviewer?.replies?.map((reply) => {
      if (reply?.clientID === user?.profile?.clientID) {
        flag = false;
        return false;
      }
    });
    return flag;
  };
  const onMouseEnterComment = (id) => {
    if (document.body.contains(document.getElementById(id)))
      document.getElementById(id).style.opacity = "1";
  };
  const onMouseLeaveComment = (id) => {
    if (document.body.contains(document.getElementById(id)))
      document.getElementById(id).style.opacity = "0";
  };

  return (
    <GridGroup
      onMouseEnter={() =>
        onMouseEnterComment(`icon-main-group${reviewer?.reviewID}`)
      }
      onMouseLeave={() =>
        onMouseLeaveComment(`icon-main-group${reviewer?.reviewID}`)
      }
    >
      <Div>
        <Image src={reviewer?.avatar} sx={{ br: 50, sqr: 80 }} />
      </Div>
      <Div sx={{ gap: 5, d: "flex", fxd: "column" }}>
        <Div>
          <Type var="h6">
            {reviewer?.clientID === user?.profile?.clientID
              ? t("You")
              : reviewer?.clientName}
          </Type>
        </Div>
        <Div
          sx={{ w: [, "100px"], pr: [0, 10] }}
          style={{ wordWrap: "break-word", wordBreak: "break-all" }}
        >
          <Type var="custom" sx={{ fs: 14, c: "gray2" }}>
            {reviewer?.comment}
          </Type>
        </Div>
        {reviewer?.replies?.length !== 0 && (
          <Div
            sx={{
              pt: [40, 40, 20],
              pr: [20, 20, 0],
              gap: 30,
              d: "flex",
              fxd: "column",
            }}
          >
            {reviewer?.replies?.map((reply, idx) => {
              return (
                <GridReplyGroup
                  key={idx}
                  onMouseEnter={() =>
                    onMouseEnterComment(`icon-group${reviewer?.reviewID}${idx}`)
                  }
                  onMouseLeave={() =>
                    onMouseLeaveComment(`icon-group${reviewer?.reviewID}${idx}`)
                  }
                >
                  <Div>
                    <Image src={reply?.avatar} sx={{ br: 50, sqr: 40 }} />
                  </Div>
                  <Div sx={{ gap: 5, d: "flex", fxd: "column" }}>
                    <Div>
                      <Type var="h6">
                        {reply?.clientID === user?.profile?.clientID
                          ? t("You")
                          : reply?.clientName}
                      </Type>
                    </Div>
                    <Div
                      sx={{ w: [, , , "150px"] }}
                      style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                    >
                      <Type
                        var="custom"
                        sx={{ fs: 14, c: "gray2", d: "flex" }}
                        style={{
                          textWrap: "balance",
                        }}
                      >
                        {reply?.comment}
                      </Type>
                    </Div>
                  </Div>
                  {reply?.clientID === user?.profile?.clientID && (
                    <GridIconsGroup
                      id={`icon-group${reviewer?.reviewID}${idx}`}
                      transition
                    >
                      <Div
                        onClick={() => {
                          setReplyInputShow(!replyInputShow);
                          setMyReply(reply);
                          setDefaultReply(reply?.comment);
                        }}
                      >
                        <Icon name="Pen" w={16} pointer />
                      </Div>
                      <Div onClick={() => removeReply(reply?.replyID)}>
                        <Icon name="Trash" w={15} pointer c="gray1" />
                      </Div>
                    </GridIconsGroup>
                  )}
                </GridReplyGroup>
              );
            })}
          </Div>
        )}
        {replyInputShow === true && (
          <Div sx={{ py: 40, px: 20 }}>
            <GridReplyGroup>
              <Div>
                <Image src={user?.profile?.avatar} sx={{ br: 50, sqr: 40 }} />
              </Div>
              <Div sx={{ gap: 5, d: "flex", fxd: "column" }}>
                <ReplyInput
                  placeholder={t("Your Reply")}
                  outlineNone
                  paddingcustom="0px 10px"
                  defaultValue={defaultReply}
                  onChange={handleChange}
                />
              </Div>
              <Div onClick={() => sendReply()} sx={{ cursor: "pointer" }}>
                <Type var="custom" sx={{ fw: "bold", text: "uppercase" }}>
                  {t("send")}
                </Type>
              </Div>
            </GridReplyGroup>
          </Div>
        )}
      </Div>
      {reviewer?.clientID !== user?.profile?.clientID && (
        <Div>
          {checkContain() && (
            <Div
              onClick={() => {
                setReplyInputShow(!replyInputShow);
                setDefaultReply("");
                setMyReply(null);
              }}
              sx={{ cursor: "pointer" }}
            >
              <Type var="custom" sx={{ fw: "bold", text: "uppercase" }}>
                {t("Reply")}
              </Type>
            </Div>
          )}
        </Div>
      )}
      {reviewer?.clientID === user?.profile?.clientID && (
        <GridIconsGroup id={`icon-main-group${reviewer?.reviewID}`} transition>
          <Div
            onClick={() => {
              setCommentReview(reviewer?.comment);
            }}
          >
            <Icon name="Pen" w={16} pointer />
          </Div>
          <Div onClick={() => removeReview(reviewer?.reviewID)}>
            <Icon name="Trash" w={15} pointer c="gray1" />
          </Div>
        </GridIconsGroup>
      )}
    </GridGroup>
  );
}

export default CardCommentBody;
