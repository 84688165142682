import { Div } from "./Div";
import { DayPickerS } from "./DayPicker";
import styled from "styled-components";
import { MonthPickerS } from "./MonthPicker";
import { YearPickerS } from "./YearPicker";
export const Wrapper = styled(Div)`
  .picker {
    height: 55.3px;
    width: 30%;
    text-align: left !important;
    padding-left: 30px;
    border: solid 1px #e0e0e0;
    border-radius: 100px;
    background-color: #fafafa;
  }
`;
function DatePicker({
  handleDay = () => {},
  handleMonth = () => {},
  handleYear = () => {},
  date,
}) {
  return (
    <Wrapper flexcenter sx={{ gap: 30 }}>
      <DayPickerS onChange={handleDay} value={date?.getDate()} />
      <MonthPickerS onChange={handleMonth} value={date?.getMonth()} />
      <YearPickerS onChange={handleYear} value={date?.getFullYear()} />
    </Wrapper>
  );
}

export default DatePicker;
