import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";
import { YearPicker } from "react-dropdown-date";

export const YearPickerS = styled(YearPicker).attrs((p) => ({
  value: p.value || "YYYY",
  required: true,
  classes: "picker",
}))`
  ${stylesBlocks}
`;
