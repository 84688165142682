import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

const StyledButton = styled.button`
  border-radius: 100%;
  border: none;
  cursor: pointer;
  color: white;
  padding: 13px;
  margin: 10px;
  background-color: #4d2d6e;

  ${stylesBlocks}
`;

function IconButton({ children, bgc,...props }) {
  return (
    <StyledButton bgc={bgc} {...props}>
      {children}
    </StyledButton>
  );
}
export default IconButton;
