import { ThemeProvider } from "styled-components";
import theme from "../theme";
import WebClientProvider from "../view/Home/provider";
import AuthProvider from "./auth";
import LocalizationProvider from "./LocalizationProvider";
import FortellerProvider from "../view/Fortellers/provider";
import ServicesProvider from "../view/Services/provider";
import StoreProvider from "../view/GemStore/provider";
import HistoryProvider from "../view/History/provider";
import FirebaseSectionProvider from "../view/Firebase/provider";
import ProfileProvider from "../view/Profile/provider";

export default function Providers({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider>
        <ProfileProvider>
          <HistoryProvider>
            <StoreProvider>
              <ServicesProvider>
                <FortellerProvider>
                  <WebClientProvider>
                    <AuthProvider>
                      <FirebaseSectionProvider>
                        {children}{" "}
                      </FirebaseSectionProvider>
                    </AuthProvider>
                  </WebClientProvider>
                </FortellerProvider>
              </ServicesProvider>
            </StoreProvider>
          </HistoryProvider>
        </ProfileProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
