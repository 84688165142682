import React from "react";
import styled from "styled-components";
import { Div } from "../../../ui/Div";
import Type from "../../../ui/Type";
import { RowS } from "../../../ui/RowS";
import { ColS } from "../../../ui/ColS";
import { useTranslation } from "react-i18next";
import { getColors, getDate1 } from "../../../utilities/functions";
import { Paginate } from "../../../ui/Paginate";
import { useHistory } from "../provider";
import { useNavigate } from "react-router-dom";
import { sizes } from "../../../ui/core/stylesBuilder";

const Card = styled.div`
  background-color: #fafafa;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #00000000;
  transition: width 0.5s ease-in-out;
  width: 800px;
  padding: 10px;
  margin-bottom: 20px;
  @media (max-width: ${sizes.tablet}) {
    width: 100%;
  }
  @media (max-width: ${sizes.mobile}) {
    width: 80%;
    margin: 20px auto;
  }
`;
const Dot = styled.div.attrs((p) => ({ color: p.color }))`
  width: 8px;
  height: 8px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: ${(p) => p.color};
  margin-top: 8px;
`;

const HistoryCard = ({ orders }) => {
  let [t] = useTranslation();
  let navigate = useNavigate();
  const { notifyRead } = useHistory();
  const handleNotify = async (notifyID) => {
    navigate(`/History/SingleRead/${notifyID}`);
    // try {
    //   await notifyRead(notifyID);
    //   navigate(`/History/SingleRead/${notifyID}`);
    // } catch (error) {
    //   console.log(error);
    // }
  };
  return (
    <Div>
      {orders?.map((order, index) => (
        <Card>
          <RowS>
            <ColS md={12} lg={9}>
              <Type
                var="Font"
                sx={{ fs: 19, c: "background1", cursor: "pointer" }}
                onClick={() => handleNotify(order?.orderID)}
              >
                {order?.serviceName}
              </Type>
            </ColS>
            <ColS md={12} lg={3}>
              <Type var="Font" sx={{ fs: 18, c: "primary" }}>
                {getDate1(order?.creationDate, t)}
              </Type>
            </ColS>
            <ColS md={12} lg={9}>
              <Type var="Font" sx={{ fs: 20, c: "black3", fw: "bold" }}>
                {t("By")} {order?.employeeName}
              </Type>
            </ColS>
            <ColS md={12} lg={3}>
              <Div sx={{ d: "flex" }}>
                <Dot color={getColors(order.statusID)} />
                <Type var="Font" sx={{ fs: 18, c: getColors(order.statusID) }}>
                  {order?.statusName}
                </Type>
              </Div>
            </ColS>
          </RowS>
        </Card>
      ))}
    </Div>
  );
};

export default HistoryCard;
