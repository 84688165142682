// *** React
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

// *** Components
import AddService from "../AddService";

// *** Fields
import tarotFields from "../Fields/tarot";

// *** Providers & Utilities
import { useServices } from "../provider";
import { success } from "../../../utilities/ToastContainer";
import { objByStr } from "../../../utilities/functions";

// YUP
import * as Yup from "yup";

// Schema
const schema = Yup.object().shape({
  firstName: Yup.string().required("Name is required"),
  motherName: Yup.string().required("Mother Name is required"),
  maritalStatusID: Yup.string().required("Marital Status is required"),
  message: Yup.string().required("Message is required"),
});

function TarotService({}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { fortellerID } = useParams();
  const {
    state: { audio, images },
    sendRequest,
  } = useServices();
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [day, setDay] = useState(
    new Date(objByStr(user, "profile.birthDate")).getDate()
  );
  const [month, setMonth] = useState(
    new Date(objByStr(user, "profile.birthDate")).getMonth()
  );
  const [year, setYear] = useState(
    new Date(objByStr(user, "profile.birthDate")).getFullYear()
  );
  // Errors State
  const [dateError, setDateError] = useState("");
  const [audioError, setAudioError] = useState("");

  const headerList = [
    { title: t("Home"), link: "/" },
    { title: t("Services"), link: "/Services" },
    { title: t("TAROT READING"), link: "/TAROT" },
  ];
  const VerifyValues = () => {
    // DATE
    if (!year && !month && !day) {
      setDateError("Date is required");
      return false;
    } else setDateError("");
    // AUDIO
    if (!audio) {
      setAudioError("Audio is required");
      return false;
    } else setAudioError("");

    return true;
  };
  const onSubmit = async (values) => {
    var bd = new Date(Number(month) + 1 + "/" + day + "/" + year),
      birthDateFormat =
        bd.getFullYear() +
        "-" +
        (bd.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        bd.getDate().toString().padStart(2, "0");
    if (!VerifyValues()) {
      window.scroll(0, 300);
      return false;
    } else {
      await sendRequest({
        clientID: user?.profile?.clientID,
        serviceID: 8,
        employeeID: fortellerID ? Number(fortellerID) : 0,
        voice: audio?.fileName,
        birthDate: birthDateFormat,
        genderID: Number(values.genderID),
        maritalStatusID: Number(values.maritalStatusID),
        firstName: values.firstName,
        message: values.message,
        birthPlace: values.placeOfBirth,
        motherName: values.motherName,
      }).then((res) => {
        if (res.response === 1)
          localStorage.setItem("balance", res.data.financial.balance);
        if (fortellerID) {
          success(t("Thank you, your request sent"));
          setTimeout(() => {
            navigate("/FortellerPage");
          }, 3000);
        } else navigate(`/ChooseForteller/${res?.data?.orderID}/${8}`);
      });
    }
  };

  return (
    <AddService
      heading={"TAROT READING"}
      headerList={headerList}
      fields={tarotFields}
      schema={schema}
      withRecord={true}
      onSubmit={onSubmit}
      day={day}
      setDay={setDay}
      month={month}
      setMonth={setMonth}
      year={year}
      setYear={setYear}
      dateError={dateError}
      audioError={audioError}
    />
  );
}

export default TarotService;
