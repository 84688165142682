// *** React
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FacebookShareButton } from "react-share";
import { useNavigate } from "react-router-dom";

// *** Styled Components
import { Div } from "../../ui/Div";
import Type from "../../ui/Type";
import Icon from "../../ui/Icon";
import Container from "./StyledGems/Container";
import CollectionCard from "./StyledGems/CollectionCard";
import styled from "styled-components";

// *** Provider
import { useStore } from "./provider";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    width: 50%;
  }
`;
const GridCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    width: 50%;
  }
`;
const CollectGems = () => {
  let [t] = useTranslation();
  let navigate = useNavigate();
  const [User] = useState(JSON.parse(localStorage.getItem("user")));

  const [reload, setReload] = useState(false);
  const {
    state: { listCollections },
    fetchCollections,
  } = useStore();
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: "951148178911838",
      autoLogAppEvents: true,
      xfbml: true,
      version: "v14.0",
    });
  };
  useEffect(() => {
    fetchCollections({
      pageSize: 100,
      pageNumber: 1,
    });
  }, [fetchCollections]);

  const getVideo = () => {
    return navigate("video");
  };
  /**Sharing Function */
  const shareToFAcebook = async () => {
    if (User) {
      await window.open(
        'http://www.facebook.com/sharer.php?s=100&p[title]=Fb Share&p[summary]=Facebook share popup&p[url]=javascript:fbShare("http://tobsir.com")&p[images][0]=""',
        "sharer",
        "toolbar=0,status=0,width=548,height=325"
      );
    } else {
      await navigate("/LOGIN");
    }
  };
  return (
    <Container>
      <Grid>
        <Div flexcenter>
          <Div>
            <Type var="heading" fs="14" c="#833dcc" sx={{ mb: 10 }}>
              {t("Gem Collection")}
            </Type>
            <Type var="heading" fs="35" sx={{ mb: 20 }}>
              {t("Gem Collection")}
            </Type>
            <Type var="heading" fs="14" op="0.6" c="#47225E" sx={{ mb: 10 }}>
              {t("Click on any of the following to earn your collection Gems")}
            </Type>
          </Div>
        </Div>
        <GridCard>
          <>
            {listCollections?.map((collection, index) => {
              return (
                <>
                  {index % 2 !== 0 ? (
                    <CollectionCard
                      mb="60"
                      onClick={
                        User
                          ? () => {
                              collection.typeID == 1
                                ? getVideo()
                                : collection.typeID == 4
                                ? shareToFAcebook()
                                : navigate("/UnderConstruction");
                            }
                          : () => {
                              navigate("/LOGIN");
                            }
                      }
                    >
                      <Div>
                        {/* {collection.image && (
                          <img src={collection.image} alt="collection" />
                        )} */}
                      </Div>

                      <Div sx={{ mt: 40 }}>
                        <Type var="heading" fs="24">
                          {collection.description}
                        </Type>
                      </Div>
                      <Div sx={{ d: "flex", mt: 20 }}>
                        <Div sx={{ mr: 10 }}>
                          <Icon name="Gem" w={20} />
                        </Div>
                        <Type var="heading" fs="14" fw="bold">
                          {collection.gemsText}
                        </Type>
                      </Div>
                    </CollectionCard>
                  ) : (
                    <CollectionCard
                      onClick={
                        User
                          ? () => {
                              collection.typeID == 1
                                ? getVideo()
                                : collection.typeID == 4
                                ? shareToFAcebook()
                                : navigate("/UnderConstruction");
                            }
                          : () => {
                              navigate("/LOGIN");
                            }
                      }
                      mt="60"
                    >
                      {User ? (
                        collection.typeID === 4 ? (
                          <FacebookShareButton
                            url={"https://tobsir.com"}
                            quote={"i love this site"}
                            description={"Tobsir"}
                            onClick={shareToFAcebook}
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              top: 0,
                              right: 0,
                              padding: 0,
                              color: "black",
                            }}
                          ></FacebookShareButton>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <Div>
                        {/* {collection.image && (
                          <img src={collection.image} alt="collection" />
                        )} */}
                      </Div>
                      <Div sx={{ mt: 40 }}>
                        <Type var="heading" fs="24">
                          {collection.description}
                        </Type>
                      </Div>
                      <Div sx={{ d: "flex", mt: 20 }}>
                        <Div sx={{ mr: 10 }}>
                          <Icon name="Gem" w={20} />
                        </Div>
                        <Type var="heading" fs="14" fw="bold">
                          {collection.gemsText}
                        </Type>
                      </Div>
                    </CollectionCard>
                  )}
                </>
              );
            })}
          </>
        </GridCard>
      </Grid>
    </Container>
  );
};

export default CollectGems;
