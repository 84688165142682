import { toast } from "react-toastify";

export function info(message) {
  toast(message);
}

export function warning(message) {
  toast.warning(message);
}

export function error(message) {
  toast.error(message);
}

export function success(message) {
  toast.success(message);
}

export function hidesocial() {
  document.getElementById("socialclass2").style.display = "none";
}

export function showsocial() {
  document.getElementById("socialclass2").style.display = "block";
}
