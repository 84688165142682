// *** React
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// *** Stripe
import { loadStripe } from "@stripe/stripe-js";

// *** Styled Components
import { Div } from "../../ui/Div";
import Icon from "../../ui/Icon";
import Type from "../../ui/Type";
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import Loading from "../../ui/Loading";
import Container from "./StyledGems/Container";
import GemsCard from "./StyledGems/GemsCard";

// *** Providers
import { useStore } from "./provider";

const BuyGems = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [User] = useState(JSON.parse(localStorage.getItem("user")));
  const [Loader, setLoader] = useState(false);
  let stripe;
  let StripePromise;
  const {
    state: { listOffers, selected: Purchase },
    fetchOffers,
    getPaymentPurchase,
    SetStatus,
  } = useStore();

  useEffect(() => {
    fetchOffers({
      pageSize: 100,
      pageNumber: 1,
    });
  }, [fetchOffers]);
  /**get stripe object */
  const getStripe = () => {
    if (!StripePromise) {
      StripePromise = loadStripe(
        "pk_test_51NrfaCIzyB3Y1CrAySbXO8dkGmNNiU01Drs3ZnXZ3yPF9VYMeMhiP5Hz2TDFPCnn0uQLTFJIznIuupeUmECWVVhm00wygNoJEr"
      );
    }
    return StripePromise;
  };

  const checkOutOptions = {
    lineItems: [
      {
        price: Purchase?.stripeAPIID,
        quantity: 1,
      },
    ],
    mode: "payment",
    clientReferenceId: Purchase?.clientReferenceID,
    successUrl: `${window.location.origin}/success/${Purchase?.purchaseID}`,
    cancelUrl: `${window.location.origin}/cancel/${Purchase?.purchaseID}`,
  };
  /**successful  function after calling purchase api which redirect to payment page*/
  const handleSuccess = async () => {
    console.log(Purchase, "in success function");
    stripe = await getStripe();
    console.log(stripe, "stripe");
    console.log("object", checkOutOptions);

    const { error } = await stripe.redirectToCheckout(checkOutOptions);
    setLoader(false);

    console.log("Stripe Checkout error", error);
  };
  /** function calling when click on card */
  const redirectCheckOut = async (offer) => {
    try {
      setLoader(true);
      await getPaymentPurchase(offer?.offerID, false);
      console.log("Purchase after fetch purchase api in try section", Purchase);
    } catch (error) {
      console.log(error, "error from purchase api");
    }
  };
  /** put handleSuccess function in useEffect after access purchase to avoid timeout error */
  useEffect(() => {
    if (Purchase) {
      handleSuccess();
    }
    console.log(Purchase);
  }, [Purchase]);

  return (
    <Container>
      <RowS flexcenter>
        {Loader && <Loading />}
        {listOffers?.map((offer, index) => (
          <ColS sm={12} md={6} lg={4}>
            <GemsCard
              onCllick={() =>
                User ? redirectCheckOut(offer) : navigate("/LOGIN")
              }
            >
              <Div sx={{ mb: 25 }}>
                <Icon name="GemBuy" w={90} />
              </Div>
              <Div sx={{ d: "flex", mt: 20 }}>
                <Div sx={{ mr: 10 }}>
                  <Icon name="Gem" w={20} />
                </Div>
                <Type var="heading" fs="14" fw="bold">
                  {offer?.quantityText}
                </Type>
              </Div>
              <Div sx={{ d: "flex" }}>
                <Type var="heading" fs="18" fw="bold" c="#4D2D6E">
                  {offer?.priceText}
                </Type>

                {offer?.saved ? (
                  <Div
                    sx={{
                      bgc: "primary",
                      br: 10,
                      p: 4,
                      w: 92,
                      h: 30,
                      c: "white",
                      ml: 10,
                      mr: 10,
                    }}
                    flexcenter
                  >
                    {t("Save")} {offer.saved} %
                  </Div>
                ) : (
                  ""
                )}
              </Div>
            </GemsCard>
          </ColS>
        ))}
      </RowS>
    </Container>
  );
};

export default BuyGems;
