import styled, { css } from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

const Body = styled.div`
  padding: 20px 30px;
  position: relative;
  border-radius: 6px;
  box-shadow: ${(p) =>
    p.shadow ? p.shadow : "0px 0px 10px 2px rgba(19, 40, 81, 0.1)"};
  margin: 20px 0px;
  &:hover {
    box-shadow: ${(p) =>
      p.hovershadow ? p.hovershadow : "0px 0px 30px 4px rgba(19, 40, 81, 0.1)"};
    cursor: ${(p) => p.hovershadow !== "none" && "pointer"};
  }
  ${stylesBlocks}
`;

function Card({ children, ...props }) {
  return (
    <>
      <Body {...props}>{children}</Body>
    </>
  );
}

export default Card;
