import { Button, Modal } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Div } from "./Div";
import Icon from "./Icon";

const ModalBox = ({ open, handleClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Div loginbg sx={{ bgc: "gray7", w: "80%", h: "90%" }}>
        <Div
          onClick={handleClose}
          sx={{ cursor: "pointer", pr: 20, d: "flex", jc: "flex-end" }}
        >
          <Icon name="Close" w={50} />
        </Div>

        {children}
      </Div>
    </Modal>
  );
};

export default ModalBox;
