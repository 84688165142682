import { useCallback, useState } from "react";
import { apiUrl as baseUrl } from "../config";
import { useLocalization } from "./LocalizationProvider";
import { useAuth } from "./auth";

export const useRequest = () => {
  // var data;
  const auth = useAuth();
  const [User, setUser] = useState(localStorage.getItem("user"));
  return useCallback((...params) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (params[3]) await checkToken();
        const res = await request(...params);

        if (res.status === 401) {
          if (User) {
            window.location.replace("/LOGIN");
            reject({ status: res.status, errors: "Unauthorized Access" });
          } else {
            window.location.replace("/");
            reject({ status: res.status, errors: "Unauthorized Access" });
          }
        } else if (res.status === 403) {
          window.location.replace("/no-access");
          reject({ status: res.status, errors: "Access Denied" });
        } else if (res.status === 404) {
          window.location.replace("/");
          reject({ status: res.status, errors: "NOT FOUND" });
        } else if (!res.ok) {
          const eData = await res.json();
          reject({ status: res.status, errors: eData });
        }
        const resData = await (res.status !== 204 ? res.json() : {});
        resolve(resData);
      } catch (error) {
        reject(error);
      }
    });
  }, []);
};

export default function request(
  url,
  data = null,
  options = {},
  withAuth = false,
  headers = {}
) {
  const defaultOptions = {
    method: data ? "POST" : "GET",
    headers: {
      "Content-Type": "application/json",
      authenticationKey: "sak_tSk95or04htx8i76inlm==",
      ...headers,
    },
    referrerPolicy: "no-referrer",
    redirect: "follow",
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin",
    ...options,
  };
  try {
    if (data)
      if (defaultOptions.headers["Content-Type"] === "application/json")
        defaultOptions.body = JSON.stringify(data);
      else defaultOptions.body = data;
    if (withAuth)
      defaultOptions.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem("tokens"))?.accessToken
      }`;
    return fetch(`${baseUrl}/${url}`, defaultOptions);
  } catch (error) {
    throw new Error(error);
  }
}
function checkToken() {
  return new Promise(async (resolve, reject) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));

    let exp = new Date(tokens?.exp);
    let period = Date.now() - 60000;
    if (exp <= period) {
      try {
        await refreshToken(tokens);

        resolve();
      } catch (error) {
        reject(error);
      }
    }
    resolve();
  });
}

async function refreshToken(tokens) {
  return new Promise(async (resolve, reject) => {
    try {
      let headersData = {
        lang: "en",
      };
      const res = await request(
        `Web/RefreshToken`,
        {
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
        },
        { method: "POST" },
        true,
        headersData
      );
      const { data } = await res.json();
      if (data?.accessToken)
        localStorage.setItem(
          "tokens",
          JSON.stringify({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
            IssuedAtTime: data.issuedAt,
            exp: data.expiration,
          })
        );
      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}
