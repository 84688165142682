const fields = [
  {
    name: "email",
    type: "email",
    label: "Email",
    placeholder: "Enter your Email",
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    placeholder: "Enter your Password",
  },
];
export default fields;
