// *** React
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// *** Styled Components
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import { Div } from "../../ui/Div";
import { Button } from "../../ui/Button";
import Type from "../../ui/Type";
import Image from "../../ui/Image";
import Card from "../../ui/Card";
import CardBody from "./Styled Components/CardBody";

// *** Icons and Images
import AboutImageEn from "../../assets/aboutImgEn.svg";
import AboutImageAr from "../../assets/aboutImgAr.svg";

// *** Providers
import { useServices } from "../Services/provider";

// *** Utilities
import { getServiceImage } from "../../utilities/functions";
import styled from "styled-components";
import Icon from "../../ui/Icon";
import { useNavigate } from "react-router-dom";

const Dot = styled.div`
  font-weight: bold;
  padding-bottom: 10px;
`;
const CoveredDot = styled.div`
  border: 2.7px #833dcc solid;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: #833dcc;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function About() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const {
    state: { listServices },
    fetchServicesList,
  } = useServices();

  useEffect(() => {
    fetchServicesList();
  }, [fetchServicesList, localStorage.getItem("tobsirLng")]);

  return (
    <Div flexcenter sx={{ mt: 150, mb: 100 }}>
      <Div sx={{ w: "75%" }}>
        <RowS sx={{ pb: 50 }}>
          <ColS md="12" lg="12" xl="6" className="d-none d-lg-block d-xl-block">
            <Div relative>
              <Div
                abs={
                  localStorage.getItem("tobsirLng") === "en"
                    ? { t: 130, r: 470 }
                    : { t: 130, l: 470 }
                }
                flexcenter
                sx={{ fxd: "column" }}
              >
                <Icon name="BlackDot"></Icon>
                <CoveredDot>
                  <Dot>.</Dot>
                </CoveredDot>
                <Icon name="BlackDot"></Icon>
              </Div>
              <img
                src={
                  localStorage.getItem("tobsirLng") === "en"
                    ? AboutImageEn
                    : AboutImageAr
                }
                alt="Hero"
                style={{ width: "100%", height: "100%" }}
              />
              <Div
                abs={{
                  t: 70,
                  l: localStorage.getItem("tobsirLng") === "en" ? 30 : 0,
                  r: localStorage.getItem("tobsirLng") === "en" ? 0 : 30,
                }}
                sx={{ c: "white", h: "65%", ofy: "scroll" }}
                onClick={() => navigate("/Services")}
              >
                {listServices?.map((service) => {
                  return (
                    <Div
                      key={service.serviceID}
                      sx={{ my: 10, cursor: "pointer" }}
                    >
                      <Image
                        src={getServiceImage(service.serviceID)}
                        sx={{ mx: 8, sqr: 50 }}
                      />
                      <Type var="custom" sx={{ fs: 16 }}>
                        {t(service.title)}
                      </Type>
                    </Div>
                  );
                })}
              </Div>
            </Div>
          </ColS>
          <ColS md="12" lg="12" xl="6">
            <Card shadow="none" hovershadow="none">
              <CardBody
                section1={t("ABOUTUS")}
                section2={t("aboutheader")}
                section3={t("aboutbody")}
                children={
                  <Button
                    href={"/aboutus"}
                    sx={{
                      py: 10,
                      px: 0,
                      w: 150,
                      bgc: "primary",
                      c: "white",
                      br: 20,
                    }}
                  >
                    <Type var="custom" sx={{ fs: 16 }}>
                      {t("Read More")}
                    </Type>
                  </Button>
                }
              ></CardBody>
            </Card>
          </ColS>
        </RowS>
      </Div>
    </Div>
  );
}

export default About;
