import months from "../data/months.json";
import timeZoneCityToCountry from "../data/tz-cities-to-countries.json";
import countries from "../data/countries.json";

// Images
import COFFEE from "../assets/coffee.png";
import TAROT from "../assets/tarot.png";
import SPIRITUAL from "../assets/spiritual.svg";
import SOULMATE from "../assets/soulamte.png";
import PALM from "../assets/palm.png";
import DREAM from "../assets/dream.png";
import FACE from "../assets/face.png";
import HOROSCOPE from "../assets/horoscope.png";

const firstUp = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

function toDateTime(dateString, options = { time: true, short: false }) {
  const objectDate = new Date(Number(dateString) * 1000);
  let day = objectDate.getDate();
  let month = objectDate.getMonth();
  let year = objectDate.getFullYear();

  const date = `${
    months[month][options.short ? "short" : "long"]
  } ${day}, ${year}`;

  if (!options?.time) return date;

  let hours = objectDate.getHours();
  let minutes = objectDate.getMinutes();

  let ampm = hours <= 12 ? "AM" : "PM";

  return `${date}, ${("0" + (hours % 12)).slice(-2)}:${("0" + minutes).slice(
    -2
  )} ${ampm}`;
}

function objByStr(o, s) {
  if (!s) return null;
  if (!o) return;
  s = s?.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s?.replace(/^\./, ""); // strip a leading dot
  var a = s?.split(".");
  for (var i = 0, n = a?.length; i < n; ++i) {
    if (!o) return;
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

function rmEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

function genYears(min = 1900, max = new Date().getFullYear()) {
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i.toString());
  }
  return years;
}

// transform js date to yyy-mm-dd without time
function transformDate(date, time = false) {
  if (time) {
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    return new Date(date.getTime() - tzoffset)
      .toISOString()
      .replace("T", " ")
      .replace(":00.000Z", "");
  }
  return date.toISOString().split("T")[0];
}

function subtractYears(numOfYears, date = new Date()) {
  const dateCopy = new Date(date.getTime());

  dateCopy.setFullYear(dateCopy.getFullYear() - numOfYears);

  return dateCopy;
}

function addDays(days, date = new Date()) {
  const dateCopy = new Date(date.getTime());
  dateCopy.setDate(dateCopy.getDate() + days);

  return dateCopy;
}

function addHours(hours, date = new Date()) {
  var dateCopy = new Date(date.getTime());
  dateCopy.setHours(dateCopy.getHours() + hours);

  return dateCopy;
}

const createThumb = (blobImage, w, h) => {
  return new Promise((resolve, reject) => {
    try {
      var reader = new FileReader();
      //File reader is for some reason asynchronous
      reader.onloadend = function () {
        var img = document.createElement("img");
        img.onload = async function (e) {
          resolve(await _resize(img, w, h, blobImage.type));
        };
        img.onerror = (e) => {
          console.log(e);
        };
        img.src = reader.result;
      };
      //This starts the conversion
      reader.readAsDataURL(blobImage);
    } catch (e) {
      console.error(e);
      reject(e.message);
    }
  });

  function _resize(img, w, h, type) {
    var width = img.width;
    var height = img.height;

    if (width < height) {
      height = height * (w / width);
      width = w;
    } else {
      width = width * (h / height);
      height = h;
    }

    var canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, type);
      } catch (e) {
        console.error(e);
        reject(e.message);
      }
    });
  }
};

function downloadFile(fileUrl, filename) {
  var link = document.createElement("a");
  link.setAttribute("href", fileUrl);
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  // delete link;
}

function currency(
  v,
  style = "currency",
  currency = "USD",
  intDigits = 2,
  fractionDigits = 2
) {
  const opt = {
    minimumIntegerDigits: intDigits,
    minimumFractionDigits: fractionDigits,
  };
  if (style) opt.style = style;
  if (currency) opt.currency = currency;
  return new Intl.NumberFormat("en-US", opt).format(v);
}

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

function dateDiff(pastDate, futurDate) {
  return dateFromNow(futurDate, pastDate);
}

// this function has been updated date difference
function dateFromNow(date, diffDate) {
  var now = diffDate ? new Date(diffDate).getTime() : new Date().getTime();

  // Find the distance between now an the count down date
  var distance = new Date(date).getTime() - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return [
    days.toString().length === 1 ? "0" + days : days,
    hours,
    minutes,
    seconds,
  ];
}

function getUserCountry() {
  // var userRegion;
  var userCity;
  var userCountry;
  var userTimeZone;
  var userRegion;

  if (Intl) {
    userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var tzArr = userTimeZone.split("/");
    userRegion = tzArr[0];
    userCity = tzArr[tzArr.length - 1];
    userCountry = timeZoneCityToCountry[userCity];
  }

  const country = countries.find(
    (c) => c.name.toLowerCase() === userCountry.toLowerCase()
  );

  return {
    ...country,
    city: userCity,
    region: userRegion,
    timezone: userTimeZone,
  };
}

function delay(callback, ms) {
  var timer = 0;
  return function () {
    var context = this,
      args = arguments;
    console.log(args);
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}

function enforceMinMax(e, setValue) {
  const el = e.target;
  if (el.value !== "") {
    if (parseInt(el.value) < parseInt(el.getAttribute("min"))) {
      el.value = el.getAttribute("min");
      setValue(Number(el.getAttribute("min")));
    }
    if (parseInt(el.value) > parseInt(el.getAttribute("max"))) {
      el.value = el.getAttribute("max");
      setValue(Number(el.getAttribute("max")));
    }
  }
}

function truncatWork(s = "", max) {
  if (!s) return;
  if (s.length <= max) return s;
  var trimmedString = s.substring(0, max);

  //re-trim if we are in the middle of a word
  return (
    trimmedString.substring(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    ) + "..."
  );
}

function getStatus(id) {
  switch (id) {
    case 0:
      return "Under Review";
    case 1:
      return "NEW";
    case 2:
      return "UPCOMING";
    case 3:
      return "OPEN";
    case 4:
      return "CLOSED";
    default:
      return "";
  }
}

function getInitials(name) {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  return (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();
}

function toThousand(n) {
  if (!n || isNaN(n)) return 0;
  if (n < 1000) return currency(n, null, null);
  return currency(n / 1000, null, null, 1, 0) + "K";
}
const copyToClipBoard = async (copyMe, setCopySuccess, CopySuccess) => {
  try {
    await navigator.clipboard.writeText(copyMe);
    setCopySuccess(copyMe);
    console.log(CopySuccess);
  } catch (err) {
    setCopySuccess("Failed to copy!");
  }
};
function calminStakingPeriod(sec) {
  if (sec) {
    let day = Math.floor(sec / (60 * 60 * 24));
    let hours = Math.floor((sec % (60 * 60 * 24)) / (60 * 60));
    let min = Math.floor(((sec % (60 * 60 * 24)) % (60 * 60)) / 60);
    return `(= ${day}d:${hours}h:${min}m)`;
  }
}
function tolocalDate(date) {
  var d = new Date(date);
  let day = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  let hour = d.getHours();
  let min = d.getMinutes();
  let sec = d.getSeconds();
  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  var localdate = year + "-" + month + "-" + day + "T" + hour + ":" + min;
  return localdate;
}
function calcHeight(v, limit) {
  var Height;
  if (v > limit?.good) {
    Height = "200px";
  } else if (v < limit?.low && v != 0) {
    Height = "50px";
  } else if (v == 0) {
    Height = "1px";
  } else if (v == limit.low) {
    Height = "75px";
  } else if (v == limit.good) {
    Height = "160px";
  } else if (v < limit?.good && v > limit?.low) {
    Height = "140px";
  }
  return Height;
}
function viewDate(d) {
  if (d) {
    let date = new Date(d);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let min = date.getMinutes();
    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;
    if (hours < 10) hours = "0" + hours;
    if (min < 10) min = "0" + min;
    return `${day}/${month}/${year} at ${hours}:${min}`;
  }
}
function toUtc(date) {
  let utc = new Date(date).toUTCString();
  return utc;
}
function getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
const getRandomNumbersArray = (num, min, max) => {
  if (num <= max - min + 1) {
    let rands = [];
    while (rands.length !== num) {
      let r = getRandomNumberBetween(min, max);
      if (!rands.includes(r)) {
        rands.push(r);
        // setRands(...rands, r);
      }
    }
    return rands;
  } else {
    console.log(
      "the number is too big in comparison with the minimum and maximum"
    );
    return 0;
  }
};
const getServiceImage = (id) => {
  switch (id) {
    case 1:
      return COFFEE;
    case 2:
      return DREAM;
    case 3:
      return FACE;
    case 4:
      return HOROSCOPE;
    case 5:
      return PALM;
    case 6:
      return SOULMATE;
    case 7:
      return SPIRITUAL;
    case 8:
      return TAROT;
    default:
      break;
  }
};

const getServiceForm = (id, fortellerID) => {
  switch (id) {
    case 1:
      return fortellerID ? `/COFFEE/${fortellerID}` : "/COFFEE";
    case 2:
      return fortellerID ? `/DREAM/${fortellerID}` : "/DREAM";
    case 3:
      return fortellerID ? `/FACE/${fortellerID}` : "/FACE";
    case 4:
      return fortellerID ? `/HOROSCOPE/${fortellerID}` : "/HOROSCOPE";
    case 5:
      return fortellerID ? `/PALM/${fortellerID}` : "/PALM";
    case 6:
      return fortellerID ? `/SOULMATE/${fortellerID}` : "/SOULMATE";
    case 7:
      return fortellerID ? `/SPIRTUAL/${fortellerID}` : "/SPIRTUAL";
    case 8:
      return fortellerID ? `/TAROT/${fortellerID}` : "/TAROT";
    default:
      break;
  }
};

const handleBody = (data, withAuth, lang, user) => {
  const body = { authenticationKey: "auth", lang: lang, ...data };
  if (withAuth && user) {
    body.token = user?.token;
  } else if (withAuth) return "/LOGIN";
  return body;
};
function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

const getDate1 = (d, t) => {
  var date = convertUTCDateToLocalDate(new Date(d));

  var dat = new Date(date);
  return (
    dat.getDate() +
    "/" +
    (dat.getMonth() + 1) +
    "/" +
    dat.getFullYear() +
    " " +
    t("at") +
    " " +
    dat.getHours() +
    ":" +
    dat.getMinutes() +
    ":" +
    dat.getSeconds()
  );
};
const getColors = (ID) => {
  var color;
  switch (ID) {
    case 1:
      color = "black";
      break;
    case 2:
      color = "blue";
      break;
    case 3:
      color = "red";
      break;
    case 4:
      color = "green";
      break;
    case 5:
      color = "orange";
      break;
    default:
      color = "black";
      break;
  }
  return color;
};
export {
  firstUp,
  toDateTime,
  objByStr,
  rmEmpty,
  genYears,
  createThumb,
  downloadFile,
  currency,
  transformDate,
  subtractYears,
  addDays,
  addHours,
  debounce,
  getUserCountry,
  dateFromNow,
  delay,
  enforceMinMax,
  truncatWork,
  getStatus,
  getInitials,
  dateDiff,
  toThousand,
  copyToClipBoard,
  calminStakingPeriod,
  viewDate,
  tolocalDate,
  toUtc,
  calcHeight,
  getRandomNumberBetween,
  getRandomNumbersArray,
  getServiceImage,
  getServiceForm,
  handleBody,
  getDate1,
  getColors,
};
