// *** React
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// *** Styled Components
import { Div } from "../../ui/Div";
import { Paginate } from "../../ui/Paginate";
import Header from "../../ui/Header";
import Card from "../../ui/Card";
import CardBody from "./Styled Components/CardBody";

// *** Providers
import { useServices } from "./provider";
import { useFortellers } from "../Fortellers/provider";
import { useParams } from "react-router-dom";

function ListServices() {
  const [t] = useTranslation();
  const { fortellerID } = useParams();
  const {
    state: { listServices, page, perPage: servicesPerPage, totalRecords },
    fetchServicesList,
    dispatch,
  } = useServices();
  const {
    state: {
      listFortellerServices,
      FortellerNameServices,
      totalRecords: totalServicesforForteller,
      perPage: fortellersPerPage,
    },
    fetchFortellerServicesList,
  } = useFortellers();
  const [services, setServices] = useState();
  const [total, setTotal] = useState();
  const [perPage, setPerPage] = useState();

  useEffect(() => {
    if (fortellerID) {
      fetchFortellerServicesList({ id: fortellerID });
    } else {
      fetchServicesList({
        pageSize: 10,
        pageNumber: 0,
      });
    }
  }, [fetchServicesList, fetchFortellerServicesList, fortellerID]);
  useEffect(() => {
    if (fortellerID) {
      setServices(listFortellerServices);
      setTotal(totalServicesforForteller);
      setPerPage(fortellersPerPage);
    } else {
      setServices(listServices);
      setTotal(totalRecords);
      setPerPage(servicesPerPage);
    }
  }, [
    listServices,
    listFortellerServices,
    fortellerID,
    setServices,
    totalServicesforForteller,
    fortellersPerPage,
    totalRecords,
    servicesPerPage,
  ]);

  const handlePaging = (event, value) => {
    console.log(value, "pag");
    window.scrollTo(0, 0);
    dispatch({ type: "set_page", payload: value });
  };

  return (
    <Div flexcenter sx={{ fxd: "column" }}>
      <Div flexcenter sx={{ m: 30 }}>
        <Header
          title={
            fortellerID ? (
              <h2 dir="ltr">
                ({FortellerNameServices}) {t("services")}
              </h2>
            ) : (
              t("Services")
            )
          }
          list={[
            { title: t("Home"), link: "/" },
            {
              title: t("Services"),
              link: fortellerID ? `/empServices/${fortellerID}` : "/Services",
            },
          ]}
        ></Header>
      </Div>
      <Div flexcenter sx={{ w: "75%", gap: 10, fxd: "column" }}>
        {services?.map((service, index) => {
          return (
            <Card
              key={index}
              shadow="0px 3px 6px #00000029"
              hovershadow="0px 3px 6px #00000029"
              sx={{ bgc: "gray0" }}
            >
              <CardBody service={service} fortellerID={fortellerID}></CardBody>
            </Card>
          );
        })}
      </Div>
      <Paginate
        count={Math.ceil(total / perPage)}
        shape="rounded"
        size="large"
        page={page}
        onChange={handlePaging}
      />
    </Div>
  );
}

export default ListServices;
