// *** React
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// *** Styled Components
import { Div } from "../../ui/Div";
import Header from "../../ui/Header";
import { FormProfile } from "./FormProfile";

// *** Services and Providers
import { useProfile } from "./provider";

// *** Fields
import resetFields from "./resetFields";

// *** YUP
import * as Yup from "yup";
import { FormChangePass } from "./FormChangePass";

// Schema
const schema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Required")
    .min(6, "Password must be at least 6 characters long"),
  confirmPassword: Yup.string()
    .required("Required")
    .min(6, "Password must be at least 6 characters long"),
});

export const ResetPassword = () => {
  const [t] = useTranslation();

  return (
    <Div sx={{ m: 30 }}>
      <Header
        title={t("Reset Password")}
        list={[
          { title: t("Edit Profile"), link: "/Profile" },
          { title: t("Reset Password"), link: "/resetpassword" },
        ]}
      ></Header>
      <FormChangePass fields={resetFields} schema={schema} />
    </Div>
  );
};
