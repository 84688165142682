// *** React
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// *** Styled Components
import styled from "styled-components";
import Image from "../../../ui/Image";
import Type from "../../../ui/Type";
import { Div } from "../../../ui/Div";
import { sizes } from "../../../ui/core/stylesBuilder";

// *** Providers and Utilities
import { getServiceForm, getServiceImage } from "../../../utilities/functions";

// *** Icons and Images
import RequestImage from "../../../assets/request icon.svg";

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 8fr 2fr;
  gap: 40px;
  row-gap: 0;
  cursor: auto;
  @media (max-width: ${sizes.laptop}) {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

function CardBody({ service, fortellerID }) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const hanleNavigate = () => {
    if (fortellerID) navigate(getServiceForm(service?.serviceID, fortellerID));
    else navigate(getServiceForm(service?.serviceID));
  };

  return (
    <GridGroup>
      <Image src={getServiceImage(service?.serviceID)} sx={{ sqr: 120 }} />
      <Div sx={{ py: 10, gap: 30, d: "flex", fxd: "column", jc: "center" }}>
        <Type var="h3">{service?.title}</Type>

        <Type var="small" fw={"light"}>
          {service?.description}
        </Type>
      </Div>
      <Div
        flexcenter
        onClick={hanleNavigate}
        sx={{ cursor: "pointer", fxd: "column" }}
      >
        <Image src={RequestImage} sx={{ w: 70 }}></Image>
        <Type var="custom" sx={{ fs: 16 }} fs={16}>
          {t("chat")}
        </Type>
      </Div>
    </GridGroup>
  );
}

export default CardBody;
