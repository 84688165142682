// *** React
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState } from "react";

// *** Styled Components
import styled from "styled-components";
import { sizes } from "../../ui/core/stylesBuilder";
import { Div } from "../../ui/Div";
import { CheckS } from "../../ui/CheckS";
import { SelectS } from "../../ui/SelectS";
import { Button } from "../../ui/Button";
import Label from "../../ui/Label";
import DatePicker from "../../ui/DatePicker";
import InputText from "../../ui/InputText";
import Type from "../../ui/Type";
import UploadPic from "./Styled Components/UploadPic";

// *** YUP
import { yupResolver } from "@hookform/resolvers/yup";

// *** Toast
import { success, warning } from "../../utilities/ToastContainer";

// *** Services and Providers
import { useProfile } from "./provider";
import { useLocalization } from "../../services/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import { objByStr } from "../../utilities/functions";
import Link from "../../ui/Link";

const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;

  @media (max-width: ${sizes.tablet}) {
    grid-template-columns: 1fr;
    gap: 0px;
    row-gap: 10px;
    width: 100%;
  }
`;
const MobGridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 40px;
  row-gap: 0;
  @media (max-width: ${sizes.tablet}) {
    grid-template-columns: 1fr;
    gap: 0px;
    row-gap: 10px;
  }
`;
const PhotoGrid = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 0.25fr 1fr;
  row-gap: 0;
  @media (max-width: ${sizes.tablet}) {
    grid-template-columns: 1fr;
    gap: 0px;
    row-gap: 6px;
  }
`;
const GridCheckList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 80px;
  padding: 50px 10px 0px 10px;
  @media (max-width: ${sizes.laptop}) {
    grid-template-columns: 1fr;
    gap: 0px;
    row-gap: 10px;
    padding: 20px 10px 0px 10px;
  }
`;

// Replace user with selected

export const FormProfile = ({ fields, schema }) => {
  const [t] = useTranslation();
  const {
    state: { lang },
  } = useLocalization();
  const {
    state: { pic },
    updateProfile,
    dispatch,
  } = useProfile();
  const navigate = useNavigate();
  // Use State
  const [error, setError] = useState(null);
  const [profile] = useState(JSON.parse(localStorage.getItem("profile")));
  const [day, setDay] = useState(new Date(profile?.birthDate).getDate());
  const [month, setMonth] = useState(new Date(profile?.birthDate).getMonth());
  const [year, setYear] = useState(new Date(profile?.birthDate).getFullYear());
  const [defaultDate, setDefaultDate] = useState(new Date(profile?.birthDate));
  const [dateError, setDateError] = useState("");
  const [defaultPhoneNumber, setdefaultPhoneNumber] = useState("");

  // Use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch({
      type: "set_pic",
      payload: {
        data: { name: profile?.avatar, url: profile?.avatar },
      },
    });
  }, [profile]);
  useEffect(() => {
    setdefaultPhoneNumber(
      objByStr(JSON.parse(localStorage.getItem("profile")), "mobile")?.slice(
        objByStr(JSON.parse(localStorage.getItem("profile")), "countryCode")
          ?.length
      )
    );
  }, [setdefaultPhoneNumber, localStorage.getItem("profile")]);
  useEffect(() => {
    setDay(new Date(profile?.birthDate).getDate());
    setMonth(new Date(profile?.birthDate).getMonth());
    setYear(new Date(profile?.birthDate).getFullYear());
  }, [profile]);
  useEffect(() => {
    var d = new Date(year, month, day);
    setDefaultDate(d);
  }, [day, month, year]);

  function isValidDate(year, month, day) {
    var d = new Date(year, month, day);
    return (
      Number(d.getFullYear()) === Number(year) &&
      Number(d.getMonth()) === Number(month) &&
      Number(d.getDate()) === Number(day)
    );
  }
  const checkday = (day) => {
    if (isValidDate(year, month, day)) setDay(day);
    else warning(t("invalid date"));
  };

  const checkmonth = (month) => {
    if (isValidDate(year, month, day)) setMonth(month);
    else warning(t("invalid date"));
  };

  const checkyear = (year) => {
    if (isValidDate(year, month, day)) setYear(year);
    else warning(t("invalid date"));
  };
  const VerifyValues = () => {
    // DATE
    if (!year && !month && !day) {
      setDateError("Date is required");
      return false;
    } else setDateError("");
    return true;
  };
  const onSubmit = async (values) => {
    var bd = new Date(Number(month) + 1 + "/" + day + "/" + year),
      birthDateFormat =
        bd.getFullYear() +
        "-" +
        (bd.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        bd.getDate().toString().padStart(2, "0");
    if (!VerifyValues()) {
      window.scroll(0, 300);
      return false;
    } else {
      await updateProfile({
        clientID: profile?.clientID,
        birthDate: birthDateFormat,
        genderID: Number(values.genderID),
        maritalStatusID: Number(values.maritalStatusID),
        firstName: values.firstName,
        birthPlace: values.placeOfBirth,
        countryCode: values.countryCode,
        mobile: values.mobile,
        email: values.email,
        birthPlace: values.birthPlace,
        avatar: pic.name,
      }).then((res) => {
        if (res.response === 1) success("Profile updated successfully");
        // navigate(`/ChooseForteller/${res?.data?.orderID}/${4}`);
      });
    }
  };

  if (profile)
    return (
      <Div sx={{ px: [80, 0, 0] }} as="form" onSubmit={handleSubmit(onSubmit)}>
        <PhotoGrid>
          <UploadPic id={0} />
          <Div sx={{ pt: 50, d: "flex", fxd: "column", gap: 20 }}>
            <Type var="h3">{profile?.firstName}</Type>
            <Type var="custom">{profile?.birthPlace}</Type>
          </Div>
        </PhotoGrid>
        <GridGroup>
          {error && error}
          {fields?.map((field, index) => {
            return field?.type === "select" ? (
              <Div>
                <Label>{t(field.label)}</Label>
                <SelectS
                  key={index}
                  dir={
                    localStorage.getItem("tobsirLng") === "en" ? "ltr" : "ltr"
                  }
                  {...register(field.name)}
                  defaultValue={objByStr(profile, field?.defaultValue)}
                >
                  <option disabled>Open this select menu</option>
                  {field?.list?.map((li, ind) => (
                    <option value={li?.id} key={ind}>
                      {t(li?.value)}
                    </option>
                  ))}
                </SelectS>
              </Div>
            ) : field?.type === "radio" ? (
              <Div>
                <GridCheckList>
                  {field?.list?.map((li, ind) => (
                    <CheckS
                      type={field?.type}
                      name={li?.name}
                      id={li?.id}
                      key={ind}
                      value={li?.id}
                      label={t(li?.value)}
                      defaultChecked={
                        objByStr(profile, field?.defaultValue) === li?.id
                      }
                      {...register(field.name)}
                    />
                  ))}
                </GridCheckList>
                <Type var="custom" sx={{ c: "red", fs: 14 }}>
                  {errors[field?.name]?.message}
                </Type>
              </Div>
            ) : field?.type === "date" ? (
              <Div>
                <Label>{t(field.label)}</Label>
                <DatePicker
                  handleDay={checkday}
                  handleMonth={checkmonth}
                  handleYear={checkyear}
                  date={defaultDate}
                />
                <Type var="custom" sx={{ c: "red", fs: 14 }}>
                  {dateError}
                </Type>
              </Div>
            ) : field?.type === "codeAndMoblie" ? (
              <MobGridGroup key={index}>
                <Div>
                  <Label>{t(field.label[0])}</Label>
                  <SelectS
                    {...register(field.names[0])}
                    dir={
                      localStorage.getItem("tobsirLng") === "en" ? "ltr" : "ltr"
                    }
                    defaultValue={objByStr(
                      JSON.parse(localStorage.getItem("profile")),
                      field?.defaultValues[0]
                    )}
                  >
                    {field?.list?.map((li, ind) => (
                      <option value={li?.value} key={ind}>
                        {li?.value}
                      </option>
                    ))}
                  </SelectS>
                </Div>
                <InputText
                  label={t(field.label[1])}
                  type={t(field.types[1])}
                  error={!!errors?.[field.names[1]]}
                  msg={errors[field.names[1]]?.message}
                  widthres="auto"
                  inputProps={{
                    ...register(field.names[1]),
                    defaultValue: defaultPhoneNumber,
                  }}
                />
              </MobGridGroup>
            ) : (
              <InputText
                style={{
                  gridColumn: field?.gridColumn,
                  marginTop: field?.gridColumn && 50,
                }}
                key={index}
                type={field.type}
                label={t(field.label)}
                placeholder={t(field.placeholder)}
                error={!!errors?.[field.name]}
                msg={errors[field.name]?.message}
                widthres="auto"
                inputProps={{
                  ...register(field.name),
                  defaultValue: objByStr(profile, field?.defaultValue),
                }}
              />
            );
          })}
        </GridGroup>
        <Div sx={{ px: 20 }}>
          <Link onClick={() => navigate("/ChangePassword")}>
            {t("Change Password")}
          </Link>
          <Link onClick={() => navigate("/Store/Subscriptions")}>
            {t("Subscription")}
          </Link>
        </Div>
        <Div sx={{ w: "95%", px: 10 }}>
          <Button
            as="button"
            type="submit"
            sx={{
              p: 10,
              my: 5,
              w: "100%",
              bgc: "primary",
              c: "white",
              br: 30,
              bs: "none",
            }}
          >
            <Type var="p" c="white">
              {t("save")}
            </Type>
          </Button>
        </Div>
      </Div>
    );
};
