import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { handleBody } from "../../utilities/functions";
import { useLocalization } from "../../services/LocalizationProvider";

const Context = createContext();

export function useHistory() {
  return useContext(Context);
}

const initState = {
  list: [],
  selected: null,
  singleRead: null,
  serviceID: 0,
  status: "idle",
  error: null,
  page: 1,
  perPage: 10,
  totalRecords: 0,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_singleRead":
      return { ...state, singleRead: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_serviceID":
      return { ...state, serviceID: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_total_records":
      return { ...state, totalRecords: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function HistoryProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const {
    state: { lang },
  } = useLocalization();

  const fetchList = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        lang: lang,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      };
      const resData = await req(
        `Order/List/${data.serviceID}`,
        null,
        { method: "GET" },
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({
        type: "set_total_records",
        payload: resData.data.totalRecords,
      });
      dispatch({
        type: "set_list",
        payload: resData.data.orders || [],
      });
    },
    [req, lang]
  );
  const notifyRead = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = { lang: lang };
      const resData = await req(
        `Notification/Read/${data}`,
        { ...data },
        { method: "PUT" },
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
    },
    [req, lang]
  );
  const fetchRead = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = { lang: lang };
      const resData = await req(
        `Order/Info/${id}`,
        null,
        { method: "GET" },
        true,
        headersData
      );
      dispatch({ type: "set_singleRead", payload: resData.data });
    },
    [req, lang]
  );
  const CancelOrder = useCallback(
    async (orderId) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = { lang: lang };
      const resData = await req(
        `Order/Cancel/${orderId}`,
        null,
        { method: "PUT" },
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
    },
    [req, lang]
  );
  const setRateOrder = useCallback(
    async (orderID, data) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = { lang: lang };
      const resData = await req(
        `Order/Rate/${orderID}`,
        { ...data },
        { method: "PUT" },
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
    },
    [req, lang]
  );
  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        notifyRead,
        fetchRead,
        CancelOrder,
        setRateOrder,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
}
