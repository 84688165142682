// *** React
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// *** Styled Components
import { Div } from "../../ui/Div";
import Header from "../../ui/Header";
import { FormChangePass } from "./FormChangePass";

// *** Services and Providers
import { useProfile } from "./provider";

// *** Fields
import changeFields from "./changeFields";

// *** YUP
import * as Yup from "yup";

// Schema
const schema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("required")
    .min(6, "Password must be at least 6 characters long"),
  newPassword: Yup.string()
    .required("Required")
    .min(6, "Password must be at least 6 characters long"),
  confirmPassword: Yup.string()
    .required("Required")
    .min(6, "Password must be at least 6 characters long"),
});

export const ChangePassword = () => {
  const [t] = useTranslation();
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  const {
    state: { selected },
    fetchProfile,
  } = useProfile();

  useEffect(() => {
    fetchProfile({
      clientID: user?.profile?.clientID,
    });
  }, []);

  return (
    <Div sx={{ m: 30 }}>
      <Header
        title={t("Change Password")}
        list={[
          { title: t("Edit Profile"), link: "/Profile" },
          { title: t("Change Password"), link: "/ChangePassword" },
        ]}
      ></Header>
      <FormChangePass fields={changeFields} schema={schema} />
    </Div>
  );
};
