import styled from "styled-components";
import { Div } from "../../../ui/Div";
import Type from "../../../ui/Type";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 15px;
  align-items: ${(props) => props.ai};
`;

function CardBody({ section1, section2, section3, children, header, ai }) {
  return (
    <Body ai={ai}>
      {header}
      {section1 && (
        <Div>
          <Type
            var="custom"
            sx={{ fw: "bold", text: "uppercase", c: "primary" }}
          >
            {section1}
          </Type>
        </Div>
      )}
      {section2 && (
        <Div>
          <Type var="custom" sx={{ lh: 42, fs: 34, fw: "normal", c: "black1" }}>
            {section2}
          </Type>
        </Div>
      )}
      {section3 && (
        <Div>
          <Type var="small">{section3}</Type>
        </Div>
      )}
      {children}
    </Body>
  );
}

export default CardBody;
