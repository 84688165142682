import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ar from "../../data/locale/ar.json";
import en from "../../data/locale/en.json";
import languageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: en,
  },
  ar: {
    translation: ar,
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("tobsirLng") ? localStorage.getItem("tobsirLng") : "ar",
    // fallbackLng: {
    //   en: ["en"],
    //   default: ["ar"],
    // },
    // fallbackLng: "ar",
    // fallbackLng: (code) => {
    //   console.log(code, "ccccccc");
    //   if (!code || code === "en") return ["en"];
    //   return [code];
    // },

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
