import { Container, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import stylesBlocks, { sizes } from "../../ui/core/stylesBuilder";

export const ContainerS = styled(Container)`
  width: 100%;
`;

export const ListGroupSItem = styled(ListGroup.Item)`
  border: none;
  @media (max-width: ${sizes.tablet}) {
    text-align: center;
  }
`;

export const FooterItem = styled(Link)`
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  text-decoration: none;
  border: none;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #161610;
  @media (max-width: ${sizes.tablet}) {
    text-align: center;
  }
  ${stylesBlocks}
`;
