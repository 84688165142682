// *** React
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const VideoPage = () => {
  const [adVideo, setAdVideo] = useState(false);
  const [videoSrc, setVideoSrc] = useState();
  let navigate = useNavigate();
  window.scroll(0, 0);
  useEffect(() => {
    setVideoSrc("https://samplelib.com/lib/preview/mp4/sample-5s.mp4");
    setAdVideo(true);
  }, [adVideo]);

  setTimeout(() => {
    var vid = document.getElementById("adVedio");
    vid.controlsList = "noplaybackrate";
    var supposedCurrentTime = 0;
    vid.addEventListener("timeupdate", function () {
      if (!vid.seeking) {
        supposedCurrentTime = vid.currentTime;
      }
    });
    // prevent user from seeking
    vid.addEventListener("seeking", function () {
      // guard agains infinite recursion:
      // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
      var delta = vid.currentTime - supposedCurrentTime;
      if (Math.abs(delta) > 0.01) {
        console.log("Seeking is disabled");
        vid.currentTime = supposedCurrentTime;
      }
    });
    vid.onended = function () {
      toast.warning("The video has ended");
      // delete the following event handler if rewind is not required
      navigate("/Store");
      // reset state in order to allow for rewind
      supposedCurrentTime = 0;
    };
  }, 1000);
  return (
    <>
      <div
        class="demovideo"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "20px",
        }}
      >
        <video controls width="50%" id="adVedio" src={videoSrc}></video>
      </div>
    </>
  );
};

export default VideoPage;
