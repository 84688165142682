import React from "react";
import { Div } from "../../ui/Div";
import centerImage from "../../assets/Component 79 – 1@3x.png";
import Image from "../../ui/Image";
import Type from "../../ui/Type";
import { Button } from "../../ui/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const UnderConstruction = () => {
  let [t] = useTranslation();
  let navigate = useNavigate();

  return (
    <Div loginbg flexcenter>
      <Div
        sx={{
          gap: 10,
          d: "flex",
          jc: "center",
          ai: "center",
          fxd: "column",
        }}
      >
        <Image src={centerImage} sx={{ w: "30%", h: "30%" }} />
        <Type var="FAQFont" sx={{ c: "black2", fs: 40 }}>
          {t("UnderConst")}
        </Type>
        <Button
          sx={{
            h: 45,
            w: 150,
            br: 25,
            bgc: "primary",
            c: "white",
            d: "flex",
            jc: "center",
            ai: "center",
          }}
          onClick={() => navigate(-1)}
        >
          {t("GoBack")}
        </Button>
      </Div>
    </Div>
  );
};

export default UnderConstruction;
