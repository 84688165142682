import { Form } from "react-bootstrap";
import styled from "styled-components";

export const SelectS = styled(Form.Select)`
  outline: solid 1px #e0e0e0;
  padding: 16px 12px 16px 30px;
  color: ${(p) => p.theme.color.gray2};
  background-color: ${(props) => props.theme.color.gray0};
  border-radius: 30px;
  height: 55px;
  margin-bottom: 20px;
  @media (max-width: 720px) {
    width: 150px;
  }
`;
