// *** React
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

// *** Styled Components
import Header from "../../ui/Header";
import Icon from "../../ui/Icon";
import Type from "../../ui/Type";
import TabList from "../../ui/TabList";
import { Div } from "../../ui/Div";
import { RowS } from "../../ui/RowS";
import { ColS } from "../../ui/ColS";
import { Button } from "../../ui/Button";
import { StyledLink } from "../../ui/StyledLink";

// *** Components
import BuyGems from "./BuyGems";
import CollectGems from "./CollectGems";

const GemStore = () => {
  const [t] = useTranslation();
  const [SelectedTab, setSelectedTab] = useState(t("Buy Gems"));

  return (
    <Div flexcenter sx={{ fxd: "column" }}>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        autoClose={2000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Div flexcenter sx={{ m: "30px" }}>
        <Header
          title={t("Gems Store")}
          list={[
            { title: t("Home"), link: "/" },
            { title: t("Gems Store"), link: "/Store" },
          ]}
        ></Header>
      </Div>
      <Div gradient="true" sx={{ br: 20, py: 50, px: 30, w: 1200 }}>
        <RowS>
          <ColS md={12} lg={1}>
            <Div sx={{ bgc: "white", br: 10, p: 4, w: 80, h: 80 }} flexcenter>
              <Icon name="GemBlue" w={65} c="white" />
            </Div>
          </ColS>
          <ColS md={9} lg={6}>
            <Div>
              <Type var="Font" sx={{ fs: 40, mt: -12 }}>
                {t("Get the Blue Gem")}
              </Type>

              <Type var="Font" sx={{ fs: 16, mt: -12 }}>
                {t("Enjoy an ad-free")}
              </Type>

              <Type var="Font" sx={{ fs: 13, mt: 12 }}>
                {t("Cancel Anytime")}
              </Type>
            </Div>
          </ColS>
          <ColS md={12} lg={4}>
            <Button
              sx={{
                py: "2%",
                px: 10,
                w: 350,
                bgc: "primary",
                c: "white",
                mt: 25,
                br: 20,
                fs: 13,
              }}
            >
              <StyledLink
                to="/Store/Subscriptions"
                sx={{ c: "white", [":hover"]: { c: "white" } }}
              >
                {t("Join a 7 days Free Trial")}
              </StyledLink>
            </Button>
          </ColS>
        </RowS>
      </Div>
      <TabList
        tabs={[{ title: t("Buy Gems") }, { title: t("Collect Gems") }]}
        selectedTab={SelectedTab}
        setSelectedTab={setSelectedTab}
      />
      {(SelectedTab === "Buy Gems" || SelectedTab === "شراء") && <BuyGems />}
      {(SelectedTab === "Collect Gems" || SelectedTab === "اجمع الجواهر") && (
        <CollectGems />
      )}
    </Div>
  );
};

export default GemStore;
